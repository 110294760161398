import moment from "moment";
import React from "react";
import { useState } from "react";

const PayrollTable = ({ payroll }) => {
  const itemsPerPage = 10; // Define number of items per page
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = payroll?.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const totalPages = Math.ceil(payroll?.length / itemsPerPage);
  return (
    <div class="table-responsive bg-white rounded-3">
      <table class="table table-hover ">
        <thead className="table-secondary">
          <tr>
            <th scope="col">Staff Name</th>
            <th scope="col">Designation</th>
            <th scope="col">Month</th>
            <th scope="col">Start Date</th>
            <th scope="col">End Date</th>
            <th scope="col">Year</th>
            <th scope="col">Issue Date</th>
            <th scope="col">Net Salary</th>
          </tr>
        </thead>
        <tbody>
          {currentItems?.length > 0 ? (
            currentItems?.map((pay, i) => (
              <tr key={i}>
                <td>{pay?.staff_Id?.name}</td>
                <td>{pay?.staff_Id?.title}</td>
                <td>
                  {moment()
                    .month(pay?.month - 1)
                    .format("MMMM")}
                </td>
                <td>{moment(pay?.startDate).format("DD-MM-YYYY")}</td>
                <td>{moment(pay?.endDate).format("DD-MM-YYYY")}</td>
                <td>{pay?.year}</td>
                <td>{moment(pay?.paymentDate).format("DD-MM-YYYY")}</td>
                <td>{pay?.netSalary} PKR</td>
              </tr>
            ))
          ) : (
            <td colSpan={8} className="text-center py-5">
              No record found
            </td>
          )}
        </tbody>
      </table>
      {payroll?.length > 0 && (
        <div className="w-90 position-absolute bottom-0 start-50 translate-middle-x mb-3">
          <div className="row align-items-center text-center text-md-start">
            <div className="col-sm-6 ms-auto col-xs-6">
              <div className="d-flex align-items-center justify-content-center">
                <button
                  className="pagination_btn"
                  onClick={() =>
                    setCurrentPage(
                      currentPage > 1 ? currentPage - 1 : currentPage
                    )
                  }
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                {[...Array(totalPages)].map((_, i) => (
                  <div
                    key={i}
                    className={`pageNo ${
                      i + 1 === currentPage ? "pageNo_active" : ""
                    }`}
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </div>
                ))}
                <button
                  className="pagination_btn"
                  onClick={() =>
                    setCurrentPage(
                      currentPage < totalPages ? currentPage + 1 : currentPage
                    )
                  }
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PayrollTable;
