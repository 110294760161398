import React, { useState, useRef, useEffect } from "react";
import { TbPhotoEdit } from "react-icons/tb";
import { errorValidate } from "../../validations/errorHandleJoi";
import { blogValidation } from "../../validations/BlogValidation";
import imageIcon from "../assets/imageIcon.png";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useMutation } from "@tanstack/react-query";
import apis from "../../services";
import { Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { setBlogs } from "../../redux/slices/userSlice";
import { BeatLoader } from "react-spinners";

const AddBlogComp = () => {
  const { pathname, state } = useLocation();
  const auther_image = useRef();
  const blog_image = useRef();
  const { blogs,storeID } = useSelector((e) => e?.user);
  const [blogInfo] =
    blogs?.length > 0 ? blogs?.filter((e) => e?.slug === state?.id) : [];
  const navigate = useNavigate();
  const isEdit = pathname?.includes("update");
  const [imagePreview, setImagePreview] = useState(null);
  const [blog_img_preview, setBlogImage] = useState(null);
  const { storeInfo, user } = useSelector((e) => e?.user);
  const dispatch = useDispatch();
  const [data, setData] = useState({
    store_Id:user?.role==="admin"  ? storeID : storeInfo?._id,
    title: "",
    author: "",
    description: "",
    author_image: "",
    image: "",
  });

  useEffect(() => {
    if (isEdit) {
      const {
        __v,
        _id,
        updatedAt,
        slug,
        isDeleted,
        isSuspend,
        createdAt,
        ...res
      } = blogInfo || {};

      setData({
        ...res,
        store_Id: user?.role === "admin"? storeID : storeInfo?._id,
      });
      setImagePreview(blogInfo?.image);
      setBlogImage(blogInfo?.author_image);
    }
    // eslint-disable-next-line
  }, [blogInfo]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const triggerFileInput = () => {
    auther_image.current.click();
  };
  const trigger_blog_img = () => {
    blog_image.current.click();
  };

  const handleFileSelection = () => {
    const selectedFile = auther_image.current.files[0];
    setData({ ...data, author_image: selectedFile });
    setImagePreview(URL.createObjectURL(selectedFile));
  };
  const select_blog_img = () => {
    const selectedFile = blog_image.current.files[0];
    setBlogImage(URL.createObjectURL(selectedFile));
    setData({ ...data, image: selectedFile });
  };
  const [error, setError] = useState();
  console.log(error, "error");

  const { mutate, isLoading: isSubmitting } = useMutation(apis.createBlog, {
    onError: function ({ message }) {
      setError("");
      toast.error(message?.message, { id: 1 });
    },
    onSuccess: ({ data, status }) => {
      if (status === 201) {
        if(user?.role==="admin"){
          navigate(`/admin/stores/details/${storeInfo?.slug}`,{state:{tab:"blog"}})
        }else{
          navigate(`/${user?.role}/blog`);
        }
        toast.success(data?.message, { id: 1 });
        dispatch(setBlogs(data?.blogs));
      }
    },
  });
  const { mutate: updateBlog, isLoading: isUpdating } = useMutation(
    apis.updateBlog,
    {
      onError: function ({ message }) {
        setError("");
        toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          toast.success(data?.message, { id: 1 });
          dispatch(setBlogs(data?.blogs));
          if(user?.role==="admin"){
            navigate(`/admin/stores/details/${storeInfo?.slug}`,{state:{tab:"blog"}})
          }else{
            navigate(`/${user?.role}/blog`);
          }
        }
      },
    }
  );

  const createBlog = (e) => {
    e.preventDefault();
    const form_data = new FormData();

    for (const [key, value] of Object.entries(data)) {
      if (pathname?.includes("update")) {
        if (key === "store_Id") {
          continue;
        }
      }
      form_data.append(key, value);
    }

    let response = errorValidate(blogValidation(data));
    if (response === true) {
      if (data?.image || data?.author_image) {
        if (isEdit) {
          updateBlog({ id: blogInfo?._id, body: form_data, role: user?.role });
        } else {
          mutate(form_data);
        }
      } else {
        toast.error("Please Select Image", { id: 1 });
      }
    } else {
      setError(response);
    }
  };

  return (
    <div className="p-4">
      <input
        type="file"
        name="image"
        accept="image/png, image/gif, image/jpeg"
        onChange={handleFileSelection}
        ref={auther_image}
        className="d-none"
      />
      <input
        type="file"
        name="image"
        accept="image/png, image/gif, image/jpeg"
        onChange={select_blog_img}
        ref={blog_image}
        className="d-none"
      />
      <div className="d-flex flex-wrap flex-wrap align-items-center w-100 justify-content-between">
        <h3 className="m-0 mb-3 fw-bold ">Add Blogs</h3>
      </div>
      <form onSubmit={createBlog}>
        <div className="row bg-white rounded-3 py-3 mb-5">
          <div className="col-md-6">
            <div className="profile_image_wrapper">
              <img
                src={imagePreview || imageIcon}
                onClick={triggerFileInput}
                className="img-fluid d-block hc mx-auto salonProfileImage"
                alt="cate"
              />
              <TbPhotoEdit
                onClick={triggerFileInput}
                className="text-center hc d-block mx-auto fs-1 mt-1 inputImage"
              />
              <p className="m-0 text-center">Author Image</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="profile_image_wrapper">
              <img
                src={blog_img_preview || imageIcon}
                onClick={trigger_blog_img}
                className="img-fluid d-block hc mx-auto salonProfileImage"
                alt="cate"
              />
              <TbPhotoEdit
                onClick={trigger_blog_img}
                className="text-center hc d-block mx-auto fs-1 mt-1 inputImage"
              />
              <p className="m-0 text-center">Blog Image</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="email" className="ms-2 mb-1">
                Blog Title
              </label>
              <input
                type="text"
                name="title"
                value={data?.title}
                onChange={onChange}
                className="form-control"
              />
              {error?.title && (
                <p className="text-danger m-0">{error?.title}</p>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="email" className="ms-2 mb-1">
                Author Name
              </label>
              <input
                type="text"
                value={data?.author}
                name="author"
                onChange={onChange}
                className="form-control"
              />
              {error?.author && (
                <p className="text-danger m-0">{error?.author}</p>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="mb-3">
              <label htmlFor="email" className="ms-2 mb-1">
                Description
              </label>
              <textarea
                name="description"
                onChange={onChange}
                id=""
                cols="5"
                rows="5"
                value={data?.description}
                className="form-control rounded-3"
              ></textarea>
              {error?.description && (
                <p className="text-danger m-0">{error?.description}</p>
              )}
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-10 mx-auto ">
            <button className="login-btn mt-2 fw-bold " type="submit">
              {isSubmitting || isUpdating ? (
                // <Spinner />
                <BeatLoader size={15} color="#fff" />
              ) : isEdit ? (
                "UPDATE BLOG"
              ) : (
                " ADD BLOG"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddBlogComp;
