import React from "react";
import AddPayrollComp from "../components/staff-payroll/AddPayrollComp";
import Layout from "../components/layout/Layout";

const AddPayroll = () => {
  return (
    <Layout>
      <AddPayrollComp />
    </Layout>
  );
};

export default AddPayroll;
