import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
const PayrollStaffTable = ({ staffs,user }) => {
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const filteredStaffs = staffs?.filter((staff) =>
    staff?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredStaffs?.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(filteredStaffs.length / itemsPerPage);

  return (
    <div className="p-3">
      <div className="col-md-4 mb-3">
        <label htmlFor="search" className="form-label">
          Search:
        </label>
        <input
          type="text"
          autoComplete="false"
          className="form-control bg_white"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div class="table-responsive bg-white rounded-3">
        <table class="table table-hover ">
          <thead className="table-secondary">
            <tr>
              <th scope="col">Staff Name</th>
              <th scope="col">Designation</th>
              <th scope="col">Generate</th>
            </tr>
          </thead>
          <tbody>
            {currentItems?.length > 0 &&
              currentItems?.map((staff, i) => (
                <tr key={i}>
                  <td>{staff?.name}</td>
                  <td>{staff?.title}</td>
                  <td>
                    <Link to={`/${user?.role}/payroll/${staff?._id}`}>
                      <button className="btn btn-secondary rounded-3 ">
                        Generate Payroll
                      </button>
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="w-90 position-absolute bottom-0 start-50 translate-middle-x mb-3">
          <div className="row align-items-center text-center text-md-start">
          
            <div className="col-sm-6 ms-auto col-xs-6">
              <div className="d-flex align-items-center justify-content-center">
                <button
                  className="pagination_btn"
                  onClick={() =>
                    setCurrentPage(
                      currentPage > 1 ? currentPage - 1 : currentPage
                    )
                  }
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                {[...Array(totalPages)].map((_, i) => (
                  <div
                    key={i}
                    className={`pageNo ${
                      i + 1 === currentPage ? "pageNo_active" : ""
                    }`}
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </div>
                ))}
                <button
                  className="pagination_btn"
                  onClick={() =>
                    setCurrentPage(
                      currentPage < totalPages ? currentPage + 1 : currentPage
                    )
                  }
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayrollStaffTable;
