import axios from "axios";
const createBackendServer = (baseURL) => {
  axios.defaults.withCredentials = true;
  const api = axios.create({
    baseURL: `${baseURL}api/`,
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    timeout: 60 * 1000,
  });

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      const message = error.response.data;
      error.message = message || error.message;
      /*if(error?.response?.data?.errors)
            error.errors = error?.response?.data?.errors;*/
      return Promise.reject(error);
    }
  );

  /*==========     AUTH Requests   ==========*/
  const authLogin = (body) => api.post("auth", body);
  const authRegister = (body) => api.post("auth/register", body);
  const authRegisterReferral = ({ body, code }) =>
    api.post(`auth/register?referralCode=${code}`, body);
  const authVerify = (body) => api.post("auth/verify", body);
  const authForgot = (body) => api.post("auth/forget", body);
  const authUpdatePassword = (body) => api.put("auth/update-password", body);

  /*==========     GET Requests   ==========*/
  const getStoreCategory = () => api.get("store-category");
  const getAllStore = () => api.get("admin/store");
  const getAdminCompleteStore = (id) => api.get(`admin/store/${id}`);
  const getServiceCategory = ({ id, role }) =>
    api.get(`service-category/store/${id}?role=${role}`);
  const getStoreBookings = (id) => api.get(`booking/store/${id}`);
  const getStaffBookings = (id) => api.get(`booking/staff/${id}`);
  const getAllBookings = () => api.get(`booking`);
  const getCompleteAdmin = (id) => api.get(`admin/store/complete-admin/${id}`);
  const getStoreBlogs = (id) => api.get(`blog/store/${id}`);
  const getStoreMarketing = (id) => api.get(`marketing/${id}`);
  const getStoreStaff = ({ id, role }) =>
    api.get(`staff/store/${id}?role=${role}`);
  const getStoreServices = ({ id, role }) =>
    api.get(`service/store/${id}?role=${role}`);
  const getStoreCoupons = ({ id, role }) =>
    api.get(`store/coupon/${id}?role=${role}`);
  const get_slots = ({ id, duration, staff_Id }) =>
    api.get(`store/slots/${id}?duration=${duration}&staff_Id=${staff_Id}`);
  const get_slots_no_staff = ({ id, duration }) =>
    api.get(`store/slots/${id}?duration=${duration}`);
  const getStoreNotification = (id) => api.get(`store/notification/${id}`);
  const getAdminCoupon = (id) => api.get(`admin/coupon`);
  const getAdminNotification = () => api.get(`admin/notification`);
  const getStaffNotification = (id) => api.get(`staff/notification/${id}`);
  const getStoreInfo = (id) => api.get(`store/analytics/store_info/${id}`);
  const getStoreGraph = (id) =>
    api.get(`store/analytics/graph_info/${id}?year=2024`);
  const storeNotificationSeen = (id) =>
    api.get(`store/notification-seen/${id}`);
  const staffNotificationSeen = (id) =>
    api.get(`staff/notification-seen/${id}`);
  const changeStaffStatus = ({ id, role }) =>
    api.get(`staff/change-status/${id}?role=${role}`);
  const getStaffReferral = (id) => api.get(`staff/referral/${id}`);
  const getStoreReferral = (id) => api.get(`store/referral/${id}`);
  const getStaffAnalytics = (id) => api.get(`staff/analytics/${id}?year=2024`);
  const getAdminAnalytics = (id) => api.get(`admin/analytics/admin-info/${id}?year=2024`);
  const getStaffGraphs = (id) => api.get(`staff/graph/${id}`);
  const getAdminGraphs = (id) => api.get(`admin/analytics/admin-graph-info/${id}`);
  const getCompleteStore = (id) =>
    api.get(`store/complete-info/${id}?to=manual`);
  const getCompleteStaff = (id) =>
    api.get(`staff/complete-info/${id}?to=manual`);
  const validateCoupon = ({ id, code }) =>
    api.get(`store/coupon/validate/${id}?value=${code}`);

  /*==========     POST Requests   ==========*/
  const create_booking = (body) => api.post("booking", body);
  const createStoreCategory = (body) => api.post("admin/store-category", body);
  const sendMarketingTemplate = (body) => api.post("marketing", body);
  const generateStoreReferral = (id) => api.post(`store/referral/${id}`);
  const generateStaffReferral = (id) => api.post(`staff/referral/${id}`);
  const checkInBooking = (body) => api.post("booking/checkIn", body);
  const confirmBooking = (body) => api.post("booking/confirmed", body);
  const generatePayroll = (body) => api.post("staffpayroll", body);
  const sendStaffNotification = ({ id, body }) =>
    api.post(`store/notification/send/${id}`, body);
  const createBlog = (body) => api.post("blog", body);
  const add_service_category = ({ body, role }) =>
    api.post(`service-category?role=${role}`, body);
  const createCoupon = (body) => api.post(`store/coupon`, body);
  const add_staff = (body) => api.post("staff", body);
  const add_service = (body) => api.post("service", body);
  const create_store = (body) => api.post("store", body);
  const cancelBooking = (body) => api.post("booking/cancelled", body);
  const createAdminCoupon = (body) => api.post("admin/coupon", body);

  // ========== DELETE REQUEST =================
  const delete_coupon = (id) => api.delete(`store/coupon/${id}`);
  const delete_service_category = ({ id, role }) =>
    api.delete(`service-category/${id}?role=${role}`);
  const delete_staff = ({ id, role }) => api.delete(`staff/${id}?role=${role}`);
  const delete_service = ({ id, role }) =>
    api.delete(`service/${id}?role=${role}`);
  const delete_blog = ({ id }) => api.delete(`blog/${id}`);
  const deleteStoreCategory = (id) => api.delete(`admin/store-category/${id}`);
  const storeSuspendDelete = ({ id, type }) =>
    api.delete(`admin/store/${id}?type=${type}`);
  const deteleAdminCoupon = (id) => api.delete(`admin/coupon/delete/${id}`);

  // ========= PUT REQUEST =================
  const update_Service = ({ id, body, role }) =>
    api.put(`service/update/${id}?role=${role}`, body);
  const update_Staff = ({ id, body, role }) =>
    api.put(`staff/update/${id}?role=${role}`, body);
  const update_User = ({ id, body }) => api.put(`user/update/${id}`, body);
  const updateBlog = ({ id, body, role }) =>
    api.put(`blog/${id}?role=${role}`, body);
  const updateStore = ({ id, body }) => api.put(`store/update/${id}`, body);
  const updateServiceCategory = ({ id, body, role }) =>
    api.put(`service-category/${id}?role=${role}`, body);
  const updateStoreCategory = ({ id, body }) =>
    api.put(`admin/store-category/${id}`, body);
  const updateStoreTime = (id, body) =>
    api.put(`store/update/${id}`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

  return {
    authLogin,
    update_User,
    authRegister,
    authVerify,
    authForgot,
    authUpdatePassword,
    updateBlog,
    staffNotificationSeen,
    storeNotificationSeen,
    getStoreNotification,
    getStoreCategory,
    add_service_category,
    getStoreStaff,
    sendStaffNotification,
    getStaffNotification,
    add_staff,
    getStoreServices,
    getStaffAnalytics,
    getStaffReferral,
    getStoreReferral,
    getAdminNotification,
    generateStoreReferral,
    generateStaffReferral,
    changeStaffStatus,
    add_service,
    create_store,
    cancelBooking,
    updateStore,
    confirmBooking,
    updateStoreTime,
    createCoupon,
    getStoreGraph,
    delete_blog,
    delete_coupon,
    update_Service,
    update_Staff,
    checkInBooking,
    getStaffGraphs,
    // get_single_staff,
    sendMarketingTemplate,
    // get_single_blog,
    get_slots,
    delete_service_category,
    delete_staff,
    create_booking,
    delete_service,
    getStoreCoupons,
    createBlog,
    generatePayroll,
    // get_specific_Staff_payroll,
    // get_store_Staff_payroll,
    getStoreInfo,
    getStoreMarketing,
    getServiceCategory,
    getCompleteStaff,
    validateCoupon,
    getCompleteStore,
    get_slots_no_staff,
    authRegisterReferral,
    getCompleteAdmin,
    getStoreBookings,
    getStaffBookings,
    getStoreBlogs,
    updateServiceCategory,
    createStoreCategory,
    updateStoreCategory,
    deleteStoreCategory,
    getAllStore,
    storeSuspendDelete,
    getAdminCompleteStore,
    getAllBookings,
    createAdminCoupon,
    getAdminCoupon,
    deteleAdminCoupon,
    getAdminAnalytics,
    getAdminGraphs,
  };
};

// const apis = createBackendServer(
//   process.env.NODE_ENV === "development"
//     ? process.env.REACT_APP_API_URL
//     : process.env.REACT_APP_PRODUCTION_API_URL
// );
const apis = createBackendServer(process.env.REACT_APP_SERVER_URL);

export default apis;
