import React, { useState } from "react";
import Logo from "../assets/Logo-02.png";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import dealBg from "../assets/dealImage.jpg";
import { BsCheck2All } from "react-icons/bs";
import { RiDeleteBin7Fill } from "react-icons/ri";
import MarketingCustomers from "./MarketingCustomers";

const DealTemplate = ({
  captureAndSendImage,
  isLoading,
  handleCheckboxChange,
  selectedIds,
  setSelectedIds,
  mainData,
  setMainData,
  divRef,
}) => {
  const { storeInfo } = useSelector((e) => e?.user);
  const [data, setData] = useState({
    offername: "Deal 1",
    background: "",
    price: "Rs:5000/-",
    logo: "",
    bg_image: "",
    target: "all",
    length: 5,
    fields: ["facial", "manicure", "padicure", "makeup", "party makeup"],
  });

  const onChange = (e, index) => {
    const { name, value, files } = e.target;
    if (name === "fields" && index !== undefined) {
      setData((prevData) => {
        const newFields = [...prevData.fields];
        newFields[index] = value;
        return {
          ...prevData,
          fields: newFields,
        };
      });
    } else {
      // Handle changes for other fields
      if (files && files.length > 0) {
        const file = files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
          const fileUrl = event.target.result;
          setData((prev) => ({ ...prev, [name]: fileUrl }));
        };
        reader.readAsDataURL(file);
      } else {
        setData((prev) => ({ ...prev, [name]: value }));
      }
    }
  };

  const handleAddField = () => {
    if (data.fields.length < data.length) {
      setData((prevData) => ({
        ...prevData,
        fields: [...prevData.fields, ""],
      }));
    }
  };

  const handleDeleteField = (index) => {
    if (data.fields.length > 1) {
      setData((prevData) => ({
        ...prevData,
        fields: prevData.fields.filter((_, i) => i !== index),
      }));
    }
  };

  const style = {
    background: `url(${data?.background ? data?.background : dealBg})`,
  };

  return (
    <div className="row mt-5">
      <div className="col-lg-6">
        <h3 className="fw-bold">Create Template</h3>
        <div className="bg-white rounded-3 p-3 mb-3">
          <div className="row">
            <div className="">
              <div className="mb-3">
                <label htmlFor="offername">Offer name</label>
                <input
                  type="text"
                  className="form-control"
                  name="offername"
                  value={data?.offername}
                  maxLength={18}
                  onChange={onChange}
                />
              </div>
            </div>

            <div className="row align-items-center pe-0 ">
              <div className="col-md-6 pe-lg-0 ">
                <div className="mb-3">
                  <label htmlFor="logo">Background image</label>
                  <br />

                  <input
                    type="file"
                    className="form-control"
                    name="background"
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="col-md-6 pe-lg-0 ">
                <div className="mb-3">
                  <label htmlFor="offername">Deal price</label>
                  <input
                    type="text"
                    className="form-control"
                    name="price"
                    value={data?.price}
                    maxLength={15}
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>

            <div className="row pe-0 ">
              <div className="col-lg-6">
                <div className="mb-3">
                  <label htmlFor="logo">Salon Logo</label>
                  <br />
                  <input
                    type="file"
                    className="form-control"
                    name="logo"
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="col-lg-6 pe-lg-0 ">
                <div className="mb-3">
                  <label htmlFor="target">Target</label>

                  <select
                    name="target"
                    id=""
                    onChange={(e) =>
                      setMainData({ ...mainData, target: e.target.value })
                    }
                    value={mainData?.target}
                    className="form-control"
                  >
                    <option value="all">All Customers</option>
                    <option value="specific">Specific Customer</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="temp-inputs d-flex mx-auto  my-1 row">
              {data?.fields?.map((field, index) => {
                return (
                  <>
                    <div className="col-md-6 my-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <label>Service {index + 1}</label>
                        <RiDeleteBin7Fill
                          className="text-danger fs-4"
                          onClick={() => handleDeleteField(index)}
                        />
                      </div>
                      <input
                        type="text"
                        name="fields"
                        value={field}
                        onChange={(e) => onChange(e, index)}
                        className="form-control"
                        maxLength={20}
                      />
                    </div>
                  </>
                );
              })}
              <div className="col-12">
                {data.fields.length < data.length && (
                  <button
                    className="backBtn d-block mx-auto mb-2"
                    onClick={handleAddField}
                  >
                    Add Field
                  </button>
                )}
              </div>
            </div>

            {mainData?.target === "specific" && (
              <MarketingCustomers
                handleCheckboxChange={handleCheckboxChange}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
              />
            )}

            <button
              className="backBtn mt-4 d-flex align-items-center px-4 d-block mx-auto"
              onClick={() => {
                captureAndSendImage();
              }}
            >
              {isLoading ? <Spinner size="sm" /> : "Create"}
            </button>
          </div>
        </div>
      </div>

      <div className="col-lg-6 mt-lg-5">
        <div
          className="position-relative dealTemplate"
          ref={divRef}
          style={style}
        >
          <div className="dealContentOverly">
            <img
              src={data?.logo || Logo}
              className="templateLogo mt-2 d-block mx-auto"
              alt=""
            />

            <h2 className="text-white mt-1 font-lobster text-center">
              {storeInfo?.name}
            </h2>
            <h5 className="text-center fw-bold ">{data?.type}</h5>
            {data?.offername && (
              <h3 className="text-center my-3 fw-bold courgette-font text-warning">
                {data?.offername}
              </h3>
            )}

            {/* <ul className="">
              <li className="text-warning courgette-font fs-5">
                <PiCheck className="fs-5 text-white" />
                Bridal Makup
              </li>
            </ul> */}
            <ul className="list-unstyled ">
              {data?.fields?.map((value, index) => (
                <>
                  {value && (
                    <li
                      className="text-warning courgette-font fs-5 d-flex align-items-center"
                      key={index}
                    >
                      <BsCheck2All className="fs-5 text-white mx-2" /> {value}
                    </li>
                  )}
                </>
              ))}
            </ul>

            <h3 className="text-warning luckiest-font text-center">
              {data?.price}
            </h3>

            <h6 className="position-absolute courgette-font bottom-0 end-0 text-white me-3">
              Powered by beautowns
            </h6>
          </div>
        </div>
      </div>

      {/* <img
        src={data.bg_image || discounted}
        className="img-fluid discountedImg d-block mx-auto mt-5 "
        alt=""
      /> */}
    </div>
  );
};

export default DealTemplate;
