import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import {
  ViewState,
  GroupingState,
  IntegratedGrouping,
  IntegratedEditing,
  EditingState,
} from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  Resources,
  Appointments,
  AppointmentTooltip,
  GroupingPanel,
  WeekView,
  DayView,
  MonthView,
  DragDropProvider,
  AppointmentForm,
  Toolbar,
  DateNavigator,
  TodayButton,
} from "@devexpress/dx-react-scheduler-material-ui";
import {
  pink,
  teal,
  blue,
  indigo,
  deepPurple,
  cyan,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  red,
  brown,
  blueGrey,
  grey,
} from "@mui/material/colors";

import moment from "moment";
import BookingDetailsModal from "../Modal/BookingDetailsModal";

const AllStaffCalander = ({ staffs, bookings, calanderView }) => {
  const [show, setShow] = useState(false);
  const [appointmentData, setAppointmentData] = useState({});
  const materialColors = [
    pink,
    teal,
    blue,
    indigo,
    deepPurple,
    cyan,
    green,
    lightGreen,
    lime,
    yellow,
    amber,
    orange,
    deepOrange,
    red,
    brown,
    blueGrey,
    grey,
  ];

  const staffNames = staffs?.map((staff, i) => ({
    text: staff?.name,
    id: staff?._id,
    color: materialColors[i],
  }));

  const [combinedAppointments, setCombinedAppointments] = useState([]);

  useEffect(() => {
    if (
      staffs &&
      Array.isArray(staffs) &&
      bookings &&
      Array.isArray(bookings)
    ) {
      const formattedAppointments = bookings?.map((booking) => {
        const { date, time, end, user_Id, _id, salon_staff_Id } = booking || {};
        const startDate = moment(`${date} ${time}`, "DD MMMM YYYY hh:mmA");
        const endDate = moment(`${date} ${end}`, "DD MMMM YYYY hh:mmA");

        return {
          startDate: startDate.format("YYYY-MM-DDTHH:mm"),
          endDate: endDate.format("YYYY-MM-DDTHH:mm"),
          id: _id,
          title: user_Id?.name,
          members: [salon_staff_Id?._id],
          rest: booking,
        };
      });

      setCombinedAppointments(formattedAppointments);
    } else {
      console.error(
        "Error: staffs or storeBookings data is not available or not an array"
      );
      setCombinedAppointments([]);
    }
  }, [bookings, staffs]);

  const CustomAppointment = ({ style, data, ...restProps }) => (
    <Appointments.Appointment
      {...restProps}
      onClick={() => handleAppointmentClick(data)}
    />
  );

  const handleAppointmentClick = (appointmentId) => {
    setAppointmentData(appointmentId?.rest);
    setShow(true);
  };

  const startDate = new Date(2024, 1, 11);

  const [currentDate, setCurrentDate] = useState(new Date());

  const handleDateChange = (date) => {
    setCurrentDate(date);
  };
  useEffect(() => {
    setCurrentDate(new Date());
  }, []);
  return (
    <>
      <BookingDetailsModal
        show={show}
        setShow={setShow}
        data={appointmentData}
        isCalander={true}
      />
      <Paper>
        <Scheduler data={combinedAppointments}>
          {/* <ViewState defaultCurrentDate={new Date()} /> */}
          <ViewState
            currentDate={currentDate}
            onCurrentDateChange={handleDateChange}
          />
          <EditingState />
          {/* <GroupingState grouping={[{ resourceName: "members" }]} /> */}
          {/* {calanderView ==="daily" &&
          <DayView startDayHour={9} endDayHour={24} />} */}
          {calanderView === "week" ? (
            <WeekView startDayHour={9} endDayHour={24} />
          ) : calanderView === "day" ? (
            <DayView startDayHour={9} endDayHour={24} />
          ) : (
            <MonthView startDayHour={9} endDayHour={24} />
          )}
          <Appointments />
          {/* <Resources
            data={[
              {
                fieldName: "members",
                title: "Members",
                instances: staffNames,
                allowMultiple: true,
              },
            ]}
            mainResourceName="members"
          /> */}
          {/* <IntegratedGrouping /> */}
          {/* <IntegratedEditing /> */}
          <Appointments appointmentComponent={CustomAppointment} />
          <AppointmentTooltip showOpenButton />
          {/* <AppointmentForm /> */}
          {/* <GroupingPanel /> */}
          <DragDropProvider />
          <Toolbar />
          <DateNavigator />
          <TodayButton />
        </Scheduler>
      </Paper>
    </>
  );
};

export default AllStaffCalander;
