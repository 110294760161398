import React, { useEffect, useState } from "react";
import { PiUsersThree } from "react-icons/pi";
import StaffSelectionModal from "../Modal/StaffSelectionModal";
import MajorStaffSelection from "../Modal/MajorStaffSelection";
import { setNoOfStaff, setStaffs } from "../../redux/slices/userSlice";
import { useMutation } from "@tanstack/react-query";
import apis from "../../services";
import { useDispatch, useSelector } from "react-redux";

const StaffSelection = ({
  selectedServices,
  selectedStaffs,
  setSelectedStaffs,
  storeStaff,
  updateStep,
  services,
}) => {


  const [show, setShow] = useState(false);
  const [showMajor, setShowMajor] = useState(false);
  const [index, setIndex] = useState(null);
  const [serviceName, setServiceName] = useState(null);
  let seletedService = services?.filter((e) =>
    selectedServices?.includes(e._id)
  );

  const setStaffAtIndex = (index, newValue) => {
    const newArray = [...selectedStaffs];
    newArray[index] = newValue;
    setSelectedStaffs(newArray);
  };

  const { user, storeInfo } = useSelector((e) => e?.user);
  const dispatch = useDispatch();
 

  const { mutate: getStaff, isLoading } = useMutation(
    () =>
      apis.getStoreStaff({
        id: user?.role === "store" ? storeInfo?._id : user?._id,
        role: user?.role,
      }),
    {
      onError: function ({ message }) {
        // toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          dispatch(setStaffs(data?.staff));
          dispatch(setNoOfStaff(data?.staff.length || 0));
        }
      },
    }
  );

  useEffect(() => {
    getStaff();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div className="d-flex flex-wrap justify-content-center justify-content-xl-start">
      {isLoading && <div id="cover-spin"></div>}

      <StaffSelectionModal
        show={show}
        setIndex={setIndex}
        selectedStaffs={selectedStaffs}
        setShow={setShow}
        setShowMajor={setShowMajor}
        storeStaff={storeStaff}
        setServiceName={setServiceName}
        setSelectedStaffs={setSelectedStaffs}
        seletedService={seletedService}
        setStaffAtIndex={setStaffAtIndex}
        updateStep={updateStep}
      />
      <MajorStaffSelection
        showMajor={showMajor}
        index={index}
        serviceName={serviceName}
        selectedStaffs={selectedStaffs}
        setShowMajor={setShowMajor}
        storeStaff={storeStaff}
        setSelectedStaffs={setSelectedStaffs}
        seletedService={seletedService}
        setStaffAtIndex={setStaffAtIndex}
        setShow={setShow}
      />
      <div
        className={`staffWrapper hc m-2 pb-2 ${
          selectedStaffs?.length === 0 && "staffWrapper-active"
        }`}
        onClick={() => setSelectedStaffs([])}
      >
        <PiUsersThree className="fs-1 text-center userLogo d-block my-2 mx-auto" />
        <p className="text-center m-0 fw-bold ">Any professional</p>
        <p className="text-center m-0 text-secondary">Maxiumum Availability</p>
      </div>
      {/* {selectedServices?.length > 1 && (
        <div
          className={`staffWrapper ${
            selectedServices?.length > 1 &&
            selectedStaffs?.length >= 1 &&
            "staffWrapper-active"
          } hc m-2 pb-2`}
          onClick={() => setShow(true)}
        >
          <PiUsersThree className="fs-1 text-center userLogo d-block my-2 mx-auto" />
          <p className="text-center m-0 fw-bold ">Select professional</p>
          <p className="text-center m-0 text-secondary">Per service</p>
        </div>
      )} */}

      {selectedServices?.length >= 1 &&
        storeStaff?.length > 0 &&
        storeStaff?.map((staff, i) => (
          <>
            {staff?.salon_staff_Id?.isDeleted ? (
              ""
            ) : (
              <div
                className={`staffWrapper hc m-2 pb-4 ${
                  selectedStaffs?.[0] === staff?._id && "staffWrapper-active"
                }`}
                key={i}
                onClick={() => setSelectedStaffs([staff?._id])}
              >
                <img src={staff?.image} className="img-fluid " alt="" />
                <p className="text-center m-0 fw-bold ">{staff?.name}</p>
                <p className="text-center m-0 text-secondary ">
                  {staff?.title}
                </p>
              </div>
            )}
          </>
        ))}
    </div>
  );
};

export default StaffSelection;
