import React, { useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import apis from "../../services";
import DeleteModal from "../Modal/DeleteModal";
import { MdDeleteForever } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setNoOfServices, setServices } from "../../redux/slices/userSlice";
import { useMutation } from "@tanstack/react-query";

const ServiceTable = ({ services }) => {
  const { user } = useSelector((e) => e.user);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = services?.slice(indexOfFirstItem, indexOfLastItem);
  const [id, setId] = useState();
  const [show, setShow] = useState(false);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(services?.length / itemsPerPage);
  const showModal = (id) => {
    setId(id);
    setShow(true);
  };

  const { mutate: delete_service, isLoading: loading } = useMutation(
    () => apis.delete_service({ id, role: user?.role }),
    {
      onError: function ({ message }) {
        toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          toast.success(data?.message);
          setShow(false);
          dispatch(setServices(data?.services));
          dispatch(setNoOfServices(data?.services.length || 0));
        }
      },
    }
  );

  return (
    <div class="">
      <DeleteModal
        deleteFunction={delete_service}
        show={show}
        setShow={setShow}
        loading={loading}
      />

      <div class="table-responsive bg-white rounded-3  mt-2">
        <table class="table table-hover ">
          <thead className="table-secondary">
            <tr>
              <th scope="col">Service Name</th>
              <th scope="col">Price</th>
              <th scope="col">Type</th>
              <th scope="col">Catogery</th>
              <th scope="col">Duration</th>
              {user?.role === "admin" && !pathname.includes("stores") && (
                <th scope="col">Salon Name</th>
              )}
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentItems?.length > 0 ? (
              currentItems?.map((service, i) => {
                return (
                  <tr key={i}>
                    <td>{service?.name}</td>
                    <td>{service?.value} PKR</td>
                    <td>
                      {service?.segment_Id === 1
                        ? "MALE"
                        : service?.segment_Id === 2
                        ? "FEMALE"
                        : service?.segment_Id === 3
                        ? "BOTH"
                        : ""}
                    </td>
                    <td>{service?.service_category_Id?.name}</td>
                    <td>{service?.duration} Min</td>
                  
                    {user?.role === "admin" && !pathname.includes("stores") && (
                      <td>{service?.store_Id?.name}</td>
                    )}
                    <td className="d-flex">
                      <IoMdEye
                        className="fs-3 text-secondary me-2 hc"
                        onClick={() =>
                          navigate(`/${user?.role}/service/details`, {
                            state: { id: service?._id },
                          })
                        }
                      />

                      <FaEdit
                        className="fs-3 text-secondary mx-2 hc"
                        onClick={() =>
                          navigate(`/${user?.role}/service/update`, {
                            state: { service: service },
                          })
                        }
                      />

                      <MdDeleteForever
                        className="fs-3 text-danger hc"
                        onClick={() => showModal(service?._id)}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <td colSpan={5} className="text-center py-5">
                No service found
              </td>
            )}
          </tbody>
        </table>
        {services?.length > 0 && (
          <div className="w-90 position-absolute bottom-0 start-50 translate-middle-x mb-3">
            <div className="row align-items-center text-center text-md-start">
              <div className="col-sm-6 ms-auto col-xs-6">
                <div className="d-flex align-items-center justify-content-center">
                  <button
                    className="pagination_btn"
                    onClick={() =>
                      setCurrentPage(
                        currentPage > 1 ? currentPage - 1 : currentPage
                      )
                    }
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  {[...Array(totalPages)].map((_, i) => (
                    <div
                      key={i}
                      className={`pageNo ${
                        i + 1 === currentPage ? "pageNo_active" : ""
                      }`}
                      onClick={() => paginate(i + 1)}
                    >
                      {i + 1}
                    </div>
                  ))}
                  <button
                    className="pagination_btn"
                    onClick={() =>
                      setCurrentPage(
                        currentPage < totalPages ? currentPage + 1 : currentPage
                      )
                    }
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceTable;
