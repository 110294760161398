import React, { useState } from "react";
import { IoMdEye } from "react-icons/io";
import { MdDeleteForever } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import apis from "../../services";
import DeleteModal from "../Modal/DeleteModal";
import { useNavigate } from "react-router-dom";
import { setStores } from "../../redux/slices/userSlice";
import { CgUnblock } from "react-icons/cg";
import { useDispatch } from "react-redux";
import { ImBlocked } from "react-icons/im";
import SuspendModal from "../Modal/SuspendModal";

const StoresTable = ({ stores }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const itemsPerPage = 10; // Define number of items per page
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = stores?.slice(indexOfFirstItem, indexOfLastItem);
  const [show, setShow] = useState(false);
  const [showSuspend, setShowSuspend] = useState(false);
  // const [id, setId] = useState(null);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [type_id, setType_id] = useState({
    type: "",
    id: "",
  });

  const totalPages = Math.ceil(stores?.length / itemsPerPage);

  const { mutate, isLoading } = useMutation(
    () => apis?.storeSuspendDelete(type_id),
    {
      onError: function ({ message }) {
        toast.error(message?.message, { id: 1 });
        setShow(false);
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          setShow(false);
          setShowSuspend(false);
          dispatch(setStores(data?.store));
          toast.success(data?.message, { id: 1 });
        }
      },
    }
  );
  return (
    <div class="table-responsive bg-white rounded-3 mt-2">
      <DeleteModal
        deleteFunction={mutate}
        show={show}
        setShow={setShow}
        loading={isLoading}
      />
      <SuspendModal
        deleteFunction={mutate}
        show={showSuspend}
        setShow={setShowSuspend}
        loading={isLoading}
      />
      <table class="table table-hover ">
        <thead className="table-secondary">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">City</th>
            <th scope="col">Phone</th>
            <th scope="col">Location</th>
            <th scope="col">Owner</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {currentItems?.length > 0 ? (
            currentItems?.map((salon) => {
              return (
                <tr>
                  <td>{salon?.name}</td>
                  <td>{salon?.city}</td>
                  <td>+{salon?.phone}</td>
                  <td>
                    {salon?.location?.length > 20
                      ? `${salon.location.substring(0, 20)}...`
                      : salon?.location}
                  </td>
                  <td>{salon?.salon_owner_Id?.name}</td>
                  <td className="d-flex">
                    <IoMdEye
                      className="fs-3 text-secondary me-2 hc"
                      onClick={() =>
                        navigate(`/admin/stores/details/${salon?.slug}`)
                      }
                    />

                    <FaEdit
                      className="fs-3 text-secondary mx-2 hc"
                      onClick={() =>
                        navigate(`/admin/${salon?.slug}/edit`)
                      }
                    />
                    {salon?.isSuspend === true ? (
                      <CgUnblock
                        className="fs-3 mx-2 text-danger hc"
                        onClick={() => {
                          setType_id({
                            type: "unsuspended",
                            id: salon?._id,
                          });
                          setShow(true);
                        }}
                      />
                    ) : (
                      <ImBlocked
                        className="fs-4 mx-2 text-danger hc"
                        onClick={() => {
                          setType_id({
                            type: "suspended",
                            id: salon?._id,
                          });
                          setShowSuspend(true);
                        }}
                      />
                    )}
                    {/* <ImBlocked
                      className="fs-4 mx-2 text-danger hc"
                      onClick={() => {
                        setType_id({
                          type: "suspended",
                          id: salon?._id,
                        });
                        setShow(true);
                      }}
                    />
                    <CgUnblock
                      className="fs-4 mx-2 text-danger hc"
                      onClick={() => {
                        setType_id({
                          type: "suspended",
                          id: salon?._id,
                        });
                        setShow(true);
                      }}
                    /> */}
                    <MdDeleteForever
                      className="fs-3 text-danger hc"
                      onClick={() => {
                        setType_id({
                          type: "deleted",
                          id: salon?._id,
                        });
                        setShow(true);
                      }}
                    />
                  </td>
                </tr>
              );
            })
          ) : (
            <td colSpan="5" className="text-center py-5 ">
              No salon found
            </td>
          )}
        </tbody>
      </table>
      {stores?.length > 0 && (
        <div className="w-90 position-absolute bottom-0 start-50 translate-middle-x mb-3">
          <div className="row align-items-center text-center text-md-start">
            <div className="col-sm-6 ms-auto  col-xs-6">
              <div className="d-flex align-items-center justify-content-center">
                <button
                  className="pagination_btn"
                  onClick={() =>
                    setCurrentPage(
                      currentPage > 1 ? currentPage - 1 : currentPage
                    )
                  }
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                {[...Array(totalPages)].map((_, i) => (
                  <div
                    key={i}
                    className={`pageNo ${
                      i + 1 === currentPage ? "pageNo_active" : ""
                    }`}
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </div>
                ))}
                <button
                  className="pagination_btn"
                  onClick={() =>
                    setCurrentPage(
                      currentPage < totalPages ? currentPage + 1 : currentPage
                    )
                  }
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StoresTable;
