import React from "react";
import { useSelector } from "react-redux";
import Layout from "../components/layout/Layout";
import SalonInfomation from "../components/register-salon/SalonInfomation";

const MySalon = () => {
  const { storeInfo, staffs, services, user, noOfStaff, noOfServices } =
    useSelector((e) => e?.user);
  return (
    <Layout>
      <SalonInfomation
        storeInfo={storeInfo}
        staffs={staffs}
        services={services}
        user={user}
        noOfStaff={noOfStaff}
        noOfServices={noOfServices}
      />
    </Layout>
  );
};

export default MySalon;
