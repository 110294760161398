import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { FaCopy } from "react-icons/fa6";
import apis from "../../services";
import { setUser } from "../../redux/slices/userSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { RiAiGenerate } from "react-icons/ri";
import ReferralPolicyModal from "../Modal/ReferralPolicyModal";

const ReferalComp = ({ referral, totalReferral }) => {
  const { user: currentUser } = useSelector((e) => e?.user);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const referralLink = `https://dashboard.beautowns.com/sign-up?referralCode=${currentUser?.referralCode}`;

  let isStore = currentUser?.role === "store" ? true : false;

  const { mutate, isLoading } = useMutation(
    () =>
      isStore
        ? apis.generateStoreReferral(currentUser?._id)
        : apis.generateStaffReferral(currentUser?._id),
    {
      onError: function ({ message }) {
        toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data: user, status }) => {
        if (status === 200) {
          if (currentUser?.role === "store") {
            dispatch(setUser(user?.store));
          } else {
            dispatch(setUser(user?.staff));
          }
          toast.success(user?.message, { id: 1 });
        }
      },
    }
  );

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(referralLink);
    toast.success("Link copied to clipboard", { id: 2 });
  };

  return (
    <div className="p-3">
      <ReferralPolicyModal show={show} setShow={setShow} />
      <h3 className="fw-bold">Referrals</h3>
      <div className="d-flex flex-wrap justify-content-center justify-content-md-start  align-items-center">
        {currentUser?.referralCode && (
          <div className="m-0 my-2 bg-white px-3 py-2 rounded-3 text-wrap">
            {referralLink}
          </div>
        )}
        {currentUser?.referralCode ? (
          <button
            onClick={() => handleCopyToClipboard()}
            className="fs-6 my-2 ms-3 d-flex align-items-center btn btn-outline-secondary"
          >
            <FaCopy className="me-2" /> COPY
          </button>
        ) : (
          <button
            onClick={mutate}
            className="fs-6 my-2 ms-3 d-flex align-items-center btn btn-outline-secondary"
          >
            <RiAiGenerate className="me-2" />
            {isLoading ? <Spinner size="sm" /> : "Generate Referral Code"}
          </button>
        )}
      </div>

      <button
        className="float-end border-0 bg-secondary text-white p-2 rounded-3"
        onClick={() => setShow(true)}
      >
        Referral Policy
      </button>

      <div className=" d-flex justify-content-between flex-wrap mt-5">
        <div className=" my-3 d-flex flex-column justify-content-center align-items-center">
          <h4>Total Rewards</h4>
          <h2 className="fw-bold">{totalReferral || 0}</h2>
        </div>
        <div className=" my-3 d-flex flex-column justify-content-center align-items-center">
          <h4>Total Invites</h4>
          <h2 className="fw-bold">{referral && referral?.length}</h2>
        </div>
      </div>
    </div>
  );
};

export default ReferalComp;
