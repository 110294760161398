import React from "react";
import Chart from "react-apexcharts";

const BestSelling = ({ data }) => {
  const chartOptions = {
    labels: Object?.keys(data || {}),
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    legend: {
      show: false, // Set to false to hide the labels parallel to the chart
    },
  };

  return (
    <div className="pie-chart-container d-flex justify-content-center">
      <Chart
        options={chartOptions}
        series={Object?.values(data || {})}
        type="donut"
        width="100%"
        // style={{minHeight: "320px"}}
        height={window.innerWidth < 485 ? 230 : 340}
        // height={window?.innerWidth > 992 ? 280 :230}
        
      />
    </div>
  );
};

export default BestSelling;
