import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
// import ChatApp from "../components/chat/ChatApp";
import StoreCustomerChat from "../components/chat/StoreCustomerChat";
import { useDispatch, useSelector } from "react-redux";
import { setBookings, setStoreInfo } from "../redux/slices/userSlice";
import { useMutation } from "@tanstack/react-query";
import apis from "../services";

const CustomerChat = ({ socket }) => {
  const { bookings,storeInfo, users, user } = useSelector((e) => e?.user);
  const [currect, setCurrent] = useState("customers");
  const dispatch = useDispatch();
  const customerList = () => {
    let list;
    if (currect === "customers") {
      list =
        bookings?.length > 0 &&
        Array.from(
          new Map(
            bookings.map((user) => [user?.user_Id?._id, user?.user_Id])
          ).values()
        );
      return list;
    } else {
      list =
        users?.length > 0 && Array.from(new Set(users.map((user) => user?.id)));
      return list;
    }
  };

  const { mutate: getBookings, isLoading } = useMutation(
    () => apis?.getStoreBookings(storeInfo?._id),
    {
      onError: function ({ message }) {
        console.log(message, "message");
        // toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          dispatch(setBookings(data?.booking));
        }
      },
    }
  );

  useEffect(() => {
    getBookings();
  }, []);

  return (
    <Layout>
      {isLoading && <div id="cover-spin"></div>}
      <StoreCustomerChat
        customers={customerList()?.length > 0 ? customerList() : []}
        user={user}
        socket={socket}
      />
    </Layout>
  );
};

export default CustomerChat;
