import React from "react";
import Layout from "../components/layout/Layout";
import SIgnUpForm from "../components/auth/SIgnUpForm";

const Userprofile = () => {
  return (
    <Layout>
      <SIgnUpForm isEdit={true} />
    </Layout>
  );
};

export default Userprofile;
