import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../redux/slices/userSlice";
import toast from "react-hot-toast";
import { errorValidate } from "../../validations/errorHandleJoi";
import { loginValidate } from "../../validations/LoginValidation";
import apis from "../../services";
import { useDispatch } from "react-redux";
import { IoIosUnlock } from "react-icons/io";
import { MdOutlineEmail } from "react-icons/md";
import { BeatLoader } from "react-spinners";

const AdminLogin = () => {
  //   const { user } = useSelector((e) => e?.user);

  //   useEffect(() => {
  //     if (user?.isVerified) {
  //       if (user?.role === "store") {
  //         navigate("/dashboard");
  //       } else if (user?.role === "staff") {
  //         navigate("/employee/dashboard");
  //       }
  //     }
  //   }, []);

  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [data, setData] = useState({ email: "", password: "", role: "admin" });
  let [error, setError] = useState();

  const onChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  console.log(error, "error");

  const { mutate, isLoading } = useMutation(apis.authLogin, {
    onError: function ({ message }) {
      setError("");
      toast.error(message?.message, { id: 1 });
    },
    onSuccess: ({ data: user, status }) => {
      if (status === 200) {
        if (user?.user?.isVerified) {
          if (user?.user?.role === "admin") {
            dispatch(setUser(user?.user));
            toast.success(user?.message, { id: 1 });
            navigate("/admin/dashboard");
          }
        }
      }
    },
  });

  const signIn = (e) => {
    e.preventDefault();
    let response = errorValidate(loginValidate(data));
    if (response === true) {
      mutate(data);
    } else {
      setError(response);
    }
  };

  return (
    <div className="Login-card">
      <form onSubmit={signIn}>
        <h1 className="text-center">Login</h1>

        <div className="mb-3 position-relative ">
          <label htmlFor="email" className="ms-2 mb-1">
            Email
          </label>
          <input
            type="text"
            name="email"
            className="form-control iconsPadding"
            onChange={onChange}
          />
          <MdOutlineEmail className="inputIconss" />

          {error?.email && <p className="text-danger">{error?.email}</p>}
        </div>
        <div className="mb-3 position-relative">
          <label htmlFor="password" className="ms-2 mb-1">
            Password
          </label>
          <input
            name="password"
            type={showPassword ? "text" : "password"}
            className="form-control iconsPadding"
            onChange={onChange}
          />
          <IoIosUnlock className="inputIconss" />
          <div
            className="eyeDiv"
            onClick={() => setShowPassword(!showPassword)}
          >
            {!showPassword ? (
              <FaRegEyeSlash className="eyeIcon" />
            ) : (
              <FaRegEye className="eyeIcon" />
            )}
          </div>
        </div>
        {error?.password && (
          <p className="text-danger password-error">{error?.password}</p>
        )}

        {/* <p className="text-end m-0 smallText mb-2">
          <Link to="/forgot-password">Forgot password?</Link>
        </p> */}
        <button
          className="login-btn fw-bold"
          disabled={isLoading}
          type="submit"
        >
          {isLoading ? (
            <BeatLoader size={15} color="#fff" />
          ) : (
            "LOGIN"
          )}
        </button>
      </form>
    </div>
  );
};

export default AdminLogin;
