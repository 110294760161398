import React from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import apis from "../../services";
import toast from "react-hot-toast";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { BeatLoader } from "react-spinners";

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    email: "",
    role: "store",
  });

  const { mutate, isLoading } = useMutation(apis.authForgot, {
    onError: function ({ message }) {
      toast.error(message?.message, { id: 1 });
    },
    onSuccess: ({ data: user, status }) => {
      if (status === 200) {
        toast.success(user?.message, { id: 1 });
        navigate("/otp-verification", {
          state: { ...data, type: "rest-password" },
        });
      }
    },
  });

  const sendOtp = (e) => {
    e.preventDefault();
    if (data?.email) {
      mutate({ email: data?.email, role: data?.role });
    } else {
      setError("Email is Required");
    }
  };

  return (
    <div className="Login-card">
      <form onSubmit={sendOtp}>
        <h1 className="text-center">Forgot Password</h1>

        <div className="d-flex justify-content-start mb-3">
          <div
            className="d-flex align-items-center fs-5 hc"
            onClick={() =>
              setData((prevData) => ({ ...prevData, role: "store" }))
            }
          >
            <input
              type="checkbox"
              className="checkBoxInput"
              checked={data?.role === "store"}
            />
            Salon
          </div>
          <div
            className="d-flex align-items-center fs-5 hc ms-4"
            onClick={() =>
              setData((prevData) => ({ ...prevData, role: "staff" }))
            }
          >
            <input
              type="checkbox"
              className="checkBoxInput"
              checked={data?.role === "staff"}
            />
            Staff
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="ms-2 mb-1">
            Email
          </label>
          <input
            type="email"
            required
            onChange={(e) =>
              setData((prevData) => ({ ...prevData, email: e.target.value }))
            }
            className="form-control"
            placeholder="store@example.com"
          />
          {error && <p className="text-danger">{error}</p>}
        </div>
        <p className="text-center m-0 mb-2">We will send you OTP via Email.</p>
        <button className="login-btn fw-bold " disabled={isLoading} type="submit">
          {isLoading ? 
          // <Spinner animation="border" />
          <BeatLoader size={15} color="#fff" />
          : "SEND OTP"}
        </button>
      </form>
    </div>
  );
};

export default ForgotPasswordForm;
