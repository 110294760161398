import React from "react";
import Layout from "../components/layout/Layout";
import toast from "react-hot-toast";
import apis from "../services";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import { useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { Spinner } from "react-bootstrap";
import {setStoreInfo} from "../redux/slices/userSlice"

const UploadGallery = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { storeInfo,user } = useSelector((e) => e.user);
  const [data, setData] = useState({ documents: [] });
  const [selectedImages, setSelectedImages] = useState([]);

  const handleImageChange = (e) => {
    const files = e.target.files;
    const imageArray = Array.from(files);
    setSelectedImages([...selectedImages, ...imageArray]);
    setData((prevState) => ({
      ...prevState,
      documents: [...prevState.documents, ...imageArray],
    }));
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const removeImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
    const updatedData = [...data?.documents];
    updatedData.splice(index, 1);
    setData((prevState) => ({ ...prevState, documents: updatedData }));
  };
  const handleUpload = async (e) => {
    e.preventDefault();
    setLoading(true);

    const form_data = new FormData();
    for (let i = 0; i < data?.documents?.length; i++) {
      form_data.append("gallery", data?.documents[i]);
    }
    try {
      const response = await apis.updateStore({
        id: storeInfo?._id,
        body: form_data,
      });
      if (response?.data?.message && response?.data?.store) {
        navigate(`/${user?.role}/gallery`);
        dispatch(setStoreInfo(response?.data?.store));
        toast.success(response?.data?.message);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  return (
    <Layout>
      <div className="p-3">
        <h3 className="fw-bold text-uppercase fw-bold mb-3">
          Upload Gallery Images
        </h3>
        <form onSubmit={handleUpload} encType="multipart/form-data">
          <div className="col-md-6 col-lg-4 ">
            <input
              type="file"
              className="form-control bg_white "
              onChange={handleImageChange}
              multiple
              accept="image/png, image/gif, image/jpeg"
              ref={fileInputRef}
            />
          </div>
          <div className="d-flex flex-wrap ">
            {data?.documents?.map((preview, index) => (
              <div className=" position-relative m-3" key={index}>
                <img
                  src={URL.createObjectURL(preview)}
                  alt={`Preview ${index}`}
                  className="img-fluid upload__images"
                />
                <br />
                <IoMdCloseCircle
                  className="removeImage p-0  bg-white rounded-5"
                  onClick={() => removeImage(index)}
                />
              </div>
            ))}
          </div>
          {data?.documents?.length > 0 && (
            <div className="col-lg-3 col-md-4 col-sm-10 mx-auto ">
              <button className="login-btn mt-3 mb-2 fw-bold">
                {loading ? <Spinner /> : "UPLOAD"}
              </button>
            </div>
          )}
        </form>
      </div>
    </Layout>
  );
};

export default UploadGallery;
