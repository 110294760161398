import Joi from "joi";
export const validateNotification = (req) => {
  const schema = Joi.object({
    store_Id: Joi.string().required(),
    target: Joi.string().required(),
    userIds:Joi.array().optional(),
    body: Joi.string().required(),
    type: Joi.string().required(),
    from: Joi.string().required(),
    to: Joi.string().required(),
    title: Joi.string().required(),
    image: Joi.any(),
    isDeleted: Joi.boolean(),
    isSuspend: Joi.boolean(),
  });
  return schema.validate(req);
};
