import React from "react";
import { Modal } from "react-bootstrap";
import { IoIosCloseCircle } from "react-icons/io";

const ImageModal = ({ show, setShow, imageAddress }) => {
  return (
    <Modal
      size="lg"
      centered
      show={show}
      onHide={setShow}
      className="bg-transparent imageModal border-0 imageModalBorder"
    >
      <Modal.Body className="w-100 h-100 bg-transparent d-flex align-items-center justify-content-center ">
        <div className="imageModalBox position-relative">
          <IoIosCloseCircle
            className="imageModalcloseBtn bg-white rounded-5   fs-1 text-danger hc"
            onClick={() => setShow(!show)}
          />
          <img
            src={imageAddress}
            className="img-fluid modalImage d-block mx-auto "
            alt=""
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ImageModal;
