import React from "react";
import { Modal } from "react-bootstrap";
import ResetPasswordForm from "../auth/ResetPasswordForm";

const ResetPasswordModal = ({ show, setShow }) => {
    let isEdit = true
  return (
    <Modal centered show={show} onHide={setShow}>
      <Modal.Header closeButton className="bg-white">
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Footer className="bg-white">
        <ResetPasswordForm isEdit={isEdit} setShow={setShow} />
      </Modal.Footer>
    </Modal>
  );
};

export default ResetPasswordModal;
