import React, { useEffect, useState } from "react";
import { IoIosSend } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

const ChatApp = ({ socket, staffs, user }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showMessages, setShowMessages] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(staffs?.[0]);
  const [chatWith, setChatWith] = useState(0);
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const [imageError, setImageError] = useState([]);
  const handleImageError = (index) => {
    setImageError((prevErrors) => {
      let newErrors = [...prevErrors];
      newErrors[index] = true;
      return newErrors;
    });
  };

  socket.on("receiveMessage", (data) => {
    setMessages(data.previousMessages || []);
  });

  useEffect(() => {
    setMessages([])
    socket.emit(
      "sendHistory",
      { receiver_Id: selectedStaff?.salon_staff_Id?._id, sender_Id: user?._id },
      (data) => {
        setMessages(data?.previousMessages || []);
      }
    );
  }, [selectedStaff]);

  const sendMessage = () => {
   
    socket.emit(
      "sendMessage",
      {
        receiver_Id: selectedStaff?.salon_staff_Id?._id,
        sender_Id: user?._id,
        message: messageInput,
      },
      (ack) => {
        console.log(ack.previousMessages, "previousMessages");
        setMessages(ack?.previousMessages || []);
      }
    );

    // Clear the message input field
    setMessageInput("");
  };

  return (
    <>
      <div className="p-3">
        <div className="row clearfix mt-4">
          <div className="col-lg-12">
            <div className="chat-app bg-white rounded-3 w-100 d-flex">
              <div
                className={`people-list overflow-hidden  ${
                  windowWidth < 768 && showMessages && "d-none"
                }`}
              >
                <div className="input-group p-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                  />
                </div>
                <div className="overflow-y-auto px-3">
                <ul className="list-unstyled chat-list mt-2 mb-0">
                  {staffs?.map((staff, index) => (
                    <li
                      className={`clearfix d-flex align-items-center ${
                        chatWith === index && "active"
                      }`}
                      onClick={() => {
                        setShowMessages(true);
                        setSelectedStaff(staff);
                        setChatWith(index);
                      }}
                      key={index}
                    >
                      <img
                        src={staff?.image}
                        className="img-fluid chatUserListImg"
                        alt="avatar"
                        style={{
                          display: imageError[index] ? "none" : "block",
                        }}
                        onError={(e) => handleImageError(index)}
                      />
                      {imageError[index] && (
                        <div className="alterWrapper text-uppercase ">
                          {staff?.name?.substring(0, 1)}
                        </div>
                      )}
                      <div className="about">
                        <div className="name textEclips">{staff?.name}</div>
                        <div className="status textEclips text-nowrap">
                          {staff?.title}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                </div>
              </div>
              <div
                className={`chat ${
                  windowWidth < 768 && !showMessages && "d-none"
                }`}
              >
                <div className="chat-header clearfix">
                  <div className="row">
                    <div className="col-lg-6 d-flex">
                      {windowWidth < 768 ? (
                        <IoIosArrowBack
                          onClick={() => setShowMessages(!showMessages)}
                          className="fs-1 hc mt-2 me-3"
                        />
                      ) : (
                        ""
                      )}
                      <img
                        src={selectedStaff?.image}
                        className="img-fluid object-fit-cover"
                        alt="avatar"
                        style={{
                          display: imageError[chatWith] ? "none" : "block",
                        }}
                      />
                      {imageError[chatWith] && (
                        <div className="alterWrapper text-uppercase ">
                          {selectedStaff?.name?.substring(0, 1)}
                        </div>
                      )}

                      <div className="chat-about">
                        <h6 className="m-b-0 mb-0 textEclips1">{selectedStaff?.name}</h6>
                        <smal className="textEclips1">{selectedStaff?.title}</smal>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="chat-history">
                  <ul className="m-b-0">
                    {messages?.map((message) => {
                      return (
                        <li className="clearfix">
                          <div
                            className={`message ${
                              message?.sender_Id === user?._id
                                ? "my-message float-right"
                                : "other-message"
                            }`}
                          >
                            {message?.message}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="chat-message clearfix">
                  <div className="position-relative">
                    <IoIosSend
                      type="button"
                      onClick={sendMessage}
                      className="sendIcon"
                    />
                    <input
                      type="text"
                      value={messageInput}
                      onChange={(e) => setMessageInput(e.target.value)}
                      className="form-control"
                      placeholder="Enter text here..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatApp;
