import React, { useEffect } from "react";
import Layout from "../components/layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import BlogTable from "../components/Table/BlogTable";
import { setBlogs } from "../redux/slices/userSlice";
import apis from "../services";
import toast from "react-hot-toast";
import { useMutation } from "@tanstack/react-query";

const Blog = () => {
  const { blogs, user, storeInfo } = useSelector((e) => e?.user);

  const dispatch = useDispatch();

  const { mutate: getBlogs, isLoading } = useMutation(
    () => apis.getStoreBlogs(storeInfo?._id),

    {
      onError: function ({ message }) {
        console.log(message, "message");
        toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          dispatch(setBlogs(data?.blog));
        }
      },
    }
  );

  useEffect(() => {
    getBlogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
       {isLoading && <div id="cover-spin"></div>}
      <div className="p-3">
        <>
          <div className="d-flex flex-wrap flex-wrap align-items-center w-100 justify-content-between">
            <h3 className="m-0 mb-3 fw-bold ">Blogs</h3>
            <div className="col-lg-2 col-md-3 col-sm-4">
              <Link to={`/${user?.role}/blog/add`}>
                <button className="login-btn my-3 fw-bold">ADD</button>
              </Link>
            </div>
          </div>
          <BlogTable blog={blogs} user={user} />
        </>
      </div>
    </Layout>
  );
};

export default Blog;
