import React from "react";
import Layout from "../components/layout/Layout";
import { useSelector } from "react-redux";
import PayrollStaffTable from "../components/Table/PayrollStaffTable";

const StaffPayroll = () => {
  const { staffs, user } = useSelector((e) => e?.user);

  return (
    <Layout>
      <div className="p-3">
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="fw-bold">Staff Payroll</h3>
        </div>
        <PayrollStaffTable user={user} staffs={staffs} />
      </div>
    </Layout>
  );
};

export default StaffPayroll;
