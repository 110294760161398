import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import {
  setNoOfCategory,
  setNoOfServices,
  setNoOfStaff,
  setUser,
} from "../../redux/slices/userSlice";
import toast from "react-hot-toast";
import { errorValidate } from "../../validations/errorHandleJoi";
import { loginValidate } from "../../validations/LoginValidation";
import apis from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { IoIosUnlock } from "react-icons/io";
import { MdOutlineEmail } from "react-icons/md";
import { setStoreInfo, setStaffDetails } from "../../redux/slices/userSlice";
import { BeatLoader } from "react-spinners";

const LoginForm = () => {
  const { user } = useSelector((e) => e.user);

  useEffect(() => {
    if (user?.isVerified) {
      if (user?.role === "store") {
        navigate("/store/dashboard");
      } else if (user?.role === "staff") {
        navigate("/staff/dashboard");
      }
    }
  }, []);

  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [formData, setData] = useState({
    email: "",
    password: "",
    role: "store",
  });
  let [error, setError] = useState();

  const onChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  console.log(error, "error");

  const { mutate, isLoading } = useMutation(apis.authLogin, {
    onError: function ({ message }) {
      setError("");
      toast.error(message?.message, { id: 1 });
    },
    onSuccess: ({ data, status }) => {
      if (status === 200) {
        if (data?.user?.isVerified) {
          if (data?.user?.role === "store") {
            // console.log(data,"data")
            dispatch(setUser(data?.user));
            dispatch(setNoOfCategory(data?.noOfCategory));
            dispatch(setNoOfServices(data?.noOfServices));
            dispatch(setNoOfStaff(data?.noOfStaff));
            dispatch(setStoreInfo(data?.store));
            toast.success(data?.message, { id: 1 });
            // if (user?.store && user?.store?.completeProgess === 2) {
            //   (async () => {
            //     await dispatch(reduxApis?.getCompleteInfo(user?.store?._id));
            //   })();
            // }
            navigate("/store/dashboard");
          } else {
            console.log(data,"data login")
            dispatch(setUser(data?.user));
            dispatch(setStaffDetails(data?.staffDetail));
            toast.success(data?.message, { id: 1 });
            // (async () => {
            //   await dispatch(
            //     reduxApis?.getCompleteStaff(data?.staffDetail?._id)
            //   );
            // })();
            navigate("/staff/dashboard");
          }
        } else {
          toast.success(data?.message, { id: 1 });
          // console.log(data,"data")
          navigate("/otp-verification", { state: { email: formData?.email } });
        }
      }
    },
  });

  const signIn = (e) => {
    e.preventDefault();
    let response = errorValidate(loginValidate(formData));
    if (response === true) {
      mutate(formData);
    } else {
      setError(response);
    }
  };

  return (
    <div className="Login-card">
      <form onSubmit={signIn}>
        <h1 className="text-center">Login</h1>
        <div className="d-flex justify-content-start mb-3">
          <div
            className="d-flex align-items-center fs-5 hc"
            onClick={() =>
              setData((prevData) => ({ ...prevData, role: "store" }))
            }
          >
            <input
              type="checkbox"
              className="checkBoxInput"
              checked={formData?.role === "store"}
            />
            Salon
          </div>
          <div
            className="d-flex align-items-center fs-5 hc ms-4"
            onClick={() =>
              setData((prevData) => ({ ...prevData, role: "staff" }))
            }
          >
            <input
              type="checkbox"
              className="checkBoxInput"
              checked={formData?.role === "staff"}
            />
            Staff
          </div>
        </div>

        <div className="mb-3 position-relative ">
          <label htmlFor="email" className="ms-2 mb-1">
            Email
          </label>
          <input
            type="text"
            name="email"
            className="form-control iconsPadding"
            onChange={onChange}
          />
          <MdOutlineEmail className="inputIconss" />

          {error?.email && <p className="text-danger">{error?.email}</p>}
        </div>
        <div className="mb-3 position-relative">
          <label htmlFor="password" className="ms-2 mb-1">
            Password
          </label>
          <input
            name="password"
            type={showPassword ? "text" : "password"}
            className="form-control iconsPadding"
            onChange={onChange}
          />
          <IoIosUnlock className="inputIconss" />
          <div
            className="eyeDiv"
            onClick={() => setShowPassword(!showPassword)}
          >
            {!showPassword ? (
              <FaRegEyeSlash className="eyeIcon" />
            ) : (
              <FaRegEye className="eyeIcon" />
            )}
          </div>
        </div>
        {error?.password && (
          <p className="text-danger password-error">{error?.password}</p>
        )}

        <p className="text-end m-0 smallText mb-2">
          <Link to="/forgot-password">Forgot password?</Link>
        </p>
        <button className="login-btn fw-bold" disabled={isLoading} type="submit">
          {isLoading ? (
            // <Spinner animation="border" />
            <BeatLoader size={15} color="#fff" />
          ) : (
            "LOGIN"
          )}
        </button>
      </form>
      <p className="text-center smallText m-0 my-2">OR</p>

      <Link to="/sign-up">
        <button className="login-btn fw-bold " type="submit">
          SIGNUP NOW
        </button>
      </Link>

      <p className="text-center m-0 my-2 smallText">OR</p>
      <Link
        to="https://beautowns.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <p className="text-center mb-0 text-dark my-2 fw-medium">
          Login as a customer
        </p>
      </Link>
    </div>
  );
};

export default LoginForm;
