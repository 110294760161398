import React, { useState } from "react";
import apis from "../../services";
import DeleteModal from "../Modal/DeleteModal";
import toast from "react-hot-toast";
import { MdDeleteForever } from "react-icons/md";
import { useDispatch } from "react-redux";
import { FaEdit } from "react-icons/fa";
import { setStoreCategory } from "../../redux/slices/userSlice";

const StoreCategoryTable = ({ storeCategories, updating }) => {
  const dispatch = useDispatch();
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = storeCategories?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState();
  const [show, setShow] = useState(false);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const showModal = (id) => {
    setId(id);
    setShow(true);
  };

  const totalPages = Math.ceil(storeCategories?.length / itemsPerPage);

  const deleteStoreCateogry = async () => {
    try {
      setLoading(true);
      const response = await apis.deleteStoreCategory(id);
      toast.success(response?.data?.message);
      setLoading(false);
      setShow(false);
      if (response?.data?.categories) {
        dispatch(setStoreCategory(response?.data?.categories));
      }
    } catch (error) {
      if (error?.message?.message) {
        toast.error(error?.message?.message);
      }
      setLoading(false);
      console.log(error, "error");
    }
  };

  return (
    <>
      <div className="">
        <DeleteModal
          deleteFunction={deleteStoreCateogry}
          show={show}
          setShow={setShow}
          loading={loading}
        />
        <div class="table-responsive bg-white rounded-3 mt-2">
          <table class="table table-hover ">
            <thead className="table-secondary">
              <tr>
                <th scope="col">Category Name</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems?.length > 0 ? (
                currentItems?.map((cat) => {
                  return (
                    <tr>
                      <td>{cat?.name}</td>
                      <td>
                        <div className="d-flex">
                          <FaEdit
                            className="fs-3 me-3 text-secondary hc"
                            onClick={() => updating(cat)}
                          />
                          <MdDeleteForever
                            className="fs-3 text-danger hc"
                            onClick={() => showModal(cat?._id)}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <td colSpan={2} className="text-center py-5">
                  No store category found
                </td>
              )}
            </tbody>
          </table>
          {storeCategories?.length > 0 && (
            <div className="w-90 position-absolute bottom-0 start-50 translate-middle-x mb-3">
              <div className="row align-items-center text-center text-md-start">
                <div className="col-sm-6 ms-auto col-xs-6">
                  <div className="d-flex align-items-center justify-content-center">
                    <button
                      className="pagination_btn"
                      onClick={() =>
                        setCurrentPage(
                          currentPage > 1 ? currentPage - 1 : currentPage
                        )
                      }
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    {[...Array(totalPages)].map((_, i) => (
                      <div
                        key={i}
                        className={`pageNo ${
                          i + 1 === currentPage ? "pageNo_active" : ""
                        }`}
                        onClick={() => paginate(i + 1)}
                      >
                        {i + 1}
                      </div>
                    ))}
                    <button
                      className="pagination_btn"
                      onClick={() =>
                        setCurrentPage(
                          currentPage < totalPages
                            ? currentPage + 1
                            : currentPage
                        )
                      }
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default StoreCategoryTable;
