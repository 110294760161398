import { useMutation } from "@tanstack/react-query";
import moment from "moment";
import React, { useState } from "react";
import apis from "../../services";
import toast from "react-hot-toast";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStaffs } from "../../redux/slices/userSlice";
import { BeatLoader } from "react-spinners";

const StaffTime = ({ staff_id, isEdit, staff_details }) => {
  const navigate = useNavigate();
  const { user, storeInfo } = useSelector((e) => e.user);
  let store_timings = storeInfo?.store_timings;

  const dispatch = useDispatch();
  let workingSchedule = staff_details?.workingSchedule;
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const initialTimeState = days.map((day, index) => ({
    day: day,
    from: workingSchedule?.[index]?.from || store_timings[index].from,
    to: workingSchedule?.[index]?.to || store_timings[index].to,
    isAvailable: workingSchedule?.[index]?.isAvailable || store_timings[index].isAvailable,
  }));

  const [time, setTime] = useState(isEdit ? workingSchedule : initialTimeState);

  const handleTimeChange = (field, value, dayIndex, timeType) => {
    setTime((prevStoreTimings) => {
      const updatedStoreTimings = [...prevStoreTimings];

      updatedStoreTimings[dayIndex] = {
        ...updatedStoreTimings[dayIndex],
        [field]: value,
      };
      if (timeType === "from") {
        const [hours, minutes] =
          updatedStoreTimings[dayIndex]?.from?.split(":") || "";
        let newPeriod = minutes?.slice(-2);
        let newhour = hours;
        let newMinute = minutes?.slice(0, -2);
        if (field === "period") {
          newPeriod = value;
        } else if (field === "hour") {
          newhour = value;
        } else if (field === "minute") {
          newMinute = value;
        }

        let fromTime = moment(
          `${newhour}:${newMinute} ${newPeriod}`,
          "hh:mm A"
        ).format("hh:mmA");
        let salonStartTime = moment(store_timings[dayIndex].from, "h:mma");
        let salonEndTime = moment(store_timings[dayIndex].to, "h:mma");
        let givenTime = moment(fromTime, "h:mma");

        let compares = givenTime
          ? givenTime?.isBetween(salonStartTime, salonEndTime)
          : false;
        if (compares) {
          updatedStoreTimings[dayIndex] = {
            ...updatedStoreTimings[dayIndex],
            from: fromTime,
          };
        } else {
          toast.error(
            `${days[dayIndex]} start time must be between ${store_timings[dayIndex].from} to ${store_timings[dayIndex].to}`,
            { id: 1 }
          );
        }
      } else if (timeType === "to") {
        const [hours, minutes] =
          updatedStoreTimings[dayIndex]?.to?.split(":") || "";
        let newPeriod = minutes?.slice(-2);
        let newhour = hours;
        let newMinute = minutes?.slice(0, -2);
        if (field === "period") {
          newPeriod = value;
        } else if (field === "hour") {
          newhour = value;
        } else if (field === "minute") {
          newMinute = value;
        }

        const ToTime = moment(
          `${newhour}:${newMinute} ${newPeriod}`,
          "hh:mm A"
        ).format("hh:mmA");

        let salonStartTime = moment(store_timings[dayIndex].from, "h:mma");
        let salonEndTime = moment(store_timings[dayIndex].to, "h:mma");
        let givenTime = moment(ToTime, "h:mma");

        let compares = givenTime
          ? givenTime?.isBetween(salonStartTime, salonEndTime)
          : false;
        if (compares) {
          updatedStoreTimings[dayIndex] = {
            ...updatedStoreTimings[dayIndex],
            to: ToTime,
          };
        } else {
          toast.error(
            `${days[dayIndex]} end time must be between ${store_timings[dayIndex].from} to ${store_timings[dayIndex].to}`,
            { id: 1 }
          );
        }
      }
      return updatedStoreTimings;
    });
  };

  const formattedStoreTimings = time?.map((day) => ({
    day: day.day,
    from: day.from,
    to: day.to,
    isAvailable: day.isAvailable,
  }));

  const { mutate, isLoading } = useMutation(
    () =>
      apis.update_Staff({
        id: isEdit ? staff_details?._id : staff_id,
        body: {
          workingSchedule: formattedStoreTimings,
        },
      }),
    {
      onError: function ({ message }) {
        toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          dispatch(setStaffs(data?.staffs));
          toast.success(data?.message, { id: 1 });
          if(user?.role==="admin"){
            navigate(`/admin/stores/details/${storeInfo?.slug}`,{state:{tab:"staff"}})
          }else{
            navigate(`/${user?.role}/staff`);
          }
        }
      },
    }
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formattedStoreTimings) {
      mutate();
    }
  };

  return (
    <div className=" time__container bg-white p-3 rounded-3 ">
      <h2 className="fw-bold text-center text-uppercase mb-3">
        {isEdit ? "Update Staff Timing" : "Staff Timing"}
      </h2>
      <form onSubmit={handleSubmit}>
        {time &&
          time?.map((day, index) => (
            <div
              className="d-flex justify-content-md-center  align-items-center mb-2"
              key={index}
            >
              <span className="m-0 week__day__name">{day?.day}</span>
              <div className="d-flex flex-column ">
                <label className="ms-2">Opening Time</label>
                <div className="d-flex">
                  <select
                    className="time__input mx-1"
                    value={moment(day?.from, "hh:mm A").format("hh")}
                    onChange={(e) =>
                      handleTimeChange("hour", e.target.value, index, "from")
                    }
                    defaultValue="10"
                  >
                    {Array.from({ length: 12 }, (_, i) =>
                      (i + 1).toString().padStart(2, "0")
                    ).map((hour) => (
                      <option key={hour} value={hour}>
                        {hour}
                      </option>
                    ))}
                  </select>

                  <select
                    className="time__input mx-1"
                    value={moment(day?.from, "hh:mm A").format("mm")}
                    onChange={(e) =>
                      handleTimeChange("minute", e.target.value, index, "from")
                    }
                  >
                    {["00", "15", "30", "45"].map((minute) => (
                      <option key={minute} value={minute}>
                        {minute}
                      </option>
                    ))}
                  </select>
                  <select
                    className="time__input mx-1"
                    value={moment(day?.from, "hh:mm A").format("A")}
                    onChange={(e) =>
                      handleTimeChange("period", e.target.value, index, "from")
                    }
                  >
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                </div>
              </div>

              <div className="d-flex flex-column ms-3">
                <label className="ms-2">Closing Time</label>
                <div className="d-flex">
                  <select
                    className="time__input mx-1"
                    value={moment(day?.to, "hh:mm A").format("hh")}
                    onChange={(e) =>
                      handleTimeChange("hour", e.target.value, index, "to")
                    }
                  >
                    {Array.from({ length: 12 }, (_, i) =>
                      (i + 1).toString().padStart(2, "0")
                    ).map((hour) => (
                      <option key={hour} value={hour}>
                        {hour}
                      </option>
                    ))}
                  </select>

                  <select
                    className="time__input mx-1"
                    value={moment(day?.to, "hh:mm A").format("mm")}
                    onChange={(e) =>
                      handleTimeChange("minute", e.target.value, index, "to")
                    }
                  >
                    {["00", "15", "30", "45"].map((minute) => (
                      <option key={minute} value={minute}>
                        {minute}
                      </option>
                    ))}
                  </select>
                  <select
                    className="time__input ms-1"
                    value={moment(day?.to, "hh:mm A").format("A")}
                    onChange={(e) =>
                      handleTimeChange("period", e.target.value, index, "to")
                    }
                  >
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                </div>
              </div>
              <div className="sliderbtn">
                <label
                  className={`time_switch ${day.isAvailable ? "checked" : ""}`}
                >
                  <input
                    type="checkbox"
                    checked={day.isAvailable}
                    onChange={(e) =>
                      handleTimeChange("isAvailable", e.target.checked, index)
                    }
                  />
                  <div className="slider"></div>
                </label>
              </div>
            </div>
          ))}

        <div className="col-lg-3 col-md-5 col-sm-10 mx-auto ">
          <button className="login-btn mt-3 mb-2 fw-bold" type="submit">
            {isLoading ? 
            // <Spinner />
            <BeatLoader size={15} color="#fff" />
            : "SAVE"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default StaffTime;
