import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import AddSalon from "../components/register-salon/AddSalon";
import SalonTime from "../components/register-salon/SalonTime";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import apis from "../services";
import {
  setBlogs,
  setBookings,
  setCategory,
  setCoupons,
  setServices,
  setStaffs,
  setStoreID,
  setStoreInfo,
} from "../redux/slices/userSlice";

const EditMySalon = () => {
  const [key, setKey] = useState("info");
  const { user } = useSelector((e) => e?.user);
  const { slug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { mutate: getAdminStore, isLoading } = useMutation(
    () => apis.getAdminCompleteStore(slug),
    {
      onError: function ({ message }) {
        // toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          dispatch(setStaffs(data?.staff));
          dispatch(setServices(data?.services));
          dispatch(setStoreInfo(data?.StoreDetails));
          dispatch(setStoreID(data?.StoreDetails?._id));
          dispatch(setBlogs(data?.blogs));
          dispatch(setCoupons(data?.coupons));
          dispatch(setCategory(data?.category));
          dispatch(setBookings(data?.bookings));
        }
      },
    }
  );

  useEffect(() => {
    if (user?.role === "admin") {
      if (!slug) {
        navigate("/admin/stores");
      } else {
        getAdminStore();
      }
    }
  }, []);
  return (
    <Layout>
      {isLoading && <div id="cover-spin"></div>}
      <div className="p-3">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="info" title="Salon Information">
            <AddSalon isEdit={true} />
          </Tab>
          <Tab eventKey="timing" title="Salon Timing">
            <SalonTime isEdit={true} progress={2} />
          </Tab>
        </Tabs>
      </div>
    </Layout>
  );
};

export default EditMySalon;
