import React, { useEffect } from "react";
import Layout from "../components/layout/Layout";
import SalonService from "../components/salon-services/SalonService";
import { useDispatch, useSelector } from "react-redux";
import { setLoader, setNoOfServices, setServices } from "../redux/slices/userSlice";
import apis from "../services";
import toast from "react-hot-toast";
import { useMutation } from "@tanstack/react-query";

const Services = () => {
  const { services, user, storeInfo } = useSelector((e) => e?.user);
  const dispatch = useDispatch();
  
  const { mutate: getServices, isLoading } = useMutation(
    () =>
      apis.getStoreServices({
        id: user?.role === "store" ? storeInfo?._id : user?._id,
        role: user?.role,
      }),
    {
      onError: function ({ message }) {
        console.log(message, "message");
 
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          dispatch(setServices(data?.service));
          dispatch(setNoOfServices(data?.service.length || 0));
        }
      },
    }
  );

  useEffect(() => {
    getServices();
  }, []);
  return (
    <Layout>
      {isLoading && <div id="cover-spin"></div>}
      <SalonService user={user} services={services} />
    </Layout>
  );
};

export default Services;
