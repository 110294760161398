import React from "react";
import Layout from "../components/layout/Layout";
import AddAdminCouponComp from "../components/coupon/AddAdminCouponComp";

const AddAdminCoupon = () => {
  return (
    <Layout>
      <AddAdminCouponComp />
    </Layout>
  );
};

export default AddAdminCoupon;
