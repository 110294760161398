import React from "react";
import Chart from "react-apexcharts";

const ServicesPieChart = ({ data }) => {
  const chartOptions = {
    labels: Object?.keys(data || {}),
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    legend: {
      show: false, // Set to false to hide the labels parallel to the chart
    },
  };

  return (
    <div className="pie-chart-container d-flex justify-content-center w-100">
      <Chart
        options={chartOptions}
        series={Object?.values(data || {})}
        type="pie"
        width="100%"
        // height={340}
        height={window.innerWidth <  485 ? 230 : 340}
      />
    </div>
  );
};

export default ServicesPieChart;
