import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import StoreRegisterModal from "../Modal/StoreRegisterModal";
import UnAuthenticated from "./UnAuthenticated";
import { useSidebar } from "./SidebarContext";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Layout = ({ children }) => {
  const { isSidebarOpen } = useSidebar();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const { user, storeInfo } = useSelector((e) => e.user);
  const location = useLocation();
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment !== "");

  useEffect(() => {
    if (!user) {
      navigate("/login");
    } else if (
      user?.role === "store" &&
      (!storeInfo || storeInfo?.completeProgess < 2)
    ) {
      setShowModal(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={`${isSidebarOpen ? "AppGlass" : "AppGlass AppGlass_inactive"}`}
    >
      <Sidebar />
      <main>
        <Header />
        <StoreRegisterModal showModal={showModal} setShowModal={setShowModal} />
        {pathSegments.length > 0 && (
          <div className="m-3 useremail text-secondary">
            {location.pathname === "/dashboard" || user?.role === "staff" ? (
              ""
            ) : (
              ""
              // <span>
              //   <Link to="/" className="text-secondary">
              //     {"Dashboard > "}
              //   </Link>
              // </span>
            )}

            {/* {pathSegments?.map((segment, index) => (
              <span key={index} className="text-capitalize">
                <Link
                  to={`/${pathSegments.slice(0, index + 1).join("/")}`}
                  className="text-secondary"
                >
                  {segment}
                </Link>
                {index < pathSegments.length - 1 && " > "}
              </span>
            ))} */}
            {pathSegments?.map((segment, index) => (
              <span key={index} className="text-capitalize">
                {index < pathSegments.length - 1 ? (
                  <Link
                    to={`/${pathSegments.slice(0, index + 1).join("/")}`}
                    className="text-secondary"
                  >
                    {segment}
                  </Link>
                ) : (
                  <span className="text-secondary">{segment}</span>
                )}
                {index < pathSegments?.length - 1 && <span className="mx-2">{" > "}</span>}
              </span>
            ))}
          </div>
        )}
        <UnAuthenticated />
        {children}
   
      </main>
    </div>
  );
};

export default Layout;
