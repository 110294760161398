import { IoIosPeople } from "react-icons/io";
import { TfiStatsUp } from "react-icons/tfi";
import { IoTicketSharp } from "react-icons/io5";
import { FaGifts, FaBloggerB } from "react-icons/fa";
import { CiShop } from "react-icons/ci";
import { LuStore } from "react-icons/lu";
import { BsChatText } from "react-icons/bs";
import { HiMiniUsers } from "react-icons/hi2";
import { MdSupportAgent } from "react-icons/md";
import { MdMarkEmailRead } from "react-icons/md";
import { TbCategory2 } from "react-icons/tb";
import { IoMdChatboxes } from "react-icons/io";
import { MdOutlineCategory } from "react-icons/md";
import { VscReferences } from "react-icons/vsc";
import { IoIosNotifications } from "react-icons/io";
import { AiOutlineDashboard } from "react-icons/ai";
import { CiWallet } from "react-icons/ci";
import { SlCalender } from "react-icons/sl";
import { IoBriefcaseOutline } from "react-icons/io5";

// Sidebar Data
export const Menu = [
  {
    icon: AiOutlineDashboard,
    heading: "Dashboard",
    path: "/store/dashboard",
  },
  {
    icon: TfiStatsUp,
    heading: "Analytics",
    path: "/store/analytics",
  },
  {
    icon: SlCalender,
    heading: "Calendar",
    path: "/store/calander",
  },
  {
    icon: IoTicketSharp,
    heading: "Bookings",
    subMenu: [
      {
        heading: "Create bookings",
        path: "/store/booking/add",
      },
      {
        heading: "View bookings",
        path: "/store/booking",
      },
      // {
      //   heading: "Transaction History",
      //   path: "/booking/transactions",
      // },
    ],
  },
  {
    icon: CiShop,
    heading: "My Salon",
    subMenu: [
      {
        heading: "View salon",
        path: "/store/my-salon",
      },
      {
        heading: "Edit salon",
        path: "/store/my-salon/edit",
      },
      {
        heading: "View gallery",
        path: "/store/gallery",
      },
      {
        heading: "Upload gallery",
        path: "/store/gallery/upload",
      },
    ],
  },
  {
    icon: IoBriefcaseOutline,
    heading: "Salon Services",
    subMenu: [
      {
        heading: "View services",
        path: "/store/service",
      },
      {
        heading: "Add service",
        path: "/store/service/add",
      },
      {
        heading: "Category",
        path: "/store/service-category",
      },
    ],
  },
  {
    icon: IoIosPeople,
    heading: "Staff",
    subMenu: [
      {
        heading: "View staff",
        path: "/store/staff",
      },
      {
        heading: "Add staff",
        path: "/store/staff/add",
      },
      {
        heading: "Staff bookings",
        path: "/store/staff/bookings",
      },
      // {
      //   heading: "Staff Payroll",
      //   path: "/store/payroll",
      // },
      // {
      //   heading: "Staff Chat",
      //   path: "/chat",
      // },
    ],
  },
  {
    icon: FaGifts,
    heading: "Coupons",
    subMenu: [
      {
        heading: "View coupons",
        path: "/store/coupon",
      },
      {
        heading: "Create coupon",
        path: "/store/coupon/add",
      },
      // {
      //   heading: "View Coupon Bookings",
      //   path: "/coupon",
      // },
    ],
  },
  {
    icon: MdMarkEmailRead,
    heading: "Marketing",
    subMenu: [
      {
        heading: "View Offers/template",
        path: "/store/view-marketing-template",
      },
      {
        heading: "Create Design",
        path: "/store/create-marketing-template",
      },
    ],
  },
  {
    icon: HiMiniUsers,
    heading: "Customers",
    path: "/store/customers",
  },
  {
    icon: IoIosNotifications,
    heading: "Notifications",
    subMenu: [
      {
        key: "notifications_view",
        heading: "View",
        path: "/store/notifications",
      },
      {
        key: "notifications_send",
        heading: "Send",
        path: "/store/notifications/send",
      },
    ],
  },
  {
    icon: VscReferences,
    heading: "Referral",
    path: "/store/referal",
  },
  {
    icon: CiWallet,
    heading: "Wallet",
    path: "/store/wallet",
  },
  {
    icon: FaBloggerB,
    heading: "Blogs",
    path: "/store/blog",
  },
  {
    icon: BsChatText,
    heading: "Chat System",
    subMenu: [
      {
        heading: "Chat Customer",
        path: "/store/customer-chat",
      },
      {
        heading: "Chat Staff",
        path: "/store/staff-chat",
      },
    ],
  },
  {
    icon: MdSupportAgent,
    heading: "Customer Support",
    path: "/store/customer-support",
  },
];

export const staffMenu = [
  {
    icon: AiOutlineDashboard,
    heading: "Dashboard",
    path: "/staff/dashboard",
  },
  {
    icon: SlCalender,
    heading: "Calendar",
    path: "/staff/calander",
  },
  {
    icon: IoTicketSharp,
    heading: "Bookings",
    path: "/staff/booking",
  },
  {
    icon: CiWallet,
    heading: "Wallet",
    path: "/staff/wallet",
  },
  {
    icon: VscReferences,
    heading: "Referral ",
    path: "/staff/referal",
  },
  {
    icon: IoMdChatboxes,
    heading: "Chat",
    path: "/staff/chat",
  },
  {
    icon: IoIosNotifications,
    heading: "Notifications",
    path: "/staff/notifications",
  },
];

export const AdminMenu = [
  {
    icon: AiOutlineDashboard,
    heading: "Dashboard",
    path: "/admin/dashboard",
  },
  {
    icon: LuStore,
    heading: "Stores",
    path: "/admin/stores",
  },
  // {
  //   icon: SlCalender,
  //   heading: "Calendar",
  //   path: "/admin/admin/calander",
  // },
  {
    icon: IoTicketSharp,
    heading: "Bookings",
    path: "/admin/booking",
  },
  {
    icon: IoIosPeople,
    heading: "Staff",
    path: "/admin/staff",
  },
  {
    icon: IoBriefcaseOutline,
    heading: "Services",
    path: "/admin/service",
  },

  {
    icon: MdOutlineCategory,
    heading: "Service Category",
    path: "/admin/service-category",
  },
  {
    icon: TbCategory2,
    heading: "Store Category",
    path: "/admin/store-category",
  },
  {
    icon: FaGifts,
    heading: "Coupons",
    path: "/admin/admin-coupon",
  },
  {
    icon: HiMiniUsers,
    heading: "Customers",
    path: "/admin/customers",
  },

  // {
  //   icon: CiWallet,
  //   heading: "Wallet",
  //   path: "/admin/wallet",
  // },
  // {
  //   icon: VscReferences,
  //   heading: "Referral ",
  //   path: "/admin/referal",
  // },
  // {
  //   icon: IoMdChatboxes,
  //   heading: "Chat",
  //   path: "/employee/chat",
  // },
  {
    icon: IoIosNotifications,
    heading: "Notifications",
    path: "/admin/notifications",
  },
];
