import React, { useEffect } from "react";
import Layout from "../components/layout/Layout";
import { Link } from "react-router-dom";
import CouponTable from "../components/Table/CouponTable";
import { useDispatch, useSelector } from "react-redux";
import { setCoupons } from "../redux/slices/userSlice";
import apis from "../services";
import { useMutation } from "@tanstack/react-query";
import AdminCouponTable from "../components/Table/AdminCouponTable";

const AdminCoupon = () => {
  const { coupons } = useSelector((e) => e.user);
  const dispatch = useDispatch();

  const { mutate: getCoupons, isLoading } = useMutation(
    () => apis.getAdminCoupon(),
    {
      onError: function ({ message }) {
        // toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          console.log("data", data);
          dispatch(setCoupons(data?.coupons));
        }
      },
    }
  );

  useEffect(() => {
    getCoupons();
  }, []);

  return (
    <Layout>
      {isLoading && <div id="cover-spin"></div>}
      <div className="p-3">
        <div className="d-flex flex-wrap flex-wrap align-items-center w-100 justify-content-between">
          <h3 className="m-0 mb-4 fw-bold ">Admin Coupons</h3>
          <div className="col-lg-2 col-md-3 col-sm-4">
            <Link to={`/admin/admin-coupon/add`}>
              <button className="login-btn mt-3 mb-4 fw-bold px-4">ADD</button>
            </Link>
          </div>
        </div>
        <AdminCouponTable coupons={coupons} />
      </div>
    </Layout>
  );
};

export default AdminCoupon;
