import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import BookingCheckInTable from "../components/Table/BookingCheckInTable";
import { setBookings, setStaffs } from "../redux/slices/userSlice";
import { useMutation } from "@tanstack/react-query";
import apis from "../services";

const StaffBookings = () => {
  const { staffs, user, storeInfo ,bookings} = useSelector((e) => e?.user);
  const [id, setId] = useState(null);
  
  let filtered =
    (id && bookings?.filter((e) => e?.salon_staff_Id?._id === id)) || [];

  const dispatch = useDispatch();

  const { mutate: getStaff,isLoading:getingStaffs } = useMutation(
    () =>
      apis.getStoreStaff({
        id: user?.role === "store" ? storeInfo?._id : user?._id,
        role: user?.role,
      }),
    {
      onError: function ({ message }) {
        // toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          dispatch(setStaffs(data?.staff));
        }
      },
    }
  );

  const { mutate: getBookings, isLoading } = useMutation(
    () => apis.getStoreBookings(storeInfo?._id),
    {
      onError: function ({ message }) {
        console.log(message, "message");
        // toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          dispatch(setBookings(data?.booking));
        }
      },
    }
  );

  useEffect(() => {
    getStaff();
    getBookings();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
       {(getingStaffs || isLoading) && <div id="cover-spin"></div>}
      <div className="p-3 mt-3">
        <div className="col-lg-3 col-md-5">
          <label htmlFor="staff">Select Staff</label>
          <select
            className="form-control bg_white"
            onChange={(e) => {
              setId(e.target.value);
            }}
          >
            <option value="">Select Staff</option>
            {staffs?.length > 0 ? (
              staffs?.map((staff) => (
                <option value={staff._id}>{staff?.name}</option>
              ))
            ) : (
              <option disabled>No Staff Found</option>
            )}
          </select>
        </div>
        {filtered?.length > 0 && (
          <BookingCheckInTable
            isStaffBooking={true}
            bookings={filtered || []}
          />
        )}
      </div>
    </Layout>
  );
};

export default StaffBookings;
