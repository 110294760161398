import React from "react";
import { Modal } from "react-bootstrap";
import { FaAngleDown } from "react-icons/fa6";
import { FaUserCircle } from "react-icons/fa";

const StaffSelectionModal = ({
  selectedStaffs,
  show,
  setIndex,
  setShow,
  seletedService,
  storeStaff,
  setServiceName,
  setShowMajor,
  updateStep,
}) => {
  return (
    <Modal size="md" centered show={show} onHide={setShow} className="">
      <Modal.Header closeButton className="bg-white">
        <Modal.Title>Select professional per service</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-white majorStaffWrapper ">
        {seletedService?.length > 0 &&
          seletedService?.map((service, i) => {
            let [staff] =
              storeStaff?.length > 0
                ? storeStaff?.filter((e) => e._id === selectedStaffs[i])
                : [];
            return (
              <div className="service-wrapper">
                <p className="m-0 h6">{service?.name}</p>
                <p className="m-0 text-secondary">{service?.duration} min</p>

                <div
                  className="staff-selection-wrapper d-flex align-items-center"
                  onClick={() => {
                    setIndex(i);
                    setServiceName(service?.name);
                    setShow(false);
                    setShowMajor(true);
                  }}
                >
                  {selectedStaffs?.length > 0 && selectedStaffs?.[i] ? (
                    <>
                      <img
                        src={staff?.image}
                        className="img-fluid staff-selection-img"
                        alt=""
                      />
                      <p className="m-0 mx-2">{staff?.name}</p>
                      <FaAngleDown className="ms-2 me-1" />
                    </>
                  ) : (
                    <>
                      <FaUserCircle className="fs-2 text-secondary " />
                      <p className="m-0 mx-2">Any professional</p>
                      <FaAngleDown className="ms-2 me-1" />
                    </>
                  )}
                </div>
              </div>
            );
          })}
      </Modal.Body>
      <Modal.Footer className="bg-white py-0 ">
        <div className="d-flex">
          <button
            className="login-btn text-white border-0  px-4"
            onClick={() => {
              setShow(false);
              updateStep(3);
            }}
          >
            Continue
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default StaffSelectionModal;
