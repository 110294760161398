import React from "react";
import { useSelector } from "react-redux";

const UnAuthenticated = () => {
  const { user, storeInfo, noOfStaff, noOfCategory, noOfServices, loading } =
    useSelector((state) => state.user);
  const getStatus = () => {
    if (noOfStaff < 1) {
      return "You are not part of beautowns yet. please add staff member";
    } else if (noOfCategory < 1) {
      return "You are not part of beautowns yet. please add service categories";
    } else if (noOfServices < 1) {
      return "You are not part of beautowns yet. please add services";
    }
  };
  return (
    <>
      {/* {user?.role === "store" &&
        (!storeInfo || storeInfo?.completeProgess < 2) && (
          <div className="profile_not_completed">
            Please complete the registration process !!!
          </div>
        )} */}
      {!loading &&
        user?.role === "store" &&
        storeInfo?.completeProgess === 2 &&
        (noOfStaff < 1 || noOfCategory < 1 || noOfServices < 1) && (
          <div className="profile_not_completed position-absolute top-0 start-50 translate-middle-x">
            {getStatus()}
          </div>
        )}
    </>
  );
};

export default UnAuthenticated;
