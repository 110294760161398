import React from "react";
import { Modal } from "react-bootstrap";

const ReferralPolicyModal = ({ show, setShow }) => {
  return (
    <Modal centered show={show} onHide={setShow} size="md">
      <Modal.Header closeButton className="bg-white">
        <Modal.Title>Referral Policy</Modal.Title>
      </Modal.Header>

      <Modal.Body className="bg-white p-md-3 text-start">
        <h5 className="">Refer a Friend:</h5>
        <p>
          Share your unique referral link with friends who have yet to
          experience Beautowns!
        </p>
        <h5>Friend Makes a Booking:</h5>
        <p>
          When your friends register in Beautowns using your code or link, both
          of you unlock special rewards!
        </p>
        <h5>Book a Salon Appointment:</h5>
        <p>
          Once your referred friend successfully signs up, encourage them to
          book their first salon appointment through Beautowns!
        </p>
        <h5>Reward Details:</h5>
        <p>
          Upon your friend's successful booking, both you and your referred
          friend will receive a reward of Rs 100 credited to your Beautowns
          digital wallet accounts
        </p>
        <h5>Redeem Your Rewards:</h5>
        <p>
          Use your earned rewards to unlock exclusive discounts on your upcoming
          salon appointments.
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default ReferralPolicyModal;
