import React, { useState } from "react";
import { Link } from "react-router-dom";
import BookingCheckInTable from "../Table/BookingCheckInTable";

const BookingComp = ({ bookings,user,getBookings }) => {
  const [current, setCurrent] = useState("notcheckin");
  const filteredBookings =
    bookings?.filter((e) =>
      current === "notcheckin" ? (!e?.isCheckIn && !e?.isCancel) : current === "cancelled" ? e?.isCancel : e?.isCheckIn
    ) || [];

console.log(filteredBookings,"filteredBookings00000000")

  return (
    <div className="p-3">
      <div className="d-flex mb-2 flex-wrap p-2 flex-wrap align-items-center w-100 justify-content-between">
        <h3 className="m-0 my-3 fw-bold ">Bookings</h3>
        {user?.role === "store" && (
          <div className="col-lg-2 col-md-3 col-sm-4">
            <Link to={`/${user?.role}/booking/add`}>
              <button className="login-btn my-3 fw-bold">ADD</button>
            </Link>
          </div>
        )}
      </div>
      <div className="col-sm-10 col-md-8 col-lg-7 col-xl-5 d-flex justify-content-center mx-auto mb-4 checkWrapper">
        <button
          className={`${
            current === "notcheckin" ? "checkinBtn-active" : "checkinBtn-inactive"
          } `}
          onClick={() => setCurrent("notcheckin")}
        >
          Not check-in
        </button>
        <button
          className={`${
            current === "checkin" ? "checkinBtn-active" : "checkinBtn-inactive"
          } `}
          onClick={() => setCurrent("checkin")}
        >
          Check-in
        </button>
        <button
          className={`${
            current === "cancelled" ? "checkinBtn-active" : "checkinBtn-inactive"
          } `}
          onClick={() => setCurrent("cancelled")}
        >
          Cancelled
        </button>
      </div>
      <BookingCheckInTable getBookings={getBookings} bookings={filteredBookings} />
    </div>
  );
};

export default BookingComp;
