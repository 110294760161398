import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import ResetPassword from "./screens/ResetPassword";
import StoreDetails from "./screens/StoreDetails";
import Stores from "./screens/Stores";
import Login from "./screens/Login";
import ForgotPassword from "./screens/ForgotPassword";
import Otp from "./screens/Otp";
import Page404 from "./screens/Page404";
import Home from "./screens/Home";
import UploadGallery from "./screens/UploadGallery";
import Services from "./screens/Services";
import SignUp from "./screens/SignUp";
import ServiceDetail from "./screens/ServiceDetail";
import Staff from "./screens/Staff";
import StaffDetails from "./screens/StaffDetails";
import AddSalonStaff from "./screens/AddSalonStaff";
import Category from "./screens/Category";
import AddService from "./screens/AddService";
import Coupon from "./screens/Coupon";
import AddAdminCoupon from "./screens/AddAdminCoupon";
import AddCoupon from "./screens/AddCoupon";
import Bookings from "./screens/Bookings";
import StaffPayroll from "./screens/StaffPayroll";
import EditMySalon from "./screens/EditMySalon";
import MySalon from "./screens/MySalon";
import Gallery from "./screens/Gallery";
import Transactions from "./screens/Transactions";
import Analytics from "./screens/Analytics";
import AddBooking from "./screens/AddBooking";
import Chat from "./screens/Chat";
import AddPayroll from "./screens/AddPayroll";
import Referal from "./screens/Referal";
import Notifications from "./screens/Notifications";
import Wallet from "./screens/Wallet";
import Blog from "./screens/Blog";
import BlogDetails from "./screens/BlogDetails";
import AddBlog from "./screens/AddBlog";
import BookingSchedule from "./screens/BookingSchedule";
import SendNotifications from "./screens/SendNotifications";
import StaffBookings from "./screens/StaffBookings";
import CustomerChat from "./screens/CustomerChat";
import CustomerSupport from "./screens/CustomerSupport";
import StaffChat from "./screens/StaffChat";
import CreateTemplate from "./screens/CreateTemplate";
import PrivateRoute from "./PrivateRoute";
import ViewMarketingTemplate from "./screens/ViewMarketingTemplate";
import { io } from "socket.io-client";
import StoreCategory from "./screens/StoreCategory";
import { Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  logout,
  setTotalUnseenNotifications,
  setUserNotifications,
} from "./redux/slices/userSlice";
import Userprofile from "./screens/Userprofile";
import Customer from "./screens/Customer";
import AdminCoupon from "./screens/AdminCoupon";

const socket = io(process.env.REACT_APP_SERVER_URL);
function App() {
  const { user, loading } = useSelector((e) => e?.user);
  const dispatch = useDispatch();
  socket.on("sendNotificationStore", (data) => {
    if (user?.role === "store") {
      if (user?._id === data?.userId) {
        dispatch(setUserNotifications(data?.notifications));
        dispatch(setTotalUnseenNotifications(data?.unSeenNotifications));
      }
    }
  });

  socket.on("sendNotificationStaff", (data) => {
    if (user?.role === "staff") {
      if (user?._id === data?.userId) {
        dispatch(setUserNotifications(data?.notifications));
        dispatch(setTotalUnseenNotifications(data?.unSeenNotifications));
      }
    }
  });

  // socket.on("notification", (data) => {
  //   if (user) {
  //     if (user?._id === data?.userId) {
  //       dispatch(setUserNotifications(data?.notifications));
  //       dispatch(setTotalUnseenNotifications(data?.unSeenNotifications));
  //       // (async () => {
  //       //   if (user?.role === "store") {
  //       //     await dispatch(reduxApis?.getCompleteInfo(storeInfo?._id));
  //       //   } else {
  //       //     reduxApis?.getCompleteStaff(staffDetails?._id);
  //       //   }
  //       // })();
  //     }
  //   }
  // });

  // if (user && !user?.role) {
  //   dispatch(logout());
  // }

  if (
    (window.location.pathname.includes("login") ||
      window.location.pathname.includes("sign-up")) &&
    user
  ) {
    window.location.replace(`/${user?.role}/dashboard`);
  } else if (window.location.pathname === "/" && user?.isVerified) {
    window.location.replace(`/${user?.role}/dashboard`);
  }

  return (
    <div className="App">
      {loading && <div id="cover-spin"></div>}

      <Router>
        <Suspense fallback={<div id="cover-spin"></div>}>
          <Routes>
            {!user && <Route path="*" element={<Login />} />}
            {!user && <Route path="/login" element={<Login />} />}
            {!user && <Route path="/admin/login" element={<Login />} />}
            <Route path="/rest-password" element={<ResetPassword />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/otp-verification" element={<Otp />} />
            {!user && <Route path="/sign-up" element={<SignUp />} />}

            <Route path="/store/*" element={<PrivateRoute Role="store" />}>
              <Route path="" element={<Home />} />
              <Route path="dashboard" element={<Home />} />
              <Route path="notifications" element={<Notifications />} />
              <Route
                path="notifications/send"
                element={<SendNotifications />}
              />

              <Route path="chat" element={<Chat />} />
              <Route
                path="customer-chat"
                element={<CustomerChat socket={socket} />}
              />
              <Route
                path="customer-support"
                element={<StaffChat socket={socket} />}
              />
              <Route
                path="staff-chat"
                element={<StaffChat socket={socket} />}
              />
              <Route path="service" element={<Services />} />
              <Route path="service/details" element={<ServiceDetail />} />
              <Route path="service/add" element={<AddService />} />
              <Route path="service/update" element={<AddService />} />
              <Route path="staff" element={<Staff />} />
              <Route path="staff/bookings" element={<StaffBookings />} />
              <Route path="staff/add" element={<AddSalonStaff />} />
              <Route path="staff/:id" element={<StaffDetails />} />
              <Route path="staff/update" element={<AddSalonStaff />} />
              <Route path="service-category" element={<Category />} />
              <Route path="coupon" element={<Coupon />} />
              <Route path="my-salon" element={<MySalon />} />
              <Route path="my-salon/edit" element={<EditMySalon />} />
              <Route path="coupon/add" element={<AddCoupon />} />
              <Route path="booking" element={<Bookings />} />
              <Route path="profile" element={<Userprofile />} />
              <Route path="gallery" element={<Gallery />} />
              <Route path="gallery/upload" element={<UploadGallery />} />
              <Route path="booking/transactions" element={<Transactions />} />
              <Route path="analytics" element={<Analytics />} />
              <Route path="booking/add" element={<AddBooking />} />
              <Route path="payroll" element={<StaffPayroll />} />
              <Route path="payroll/:id" element={<AddPayroll />} />
              <Route path="wallet" element={<Wallet />} />
              <Route path="blog" element={<Blog />} />
              <Route path="blog/add" element={<AddBlog />} />
              <Route path="blog/update" element={<AddBlog />} />
              <Route path="blog/details" element={<BlogDetails />} />
              <Route path="referal" element={<Referal />} />
              <Route path="calander" element={<BookingSchedule />} />
              <Route path="customers" element={<Customer />} />
              <Route
                path="create-marketing-template"
                element={<CreateTemplate />}
              />
              <Route
                path="view-marketing-template"
                element={<ViewMarketingTemplate />}
              />
              {/* <Route path="*" element={<Page404 />} /> */}
            </Route>
            <Route path="/staff/*" element={<PrivateRoute Role="staff" />}>
              <Route index path="" element={<Home />} />
              <Route path="*" element={<Page404 />} />
              <Route index path="dashboard" element={<Home />} />
              <Route index path="booking" element={<Bookings />} />
              <Route path="calander" element={<BookingSchedule />} />
              <Route path="notifications" element={<Notifications />} />
              <Route path="chat" element={<StaffChat socket={socket} />} />
              <Route path="profile" element={<Userprofile />} />
              <Route path="wallet" element={<Wallet />} />
              <Route path="referal" element={<Referal />} />
            </Route>
            <Route path="/admin/*" element={<PrivateRoute Role="admin" />}>
              <Route index path="" element={<Home />} />
              <Route path="*" element={<Page404 />} />
              <Route index path="dashboard" element={<Home />} />
              <Route index path="store-category" element={<StoreCategory />} />
              <Route index path="customers" element={<Customer />} />
              <Route index path="stores" element={<Stores />} />
              <Route
                index
                path="stores/details/:slug"
                element={<StoreDetails />}
              />
              <Route index path="booking" element={<Bookings />} />
              <Route path="calander" element={<BookingSchedule />} />
              <Route path="notifications" element={<Notifications />} />
              <Route path="wallet" element={<Wallet />} />
              <Route path="referal" element={<Referal />} />
              <Route path="service" element={<Services />} />
              <Route path="service/details" element={<ServiceDetail />} />
              <Route path="service/update" element={<AddService />} />
              <Route path="service/add" element={<AddService />} />
              <Route path="blog" element={<Blog />} />
              <Route path="blog/add" element={<AddBlog />} />
              <Route path="blog/update" element={<AddBlog />} />
              <Route path="blog/details" element={<BlogDetails />} />
              <Route path="staff" element={<Staff />} />
              <Route path="staff/bookings" element={<StaffBookings />} />
              <Route path="staff/add" element={<AddSalonStaff />} />
              <Route path="staff/:id" element={<StaffDetails />} />
              <Route path="staff/update" element={<AddSalonStaff />} />
              <Route path="service-category" element={<Category />} />
              <Route path="profile" element={<Userprofile />} />
              <Route path="admin-coupon" element={<AdminCoupon />} />
              <Route path="admin-coupon/add" element={<AddAdminCoupon />} />
              <Route path=":slug/edit" element={<EditMySalon />} />
              <Route path="customers" element={<Customer />} />
            </Route>
          </Routes>
        </Suspense>

        <ScrollToTop />
      </Router>
    </div>
  );
}

export default App;
