import React from "react";
import WalletComp from "../components/wallet/WalletComp";
import Layout from "../components/layout/Layout";
const Wallet = () => {
  return (
    <Layout>
      <WalletComp />
    </Layout>
  );
};

export default Wallet;
