import { useMutation } from "@tanstack/react-query";
import OtpInput from "react-otp-input";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setUser, setStaffDetails } from "../../redux/slices/userSlice";
import apis from "../../services";
import toast from "react-hot-toast";
import { Spinner } from "react-bootstrap";
import reduxApis from "../../redux/api";

const OtpForm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [seconds, setSeconds] = useState(30);
  let timer;

  const startTimer = () => {
    timer = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 1) {
          clearInterval(timer);
          return 0;
        } else {
          return prevSeconds - 1;
        }
      });
    }, 1000);
  };

  // console.log(state, "state");

  const { mutate, isLoading } = useMutation(apis.authVerify, {
    onError: function ({ message }) {
      toast.error(message?.message, { id: 1 });
    },
    onSuccess: ({ data: user, status }) => {
      if (status === 200) {
        if (state?.type === "rest-password") {
          navigate("/rest-password", { state: { data: state } });
        } else {
          toast.success(user?.message, { id: 1 });
          navigate(`/${user?.user?.role}/dashboard`);
          dispatch(setUser(user?.user));
          if (user?.user?.role === "staff") {
            console.log(user?.staffDetail, "staffDetail");
            dispatch(setStaffDetails(user?.staffDetail));
            toast.success(user?.message, { id: 1 });
          }
        }
      }
    },
  });

  const resetTimer = () => {
    clearInterval(timer);
    setSeconds(30);
    startTimer();
  };

  const { mutate: resend_OTP, isLoading: resend } = useMutation(
    apis.authForgot,
    {
      onError: function ({ message }) {
        toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data: user, status }) => {
        if (status === 200) {
          resetTimer();
          toast.success(user?.message, { id: 1 });
        }
      },
    }
  );

  const sendAgain = () => {
    resend_OTP({ email: state?.email, role: state?.role });
  };

  const [otp, setOtp] = useState();

  useEffect(() => {
    if (otp?.length === 4) {
      mutate({ email: state?.email, otp: otp });
    }
    // eslint-disable-next-line
  }, [otp]);

  useEffect(() => {
    if (!state) {
      navigate(-1);
    }
    startTimer();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Clean up the timer when the component is unmounted
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="Login-card">
      <h1 className="text-center">Verify OTP</h1>
      <p className="text-center m-0 mb-2">
        We have send you an OTP via Email.
        <br /> {state?.email} <br />
        Check Your Inbox{" "}
      </p>
      <div className="w-100 d-flex justify-content-center my-4">
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={4}
          inputStyle={"OPT_INPUT"}
          inputType={"number"}
          renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
        />
      </div>
      <div className="w-100 d-flex justify-content-center">
        {(isLoading || resend) && <Spinner animation="border" />}
      </div>

      <p className="text-center m-0 my-2 h6">
        Didn't receive the OTP?
        {seconds > 0 ? (
          <span className="text-pink fw-bold ms-2">{seconds}s</span>
        ) : (
          <span className="h_u text-pink fw-bold ms-2" onClick={sendAgain}>
            {"Resend OTP."}
          </span>
        )}
      </p>
    </div>
  );
};

export default OtpForm;
