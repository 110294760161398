import React from "react";
import Chart from "react-apexcharts";

const MonthlyChart = ({ data }) => {
  const chartData = {
    options: {
      chart: {
        id: "basic-line-chart",
        toolbar: {
          show: false, // Hide the toolbar
        },
        zoom: {
          enabled: false,
        },
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      colors: ["#FF69B4"],
    },
    series: [
      {
        name: "Sales",
        data: data || [],
      },
    ],
  };

  return (
    <div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="line"
        width="100%"
        height="300px"
      />
    </div>
  );
};

export default MonthlyChart;
