import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteModal from "../Modal/DeleteModal";
import toast from "react-hot-toast";
import apis from "../../services";
import { MdDeleteForever } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { IoMdEye } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { setNoOfStaff, setStaffs } from "../../redux/slices/userSlice";
import { useMutation } from "@tanstack/react-query";

const StaffTable = ({ staffs }) => {
  const { user } = useSelector((e) => e?.user);
  let role = user?.role;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const itemsPerPage = 10; // Define number of items per page
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = staffs?.slice(indexOfFirstItem, indexOfLastItem);
  const [id, setId] = useState();
  const [show, setShow] = useState(false);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const showModal = (id) => {
    setId(id);
    setShow(true);
  };
  const totalPages = Math.ceil(staffs?.length / itemsPerPage);

  const { mutate: delete_staff, isLoading: loading } = useMutation(
    () => apis.delete_staff({ id: id, role: role }),
    {
      onError: function ({ message }) {
        toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          toast.success(data?.message);
          setShow(false);
          dispatch(setStaffs(data?.staffs));
          dispatch(setNoOfStaff(data?.staffs.length || 0));
        }
      },
    }
  );

  const { mutate: changeStaffStatus, isLoading } = useMutation(
    apis.changeStaffStatus,
    {
      onError: function ({ message }) {
        toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          toast.success(data?.message);
          setShow(false);
          dispatch(setStaffs(data?.staffs));
          dispatch(setNoOfStaff(data?.staffs.length || 0));
        }
      },
    }
  );

  return (
    <div class="">
      {isLoading && <div id="cover-spin"></div>}
      <DeleteModal
        deleteFunction={delete_staff}
        show={show}
        setShow={setShow}
        loading={loading}
      />
      {/* <div className="col-md-3 mb-3">
        <label htmlFor="search-staff" className="form-label">
          Search:
        </label>
        <input
          type="text"
          className="form-control bg_white"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div> */}

      <div class="table-responsive bg-white mt-2 rounded-3">
        <table class="table table-hover ">
          <thead className="table-secondary">
            <tr>
              <th>Staff Name</th>
              <th scope="col">Title</th>
              <th scope="col">Gender</th>
              {role === "store" && <th scope="col">Description</th>}

              {role === "admin" && !pathname.includes("stores") && (
                <th scope="col">Salon Name</th>
              )}

              <th scope="col">Status</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentItems?.length > 0 ? (
              currentItems?.map((staff, i) => {
                return (
                  <tr key={i}>
                    <td>{staff?.name}</td>
                    <td>{staff?.title}</td>
                    <td>{staff?.gender}</td>
                    {role === "store" && (
                      <td className="max-30-chars">{staff?.description}</td>
                    )}

                    {role === "admin" && !pathname.includes("stores") && (
                      <td className="max-30-chars">
                        {staff?.salon_staff_Id?.name}
                      </td>
                    )}

                    <td>
                      <label
                        className={`staff_switch ${
                          !staff?.salon_staff_Id?.isDeleted ? "checked" : ""
                        }`}
                      >
                        <input
                          type="checkbox"
                          checked={!staff?.salon_staff_Id?.isDeleted}
                          onChange={() =>
                            changeStaffStatus({
                              id: staff?._id,
                              role: role,
                            })
                          }
                        />
                        <div className="slider mt-0 "></div>
                      </label>
                    </td>
                    <td className="d-flex">
                      <IoMdEye
                        className="fs-3 text-secondary me-2 hc"
                        onClick={() =>
                          navigate(`/${role}/staff/details`, {
                            state: { id: staff?._id },
                          })
                        }
                      />

                      <FaEdit
                        className="fs-3 text-secondary mx-2 hc"
                        onClick={() =>
                          navigate(`/${role}/staff/update`, {
                            state: { id: staff?._id },
                          })
                        }
                      />

                      <MdDeleteForever
                        className="fs-3 text-danger hc"
                        onClick={() => showModal(staff?._id)}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <td colSpan="6" className="text-center py-5">
                No staff found
              </td>
            )}
          </tbody>
        </table>
        {staffs?.length > 0 && (
          <div className="w-90 position-absolute bottom-0 start-50 translate-middle-x mb-3">
            <div className="row align-items-center text-center text-md-start">
              <div className="col-sm-6 ms-auto col-xs-6">
                <div className="d-flex align-items-center justify-content-center">
                  <button
                    className="pagination_btn"
                    onClick={() =>
                      setCurrentPage(
                        currentPage > 1 ? currentPage - 1 : currentPage
                      )
                    }
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  {[...Array(totalPages)].map((_, i) => (
                    <div
                      key={i}
                      className={`pageNo ${
                        i + 1 === currentPage ? "pageNo_active" : ""
                      }`}
                      onClick={() => paginate(i + 1)}
                    >
                      {i + 1}
                    </div>
                  ))}
                  <button
                    className="pagination_btn"
                    onClick={() =>
                      setCurrentPage(
                        currentPage < totalPages ? currentPage + 1 : currentPage
                      )
                    }
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StaffTable;
