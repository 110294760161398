import React from "react";
import { Modal } from "react-bootstrap";
import { PiUsersThree } from "react-icons/pi";

const MajorStaffSelection = ({
  selectedStaffs,
  serviceName,
  showMajor,
  setShowMajor,
  storeStaff,
  setStaffAtIndex,
  index,
  setShow,
}) => {
  return (
    <Modal
      size="lg"
      centered
      show={showMajor}
      onHide={setShowMajor}
      className=""
    >
      <Modal.Header closeButton className="bg-white">
        <Modal.Title>Professional for {serviceName ? serviceName : ""}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-white majorStaffWrapper bottomBorderRadius">
        <div className="d-flex flex-wrap">
          <div
            className={`staffWrapper hc m-2 pb-4 ${
              (selectedStaffs?.[index] === undefined || "") &&
              "staffWrapper-active"
            }`}
            onClick={() => {
              setStaffAtIndex(index,null);
              setShowMajor(false);
              setShow(true);
            }}
          >
            <PiUsersThree className="fs-1 text-center userLogo d-block my-2 mx-auto" />
            <p className="text-center m-0 fw-bold ">Any professional</p>
            <p className="text-center m-0 text-secondary">
              Maxiumum Availability
            </p>
          </div>
          {storeStaff?.length > 0 &&
            storeStaff?.map((staff, i) => (
              <>
                {staff?.salon_staff_Id?.isDeleted ? (
                  ""
                ) : (
                  <div
                    className={`staffWrapper hc m-2 pb-4 ${
                      selectedStaffs?.[index] === staff?._id &&
                      "staffWrapper-active"
                    }`}
                    key={i}
                    onClick={() => {
                      setStaffAtIndex(index, staff?._id);
                      setShowMajor(false);
                      setShow(true);
                    }}
                  >
                    <img src={staff?.image} className="img-fluid " alt="" />
                    <p className="text-center m-0 fw-bold ">{staff?.name}</p>
                    <p className="text-center m-0 text-secondary ">
                      {staff?.title}
                    </p>
                  </div>
                )}
              </>
            ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MajorStaffSelection;
