import React from "react";
import OverAllChart from "../analytics/OverAllChart";
import ColumnChart from "../analytics/ColumnChart";
import BestSelling from "../analytics/BestSelling";
import StaffBookings from "../analytics/StaffBookings";
import { useSelector } from "react-redux";
import Accordion from "react-bootstrap/Accordion";

const Dashboard = ({ info, storeGraphs }) => {
  const { user } = useSelector((e) => e?.user);
  return (
    <div className="MainDash p-3">
      <div className="row justify-content-center pb-3">
        <div className="col-lg-4 col-md-6 mb-2">
          <div className="card dashboard_card">
            <div className="circle"></div>
            <div className="card-body">
              <div className="d-flex align-items-end">
                <div>
                  <div className="title">
                    <img src="./assets/appointment.png" alt="" />
                    <h3 className=" text-black">Total Appointments</h3>
                  </div>
                  <span className=" text-black font-w600">
                    {info?.totalAppointments || 0}
                    {/* <svg
                      className="ms-1"
                      width={19}
                      height={12}
                      viewBox="0 0 19 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.00401 11.1924C0.222201 11.1924 -0.670134 9.0381 0.589795 7.77817L7.78218 0.585786C8.56323 -0.195262 9.82956 -0.195262 10.6106 0.585786L17.803 7.77817C19.0629 9.0381 18.1706 11.1924 16.3888 11.1924H2.00401Z"
                        fill="#33C25B"
                      />
                    </svg> */}
                  </span>
                  {/* <h5 className="text-secondary">
                    {" "}
                    <span className="text-black fw-bold">1.7%</span> vs last
                    month
                  </h5> */}
                  {/* <span className="text-black fw-bold">0%</span> previous day */}
                </div>
              </div>
              <Accordion className="w-100 border-0 p-0 mt-3">
                <Accordion.Item eventKey="1" className="w-100 border-0 p-0 ">
                  <Accordion.Header className="w-100 border-0 p-0">
                    More
                  </Accordion.Header>
                  <Accordion.Body className="p-0">
                    <h5 className="text-secondary">
                      Completed{" "}
                      <span className="text-black fw-bold">
                        {(info?.percentageCompletedAppointments || 0).toFixed(
                          2
                        )}{" "}
                        %
                      </span>
                    </h5>
                    <h5 className="text-secondary">
                      Not Completed{" "}
                      <span className="text-black fw-bold">
                        {(
                          info?.percentageNotCompletedAppointments || 0
                        ).toFixed(2)}{" "}
                        %
                      </span>
                    </h5>
                    <h5 className="text-secondary">
                      Cancelled{" "}
                      <span className="text-black fw-bold">
                        {(info?.percentageCancelledAppointments || 0).toFixed(
                          2
                        )}{" "}
                        %
                      </span>
                    </h5>
                    <h5 className="text-secondary">
                      No-show <span className="text-black fw-bold">0%</span>
                    </h5>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
        {/* <div className="col-lg-4 col-md-6 mt-3">
          <div className="card dashboard_card">
            <div className="card-body">
              <div className="d-flex align-items-end">
                <div>
                  <h3 className=" text-black">Online Appointments</h3>
                  <span className=" text-black font-w600">
                    {info?.onlineAppointments}
                    <svg
                      className="ms-1"
                      width={19}
                      height={12}
                      viewBox="0 0 19 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.00401 11.1924C0.222201 11.1924 -0.670134 9.0381 0.589795 7.77817L7.78218 0.585786C8.56323 -0.195262 9.82956 -0.195262 10.6106 0.585786L17.803 7.77817C19.0629 9.0381 18.1706 11.1924 16.3888 11.1924H2.00401Z"
                        fill="#33C25B"
                      />
                    </svg>
                  </span>
                  <h5 className="text-secondary">
                    {" "}
                    <span className="text-black fw-bold">
                      {(info?.percentageOnlineAppointments || 0).toFixed(2)} %
                    </span>{" "}
                    previous day
                  </h5>
                  <h5 className="text-secondary">
                    Completed <span className="text-black fw-bold">0%</span>
                  </h5>
                  <h5 className="text-secondary">
                    Not Completed <span className="text-black fw-bold">0%</span>
                  </h5>
                  <h5 className="text-secondary">
                    Cancelled <span className="text-black fw-bold">0%</span>
                  </h5>
                  <h5 className="text-secondary">
                    No-show <span className="text-black fw-bold">0%</span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="col-lg-4 col-md-6 mt-3">
          <div className="card dashboard_card">
            <div className="card-body">
              <div className="d-flex align-items-end">
                <div>
                  <h3 className=" text-black">OnSite Appointments</h3>
                  <span className=" text-black font-w600">
                    {info?.onSiteAppointments}
                    <svg
                      className="ms-1"
                      width={19}
                      height={12}
                      viewBox="0 0 19 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.00401 11.1924C0.222201 11.1924 -0.670134 9.0381 0.589795 7.77817L7.78218 0.585786C8.56323 -0.195262 9.82956 -0.195262 10.6106 0.585786L17.803 7.77817C19.0629 9.0381 18.1706 11.1924 16.3888 11.1924H2.00401Z"
                        fill="#33C25B"
                      />
                    </svg>
                  </span>
                  <h5 className="text-secondary">
                    {" "}
                    <span className="text-black fw-bold">
                      {(info?.percentageOnSiteAppointments || 0).toFixed(2)}%
                    </span>{" "}
                    previous day
                  </h5>
                  <h5 className="text-secondary">
                    Working Hours
                    <span className="text-black fw-bold">0%</span>
                  </h5>
                  <h5 className="text-secondary">
                    Booked Hours <span className="text-black fw-bold">0%</span>
                  </h5>
                  <h5 className="text-secondary">
                    Unbooked Hours{" "}
                    <span className="text-black fw-bold">0%</span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="col-lg-4 col-md-6 mb-2">
          <div className="card dashboard_card">
            <div className="circle"></div>

            <div className="card-body">
              <div className="d-flex align-items-end">
                <div>
                  <div className="title">
                    <img src="./assets/sales.png" alt="" />
                    <h3 className=" text-black">Total Sales</h3>
                  </div>
                  <span className=" text-black font-w600">
                    PKR {info?.totalSales}
                    {/* <svg
                      className="ms-1"
                      width={19}
                      height={12}
                      viewBox="0 0 19 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.00401 11.1924C0.222201 11.1924 -0.670134 9.0381 0.589795 7.77817L7.78218 0.585786C8.56323 -0.195262 9.82956 -0.195262 10.6106 0.585786L17.803 7.77817C19.0629 9.0381 18.1706 11.1924 16.3888 11.1924H2.00401Z"
                        fill="#33C25B"
                      />
                    </svg> */}
                  </span>
                  {/* <h5 className="text-secondary">
                    {" "}
                    <span className="text-black fw-bold">0.5%</span> vs last
                    month
                  </h5> */}
                </div>
              </div>
              <Accordion className="w-100 border-0 p-0 mt-3">
                <Accordion.Item eventKey="1" className="w-100 border-0 p-0 ">
                  <Accordion.Header className="w-100 border-0 p-0">
                    More
                  </Accordion.Header>
                  <Accordion.Body className="p-0">
                    <h5 className="text-secondary">
                      Total Discount
                      <span className="text-black fw-bold">
                        {info?.totalDiscount || 0} PKR
                      </span>
                    </h5>
                    <h5 className="text-secondary">
                      Average Sale{" "}
                      <span className="text-black fw-bold">
                        {(info?.averageSale || 0).toFixed(2)} PKR
                      </span>
                    </h5>
                    <h5 className="text-secondary">
                      Pending Sales{" "}
                      <span className="text-black fw-bold">
                        {info?.totalPendingSales} PKR
                      </span>
                    </h5>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
        {/* <div className="col-lg-4 col-md-6 mt-3">
          <div className="card dashboard_card">
            <div className="card-body">
              <div className="d-flex align-items-end">
                <div>
                  <h3 className=" text-black">Average Sale</h3>
                  <span className=" text-black font-w600">
                    PKR 0
                    <svg
                      className="ms-1"
                      width={19}
                      height={12}
                      viewBox="0 0 19 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.00401 11.1924C0.222201 11.1924 -0.670134 9.0381 0.589795 7.77817L7.78218 0.585786C8.56323 -0.195262 9.82956 -0.195262 10.6106 0.585786L17.803 7.77817C19.0629 9.0381 18.1706 11.1924 16.3888 11.1924H2.00401Z"
                        fill="#33C25B"
                      />
                    </svg>
                  </span>
                  <h5 className="text-secondary">
                    {" "}
                    <span className="text-black fw-bold">0%</span> previous day
                  </h5>
                  <h5 className="text-secondary">
                    Sales Count <span className="text-black fw-bold">0%</span>
                  </h5>
                  <h5 className="text-secondary">
                    Av. Service Sale{" "}
                    <span className="text-black fw-bold">0%</span>
                  </h5>
                  <h5 className="text-secondary">
                    Av. Product Sale{" "}
                    <span className="text-black fw-bold">0%</span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="col-lg-4 col-md-6 mb-2">
          <div className="card dashboard_card">
            <div className="circle"></div>
            <div className="card-body">
              <div className="d-flex align-items-end">
                <div>
                  <div className="title">
                    <img src="./assets/client.png" alt="" />
                    <h3 className=" text-black">Client Retention</h3>
                  </div>
                  <span className=" text-black font-w600">
                    {(info?.clientRetention || 0).toFixed(0)}%
                    {/* <svg
                      className="ms-1"
                      width={19}
                      height={12}
                      viewBox="0 0 19 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.00401 11.1924C0.222201 11.1924 -0.670134 9.0381 0.589795 7.77817L7.78218 0.585786C8.56323 -0.195262 9.82956 -0.195262 10.6106 0.585786L17.803 7.77817C19.0629 9.0381 18.1706 11.1924 16.3888 11.1924H2.00401Z"
                        fill="#33C25B"
                      />
                    </svg> */}
                  </span>
                  {/* <h5 className="text-secondary">
                    {" "}
                    <span className="text-black fw-bold">2.5%</span> vs last
                    month
                  </h5> */}
                </div>
              </div>
              <Accordion className="w-100 border-0 p-0 mt-3">
                <Accordion.Item eventKey="1" className="w-100 border-0 p-0 ">
                  <Accordion.Header className="w-100 border-0 p-0">
                    More
                  </Accordion.Header>
                  <Accordion.Body className="p-0">
                    <h5 className="text-secondary">
                      Returning Clients
                      <span className="text-black fw-bold">
                        {info?.returningClients}
                      </span>
                    </h5>
                    <h5 className="text-secondary">
                      New Clients{" "}
                      <span className="text-black fw-bold">
                        {info?.newClients}
                      </span>
                    </h5>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
        <div
          className={`mt-3 ${
            user?.role === "store" || user?.role === "admin"
              ? "col-md-12 col-lg-7 col-xl-8 "
              : "col-12"
          }`}
        >
          <div className="dashboard_card rounded-3 pt-3">
            <div className="d-flex justify-content-between px-1 px-md-4">
              <h5 className="mt-2 text-center">
                {user?.role === "store"
                  ? "Salon "
                  : user?.role === "admin"
                  ? "All "
                  : "Your "}
                Bookings
              </h5>
              <div className="d-flex">
                <button
                  className={`px-2 mx-1 
                   login-btn-inactive`}
                >
                  Monthly
                </button>
              </div>
            </div>
            <OverAllChart data={storeGraphs?.monthlyBookingCounts} />
          </div>
        </div>
        {(user?.role === "store" || user?.role === "admin") && (
          <>
            <div className="col-md-6 col-lg-5 col-xl-4 mt-3">
              <div className="bg-white rounded-3 h-100 overflow-x-hidden pb-2  pt-3">
                <h5 className="mt-2 text-center">Best Category Selling</h5>
                <BestSelling data={storeGraphs?.categoryCountsPercentage} />
              </div>
            </div>
            {user?.role === "store" && (
              <>
                <div className="col-md-6 col-lg-5 col-xl-4 mt-3">
                  <div className="bg-white rounded-3 overflow-x-hidden pb-2 h-100 pt-3">
                    <h5 className="mt-2 text-center">Staff Bookings</h5>
                    <StaffBookings data={storeGraphs?.staffBookingPercentage} />
                  </div>
                </div>
                <div className="col-lg-7 col-xl-8 mt-3">
                  <div className="d-flex flex-column">
                    <div className="bg-white rounded-3 pt-3">
                      <h5 className="mt-2 text-center">New Sales</h5>
                      <ColumnChart data={storeGraphs?.monthlyBookingCounts} />
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
