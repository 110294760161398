import React from "react";

const ProgressBar = ({ activeStep }) => {
  return (
    <div className="stepsBar">
    <div className="position-relative m-4 mb-5">
      <div className="progress" style={{ height: "3px" }}>
        <div
          className="progress-bar bg__pink"
          role="progressbar"
          style={{ width: `${(activeStep - 1) * 100}%` }}
          aria-valuenow={activeStep * 100}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <button
        type="button"
        className={`position-absolute top-0 start-0 translate-middle btn btn-sm ${
          activeStep >= 1 ? "btn-dark-pink" : "btn-secondary"
        } rounded-pill`}
        style={{ width: "2rem", height: "2rem" }}
      >
        1
      </button>
      <p className="position-absolute top-0 start-0 translate-middle mt-4 pt-2">
        Register
      </p>
      <button
        type="button"
        className={`position-absolute top-0 start-100 translate-middle btn btn-sm ${
          activeStep >= 2 ? "btn-dark-pink" : "btn-secondary"
        } rounded-pill`}
        style={{ width: "2rem", height: "2rem" }}
      >
        2
      </button>
      <p className="position-absolute top-0 start-100 translate-middle mt-4 pt-2">
        Time
      </p>
    </div>
    </div>
  );
};

export default ProgressBar;
