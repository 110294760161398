import React, { useEffect } from "react";
import Layout from "../components/layout/Layout";
import BookingComp from "../components/bookings/BookingComp";
import { useDispatch, useSelector } from "react-redux";
import { setBookings, setStaffs } from "../redux/slices/userSlice";
import apis from "../services";
import { useMutation } from "@tanstack/react-query";

const Bookings = () => {
  const dispatch = useDispatch();
  const { bookings, storeInfo, staffDetails, user } = useSelector(
    (e) => e.user
  );

  const { mutate: getBookings, isLoading } = useMutation(
    () =>
      user?.role === "store"
        ? apis.getStoreBookings(storeInfo?._id)
        : user?.role === "staff"
        ? apis.getStaffBookings(staffDetails?._id)
        : apis.getAllBookings(),
    {
      onError: function ({ message }) {
        console.log(message, "message");
        // toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          dispatch(setBookings(data?.booking));
        }
      },
    }
  );

 

  const { mutate: getStaff } = useMutation(
    () =>
      apis.getStoreStaff({
        id: user?.role === "store" ? storeInfo?._id : user?._id,
        role: user?.role,
      }),
    {
      onError: function ({ message }) {
        // toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          dispatch(setStaffs(data?.staff));
         
        }
      },
    }
  );

  useEffect(() => {
    getBookings();
    getStaff();
  }, []);

  return (
    <Layout>
      {isLoading && <div id="cover-spin"></div>}
      <BookingComp getBookings={getBookings} user={user} bookings={bookings} />
    </Layout>
  );
};

export default Bookings;
