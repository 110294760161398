import React from "react";
import couponBg from "../assets/couponsBg.jpg";
import offerBg from "../assets/offersbg.jpg";
import offersbg1 from "../assets/offersbg1.jpg";
import dealbg from "../assets/salonDeal.jpg";
import offerImage from "../assets/offerpage.png"
import discountPage from "../assets/discountpage.png"
const Cards = ({ setMainData }) => {
  return (
    <div>
      <div className="container">
        <div
          id="main_container"
          class="pageMain_container no-background wrapper page__loading"
        >
          <div className="wrapper">
            <div className="dbContent">
              <div className="cardType">
                <h3 className=" text-center my-3 fw-bold">
                  Choose Your Marketing Template here
                </h3>
                <div
                  id="pass2utemplate"
                  className="d-flex justify-content-center  flex-gap flex-wrap "
                >
                  <div class="card_wrapper my-3">
                    <div className="cardPic shadow-sm position-relative">
                      <img
                        src={offerImage}
                        alt=""
                        onClick={() =>
                          setMainData((prev) => ({
                            ...prev,
                            step: 2,
                            currentActive: "offer",
                          }))
                        }
                        className="card-image img-fluid "
                      />
                      <div className="position-absolute top-50 start-50 translate-middle">
                        <button
                          onClick={() =>
                            setMainData((prev) => ({
                              ...prev,
                              step: 2,
                              currentActive: "offer",
                            }))
                          }
                          className="useTemplateBtn"
                        >
                          Use template
                        </button>
                      </div>
                    </div>
                    <div className="name">Offer</div>
                  </div>

                  <div class="card_wrapper my-3">
                    <div className="cardPic shadow-sm position-relative">
                      <img
                        src={offerBg}
                        alt=""
                        onClick={() =>
                          setMainData((prev) => ({
                            ...prev,
                            step: 2,
                            currentActive: "offer",
                          }))
                        }
                        className="card-image img-fluid "
                      />
                      <div className="position-absolute top-50 start-50 translate-middle">
                        <button
                          onClick={() =>
                            setMainData((prev) => ({
                              ...prev,
                              step: 2,
                              currentActive: "offer",
                            }))
                          }
                          className="useTemplateBtn"
                        >
                          Use template
                        </button>
                      </div>
                    </div>
                    <div className="name">Sale</div>
                  </div>
                  <div class="card_wrapper my-3">
                    <div className="cardPic shadow-sm position-relative">
                      <img
                        src={discountPage}
                        alt=""
                        onClick={() =>
                          setMainData((prev) => ({
                            ...prev,
                            step: 2,
                            currentActive: "Discount",
                          }))
                        }
                        className="card-image img-fluid "
                      />
                      <div className="position-absolute top-50 start-50 translate-middle">
                        <button
                          onClick={() =>
                            setMainData((prev) => ({
                              ...prev,
                              step: 2,
                              currentActive: "Discount",
                            }))
                          }
                          className="useTemplateBtn"
                        >
                          Use template
                        </button>
                      </div>
                    </div>
                    <div className="name">Discount</div>
                  </div>

                  <div class="card_wrapper my-3">
                    <div className="cardPic shadow-sm position-relative">
                      <img
                        src={dealbg}
                        alt=""
                        onClick={() =>
                          setMainData((prev) => ({
                            ...prev,
                            step: 2,
                            currentActive: "Deal",
                          }))
                        }
                        className="card-image img-fluid "
                      />
                      <div className="position-absolute top-50 start-50 translate-middle">
                        <button
                          onClick={() =>
                            setMainData((prev) => ({
                              ...prev,
                              step: 2,
                              currentActive: "Deal",
                            }))
                          }
                          className="useTemplateBtn"
                        >
                          Use template
                        </button>
                      </div>
                    </div>
                    <div className="name">Deals</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
