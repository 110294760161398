import React from "react";
import Layout from "../components/layout/Layout";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Page404 = () => {
  const { user } = useSelector((e) => e.user);
  return (
    <Layout>
      <div className="container text-center mt-5 ">
        <h1 className="oops">Oops</h1>
        <h1 className="fw-light text-uppercase my-3">Page not found</h1>
        <div className="col-11 col-md-4 col-lg-3 mx-auto ">
          <Link
            to={`/${user?.role}/dashboard`}>
            <button className="login-btn mt-5">GO TO HOME</button>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default Page404;
