import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import ChatApp from "../components/chat/ChatApp";
import {
  setBookings,
  setNoOfStaff,
  setStaffs,
} from "../redux/slices/userSlice";
import apis from "../services";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import ChatStaff from "../components/chat/ChatStaff";
import { useLocation } from "react-router-dom";
import StoreCustomerChat from "../components/chat/StoreCustomerChat";

const StaffChat = ({ socket }) => {
  const { pathname } = useLocation();
  const { staffs, user, storeInfo, staffDetails, bookings, users } =
    useSelector((e) => e?.user);

  const dispatch = useDispatch();
  const { mutate: getStaff, isLoading } = useMutation(
    () =>
      apis.getStoreStaff({
        id: user?.role === "store" ? storeInfo?._id : user?._id,
        role: user?.role,
      }),
    {
      onError: function ({ message }) {
        // toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          dispatch(setStaffs(data?.staff));
          dispatch(setNoOfStaff(data?.staff.length || 0));
        }
      },
    }
  );

  const [currect, setCurrent] = useState("customers");

  let list =
    bookings?.length > 0 &&
    Array.from(
      new Map(
        bookings.map((user) => [user?.user_Id?._id, user?.user_Id])
      ).values()
    );

  const { mutate: getBookings, isLoading: gettingBookings } = useMutation(
    () =>
      user?.role === "store"
        ? apis.getStoreBookings(storeInfo?._id)
        : user?.role === "staff"
        ? apis.getStaffBookings(staffDetails?._id)
        : null,
    {
      onError: function ({ message }) {
        console.log(message, "message");
        // toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          dispatch(setBookings(data?.booking));
        }
      },
    }
  );

  useEffect(() => {
    if (pathname === "/store/staff-chat") {
      getStaff();
    } else if (pathname === "/staff/chat") {
      getBookings();
    }
     else if(pathname === "/stor/chat") {
      getBookings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      {(isLoading || gettingBookings) && <div id="cover-spin"></div>}
      {pathname === "/store/staff-chat" && (
        <ChatApp user={user} staffs={staffs} socket={socket} />
      )}
      {pathname === "/staff/chat" && (
        <ChatStaff
          staffDetails={staffDetails}
          customerList={list}
          user={user}
          socket={socket}
        />
      )}
      {pathname === "/store/customer-chat" && (
        <StoreCustomerChat
          customers={list}
          user={user}
          socket={socket}
        />
      )}
    </Layout>
  );
};

export default StaffChat;
