import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import AddSalon from "../register-salon/AddSalon";
import SalonTime from "../register-salon/SalonTime";
// import SalonDocuments from "../register-salon/SalonDocuments";
import ProgressBar from "../register-salon/ProgressBar";

const StoreRegisterModal = ({ showModal, setShowModal }) => {
  const { storeInfo } = useSelector((e) => e.user);
  const [activeStep, setActiveStep] = useState(1);
  useEffect(() => {
    if (storeInfo?.completeProgess === 1) {
      setActiveStep(2);
    }
  }, [storeInfo]);

  return (
    <div>
      <Modal
        centered
        size={storeInfo === null ? "xl" : "lg"}
        show={showModal}
        onHide={setShowModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="bg-white rounded-3">
          <div className="container">
            <ProgressBar activeStep={activeStep} />
          </div>
          {storeInfo === null && <AddSalon />}
          {storeInfo?.completeProgess === 1 && (
            <SalonTime setShowModal={setShowModal} />
          )}
          {/* {storeInfo?.completeProgess === 2 && (
            <SalonDocuments setShowModal={setShowModal} />
          )} */}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default StoreRegisterModal;
