import React from "react";
import { useState } from "react";
import BookingDetailsModal from "../Modal/BookingDetailsModal";
import { IoMdEye } from "react-icons/io";
import CheckInModal from "../Modal/CheckInModal";
import { useMutation } from "@tanstack/react-query";
import apis from "../../services";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import reduxApis from "../../redux/api";
import { MdOutlineCancel } from "react-icons/md";
import CancelBookingModal from "../Modal/CancelBookingModal";

const BookingCheckInTable = ({ bookings, isStaffBooking, getBookings }) => {
  const { storeInfo, user, staffDetails } = useSelector((e) => e?.user);
  const dispatch = useDispatch();
  const [staff_Id, setStaff_Id] = useState("");
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [collectedAmount, setCollectedAmount] = useState(0);
  const [booking, setBooking] = useState();
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  let reverseBookings = bookings ? [...bookings].reverse() : [];
  const currentItems = reverseBookings?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const [currentBooking, setCurrentBooking] = useState("");
  const [show, setShow] = useState(false);
  const [showCheckIn, setShowCheckIn] = useState(false);
  const [showCancel, setShowCancel] = useState(false);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const showModal = (data) => {
    setCurrentBooking(data);
    setShow(true);
  };
  const totalPages = Math.ceil(bookings?.length / itemsPerPage);
  let userRole = user?.role;
  const { mutate, isLoading } = useMutation(
    () =>
      apis.checkInBooking({
        booking_Id: booking?._id,
        collected_Amount: collectedAmount,
        ...(userRole === "store" && { salon_owner_Id: user?._id }),
        ...(userRole === "store" && staff_Id && { salon_staff_Id: staff_Id }),
        ...(userRole === "store" && { store_Id: storeInfo?._id }),
        ...(userRole === "staff" && { store_Id: staffDetails?.store_Id }),
        ...(userRole === "staff" && { staff_Id: staffDetails?._id }),
      }),
    {
      onError: function ({ message }) {
        console.log(message, "message");
        toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data: user, status }) => {
        if (status === 200) {
          setShowCheckIn(false);
          toast.success(user?.message, { id: 1 });
          getBookings();
        }
      },
    }
  );

  const { mutate: bookingCancel, isLoading: isCancelling } = useMutation(
    () =>
      apis.cancelBooking({
        booking_Id: booking?._id,
        user_Id: user?._id,
      }),
    {
      onError: function ({ message }) {
        console.log(message, "message");
        toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data: user, status }) => {
        if (status === 200) {
          setShowCancel(false);
          toast.success(user?.message, { id: 1 });
          getBookings();
        }
      },
    }
  );

  const checkIn = (data) => {
    setStaff_Id("");
    setBooking(data);
    setShowCheckIn(true);
  };
  const cancelling = (data) => {
    setBooking(data);
    setShowCancel(true);
  };

  return (
    <>
      <BookingDetailsModal
        show={show}
        setShow={setShow}
        data={currentBooking}
      />
      <CheckInModal
        show={showCheckIn}
        setShow={setShowCheckIn}
        Function={mutate}
        setStaff_Id={setStaff_Id}
        staff_Id={staff_Id}
        setCollectedAmount={setCollectedAmount}
        collectedAmount={collectedAmount}
        booking={booking}
        loading={isLoading}
      />
      <CancelBookingModal
        show={showCancel}
        setShow={setShowCancel}
        Function={bookingCancel}
        loading={isCancelling}
      />

      <div class="table-responsive bg-white rounded-3 mt-2">
        <table class="table table-hover ">
          <thead className="table-secondary">
            <tr>
              <th scope="col">Customer Name</th>
              <th scope="col">Date</th>
              <th scope="col">Start Time</th>
              <th scope="col">End Time</th>
              <th scope="col">Amount</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems?.length > 0 ? (
              currentItems.map((book, i) => {
                return (
                  <tr key={i}>
                    <td>
                      {book?.customer_Name
                        ? book?.customer_Name
                        : book?.user_Id?.name
                        ? book?.user_Id?.name
                        : ""}
                    </td>
                    <td>{book?.date}</td>
                    <td>{book?.time}</td>
                    <td>{book?.end}</td>
                    <td>{book?.amount} PKR</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <IoMdEye
                          className="fs-3 text-secondary me-2 hc"
                          onClick={() => {
                            showModal(book);
                          }}
                        />
                        {!book?.isCheckIn &&
                          !isStaffBooking &&
                          !book?.isCancel && (
                            <button
                              className="recipt-btn bg__pink"
                              onClick={() => checkIn(book)}
                            >
                              Checkin
                            </button>
                          )}
                        {!book?.isCheckIn &&
                          !isStaffBooking &&
                          !book?.isCancel && (
                            <button
                              className="recipt-btn ms-2 bg-danger d-flex align-items-center"
                              onClick={() => cancelling(book)}
                            >
                              <MdOutlineCancel className="fs-5 me-1" />
                              Cancel
                            </button>
                          )}

                        {user?.role === "admin" && book?.isCheckIn && (
                          <p className="m-0 text-success">Completed</p>
                        )}
                        {user?.role === "admin" && book?.isCancel && (
                          <p className="m-0 text-danger">Cancelled</p>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <td colSpan={6} className="text-center py-5">
                No booking found
              </td>
            )}
          </tbody>
        </table>
        {currentItems?.length > 0 ? (
          <div className="w-90 position-absolute bottom-0 start-50 translate-middle-x mb-3">
            <div className="row align-items-center text-center text-md-start">
              <div className="col-sm-6 ms-auto col-xs-6">
                <div className="d-flex align-items-center justify-content-center">
                  <button
                    className="pagination_btn"
                    onClick={() =>
                      setCurrentPage(
                        currentPage > 1 ? currentPage - 1 : currentPage
                      )
                    }
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  {[...Array(totalPages)].map((_, i) => (
                    <div
                      key={i}
                      className={`pageNo ${
                        i + 1 === currentPage ? "pageNo_active" : ""
                      }`}
                      onClick={() => paginate(i + 1)}
                    >
                      {i + 1}
                    </div>
                  ))}
                  <button
                    className="pagination_btn"
                    onClick={() =>
                      setCurrentPage(
                        currentPage < totalPages ? currentPage + 1 : currentPage
                      )
                    }
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default BookingCheckInTable;
