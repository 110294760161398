import moment from "moment";
import React, {  useRef } from "react";
import { Modal } from "react-bootstrap";
import { AiFillPrinter } from "react-icons/ai";

const ReciptModal = ({ show, setShow, data }) => {
  const modalBodyRef = useRef(null);

  const handlePrint = () => {
    const contentToPrint = modalBodyRef.current.innerHTML;

    // Create a hidden iframe
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);

    // Set the content of the iframe
    const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
    iframeDoc.write(`
      <html>
        <head>
          <style>
            body {
              text-align: center;
              width: 300px;
              margin: 30px auto;
              font-family: monospace;
            }
            .salary-component {
              display: flex;
              justify-content: space-between;
              margin-bottom: 5px;
            }
           
          </style>
        </head>
        <body>
          ${contentToPrint}
          POWERED BY BEAUTOWNS
        </body>
      </html>
    `);

    // Trigger the print functionality
    iframe.contentWindow.print();

    // Remove the iframe after printing
    document.body.removeChild(iframe);
  };

  return (
    <Modal centered show={show} onHide={setShow}>
      <Modal.Header closeButton className="bg-white">
        <Modal.Title>Transaction Receipt</Modal.Title>
        <AiFillPrinter className="ms-auto fs-2 hc" onClick={handlePrint}/>
        
      </Modal.Header>

      <Modal.Body className="bg-white" ref={modalBodyRef}>
        {/* <button onClick={() => window.print()}>print</button> */}
        <div class="receipt-container">
          <div class="header">
            <h2>{data?.store_Id?.name}</h2>
            <p>Date : {moment(data?.createdAt).format("DD MMMM, YYYY")}</p>
          </div>
          <div class="payroll-details">
            <p className="m-0 salary-component">
              <strong>Customer Name:</strong> {data?.user_Id?.name}
            </p>
            <p className="m-0 mt-1 salary-component">
              <strong>Phone</strong> {data?.phone}
            </p>
            <hr className="m-0 my-2" />
            <p className="m-0 salary-component">
              <strong>Staff Name:</strong> Nabeel
            </p>
            <p className="m-0 mt-1 salary-component">
              <strong>Designation:</strong> Stylist
            </p>
          </div>
          <div class="payroll-details">
            <h4 className="text-center m-0 ">Details</h4>
            <div class="salary-component">
              <span>Start Time</span>
              <span>{data?.booking_Id?.time}</span>
            </div>
            <div class="salary-component">
              <span>End Time</span>
              <span>{data?.booking_Id?.end}</span>
            </div>
            {data?.booking_Id?.service_Ids?.length > 0 &&
              data?.booking_Id?.service_Ids?.map((service, i) => (
                <div class="salary-component">
                  <span>Service {i + 1}:</span>
                  <span>{service?.name}</span>
                </div>
              ))}
          </div>
          <div class="total-amount">
            <p className="d-flex salary-component">
              <strong>Total Amount:</strong> {data?.amount} PKR
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ReciptModal;
