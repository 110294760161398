import React, { useEffect, useState, useRef } from "react";
import html2canvas from "html2canvas";
import Logo from "../assets/Logo-02.png";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useMutation } from "@tanstack/react-query";
import apis from "../../services";
import { Spinner } from "react-bootstrap";
import { setMarketing } from "../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import MarketingCustomers from "./MarketingCustomers";

const TemplateDesign = ({
  captureAndSendImage,
  isLoading,
  handleCheckboxChange,
  selectedIds,
  setSelectedIds,
  mainData,
  setMainData,
  divRef,
}) => {
  const { storeInfo, storeBookings: users } = useSelector((e) => e?.user);

  let [theArray, setArray] = useState([]);

  const [data, setData] = useState({
    offername: "Deal 1",
    background: "",
    price: "Rs:5000/-",
    logo: "",
    bg_image: "",
    target: "all",
    length: 5,
    fields: ["facial", "manicure", "padicure", "makeup", "party makeup"],
    values: ["500", "200", "1500", "800", "1000"],
  });

  const onChange = (e) => {
    const { name, value, files } = e.target;
    if (files && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const fileUrl = event.target.result;
        setData((prev) => ({ ...prev, [name]: fileUrl }));
      };
      reader.readAsDataURL(file);
    } else {
      setData((prev) => ({ ...prev, [name]: value }));
    }
  };

  useEffect(() => {
    setArray(data?.length);
  }, []);

  let lastArrayValue = 0;

  let onAddFieldHandler = () => {
    if (theArray.length > 0) {
      setArray(theArray.sort());
      setArray((prevState) => [...prevState, lastArrayValue + 1]);
    } else {
      setArray([1]);
    }
  };

  let onRemoveFieldHandler = async (id) => {
    setArray(theArray?.filter((e) => e !== id));
    await setData((prevState) => ({
      ...prevState,
      [`field${id}`]: "",
      [`value${id}`]: "",
    }));
  };

  const style = {
    background:
      data?.background_type === "image"
        ? `url(${data.background})`
        : data?.background,
  };

  return (
    <div className="row mt-5">
      {/* <button >Capture and Download Image</button> */}

      <div className="col-lg-6">
        <h3 className="fw-bold">Create Template</h3>
        <div className="bg-white rounded-3 p-3 mb-3">
          <div className="row">
            <div className="">
              <div className="mb-3">
                <label htmlFor="offername">Offer name</label>
                <input
                  type="text"
                  className="form-control"
                  name="offername"
                  value={data?.offername}
                  maxLength={20}
                  onChange={onChange}
                />
              </div>
              {/* <div className="mb-3">
                <label htmlFor="offername">Description</label>
                <textarea
                  type="text"
                  className="form-control w-100"
                  name="description"
                  rows="4"
                  maxLength={38}
                  value={data?.description}
                  onChange={onChange}
                ></textarea>
              </div> */}
            </div>

            <div className="row align-items-center pe-0 ">
              <div className="col-lg-6">
                <div className="d-flex justify-content-between mb-3">
                  <div
                    className="d-flex align-items-center fs-5 hc"
                    onClick={() =>
                      setData((prevData) => ({
                        ...prevData,
                        background_type: "image",
                      }))
                    }
                  >
                    <input
                      type="checkbox"
                      className="checkBoxInput"
                      checked={data?.background_type === "image"}
                    />
                    Image
                  </div>
                  <div
                    className="d-flex align-items-center fs-5 hc ms-4"
                    onClick={() =>
                      setData((prevData) => ({
                        ...prevData,
                        background_type: "color",
                      }))
                    }
                  >
                    <input
                      type="checkbox"
                      className="checkBoxInput"
                      checked={data?.background_type === "color"}
                    />
                    Color
                  </div>
                </div>
              </div>
              <div className="col-lg-6 pe-lg-0 ">
                <div className="mb-3">
                  <label htmlFor="logo">
                    Background {data?.background_type}
                  </label>
                  <br />
                  {data?.background_type === "image" ? (
                    <input
                      type="file"
                      className="form-control"
                      name="background"
                      onChange={onChange}
                    />
                  ) : (
                    <input
                      type="color"
                      className="form-control"
                      name="background"
                      value={data?.background}
                      onChange={onChange}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="row pe-0 ">
              <div className="col-lg-6">
                <div className="mb-3">
                  <label htmlFor="logo">Salon Logo</label>
                  <br />
                  <input
                    type="file"
                    className="form-control"
                    name="logo"
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="col-lg-6 pe-lg-0 ">
                <div className="mb-3">
                  <label htmlFor="target">Target</label>

                  <select
                    name="target"
                    id=""
                    onChange={(e) =>
                      setMainData({ ...mainData, target: e.target.value })
                    }
                    value={mainData?.target}
                    className="form-control"
                  >
                    <option value="all">All Customers</option>
                    <option value="specific">Specific Customer</option>
                  </select>
                </div>
              </div>
            </div>

            {theArray?.length === 8 ? (
              <></>
            ) : (
              <>
                <button
                  onClick={onAddFieldHandler}
                  className="backBtn d-block mx-auto"
                >
                  Add Fields ({8 - theArray.length})
                </button>
              </>
            )}
            <div className="temp-inputs my-1">
              {/* {theArray?.map((item, index) => {
                return (
                  <>
                    <div className="mt-3">
                      <div className="d-flex justify-content-between ">
                        <span className="field_h_title">Field {index + 1}</span>

                        <button
                          className="hc backBtn bg-danger text-white"
                          onClick={() => onRemoveFieldHandler(index + 1)}
                        >
                          Delete
                        </button>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="mb-2">
                            <label>Label</label>
                            <input
                              type="text"
                              name={`field${index + 1}`}
                              id={`field${index + 1}`}
                              value={`${data?.[`field${index + 1}`]}`}
                              className="form-control"
                              maxLength={20}
                              onChange={onChange}
                              // onChange={(e) => onChange(e, index, "field")}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="mb-2">
                            <label>Value</label>
                            <input
                              type="text"
                              className="form-control"
                              name={`value${index + 1}`}
                              id={`value${index + 1}`}
                              value={`${data?.[`value${index + 1}`]}`}
                              maxLength={11}
                              onChange={onChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })} */}
            </div>
            {mainData?.target === "specific" && (
              <MarketingCustomers
                handleCheckboxChange={handleCheckboxChange}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
              />
            )}

            <button
              className="backBtn mt-4 d-flex align-items-center px-4 d-block mx-auto"
              onClick={captureAndSendImage}
            >
              {isLoading ? <Spinner size="sm" /> : "Create"}
            </button>
          </div>
        </div>
      </div>

      <div className="col-lg-6 mt-lg-5">
        <div
          className="templateMarketing position-relative"
          ref={divRef}
          style={style}
        >
          <div className="d-flex align-items-center p-2">
            <img
              src={data?.logo || Logo}
              className="templateLogo me-2"
              alt=""
            />
            <h2 className="text-dark mt-1 font-lobster single_line">
              {storeInfo?.name}
            </h2>
          </div>
          <h5 className="text-center fw-bold ">{data?.type}</h5>
          {data.offername && (
            <h5 className="text-center my-3 fw-bold ">{data.offername}</h5>
          )}

          {data?.fields?.map((field, index) => (
            <div key={index} className="grid-container my-2 p-2">
              <h5 className="m-0 fw-bold">{field}</h5>
              <h5 className="m-0 fw-bold text-end">{data?.values[index]}</h5>
            </div>
          ))}

          <h6 className="position-absolute bottom-0 end-0 text-white me-3">
            Powered by beautowns
          </h6>
        </div>
      </div>
      {/* <img
        src={data.bg_image || discounted}
        className="img-fluid discountedImg d-block mx-auto mt-5 "
        alt=""
      /> */}
    </div>
  );
};

export default TemplateDesign;
