import React, { useState } from "react";
import apis from "../../services";
import DeleteModal from "../Modal/DeleteModal";
import toast from "react-hot-toast";
import { MdDeleteForever } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { FaEdit } from "react-icons/fa";
import {
  setCategory,
  setNoOfCategory,
  setNoOfServices,
  setServices,
} from "../../redux/slices/userSlice";
import { useMutation } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

const CategoryTable = ({ categories, updating }) => {
  const { user } = useSelector((state) => state.user);
  const { pathname } = useLocation();
  let role = user?.role;
  const dispatch = useDispatch();
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = categories?.slice(indexOfFirstItem, indexOfLastItem);
  const [id, setId] = useState();
  const [show, setShow] = useState(false);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const showModal = (id) => {
    setId(id);
    setShow(true);
  };

  const totalPages = Math.ceil(categories?.length / itemsPerPage);

  const { mutate: delete_cateogry, isLoading: loading } = useMutation(
    () => apis.delete_service_category({ id: id, role: role }),
    {
      onError: function ({ message }) {
        toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          toast.success(data?.message);
          setShow(false);
          dispatch(setServices(data?.services));
          dispatch(setCategory(data?.categories));
          dispatch(setNoOfServices(data?.services.length || 0));
          dispatch(setNoOfCategory(data?.categories.length || 0));
        }
      },
    }
  );

  return (
    <>
      <div className="">
        <DeleteModal
          deleteFunction={delete_cateogry}
          show={show}
          setShow={setShow}
          loading={loading}
        />
        <div class="table-responsive bg-white rounded-3 mt-2">
          <table class="table table-hover ">
            <thead className="table-secondary">
              <tr>
                <th scope="col">Category Name</th>
                {role === "admin" && !pathname.includes("/stores") && <th scope="col">Salon Name</th>}
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems?.length > 0 ? (
                currentItems?.map((cat) => {
                  return (
                    <tr>
                      <td>{cat?.name}</td>
                      {role === "admin" && !pathname.includes("/stores") && (
                        <td>
                          {cat?.store_Id === null
                            ? "Store Deleted"
                            : cat?.store_Id?.name}
                        </td>
                      )}

                      <td>
                        <div className="d-flex">
                          <FaEdit
                            className="fs-3 me-3 text-secondary hc"
                            onClick={() => updating(cat)}
                          />
                          <MdDeleteForever
                            className="fs-3 text-danger hc"
                            onClick={() => showModal(cat?._id)}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <td colSpan={2} className="text-center py-5">
                  No category found
                </td>
              )}
            </tbody>
          </table>
          {categories?.length > 0 && (
            <div className="w-90 position-absolute bottom-0 start-50 translate-middle-x mb-3">
              <div className="row align-items-center text-center text-md-start">
                <div className="col-sm-6 ms-auto col-xs-6">
                  <div className="d-flex align-items-center justify-content-center">
                    <button
                      className="pagination_btn"
                      onClick={() =>
                        setCurrentPage(
                          currentPage > 1 ? currentPage - 1 : currentPage
                        )
                      }
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    {[...Array(totalPages)].map((_, i) => (
                      <div
                        key={i}
                        className={`pageNo ${
                          i + 1 === currentPage ? "pageNo_active" : ""
                        }`}
                        onClick={() => paginate(i + 1)}
                      >
                        {i + 1}
                      </div>
                    ))}
                    <button
                      className="pagination_btn"
                      onClick={() =>
                        setCurrentPage(
                          currentPage < totalPages
                            ? currentPage + 1
                            : currentPage
                        )
                      }
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CategoryTable;
