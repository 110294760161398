import React from "react";
import Layout from "../components/layout/Layout";
import ServiceDetailsComp from "../components/salon-services/ServiceDetailsComp";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const ServiceDetail = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { services } = useSelector((e) => e.user);
  const [service] = services?.filter((e) => e?._id === state?.id) || [];

  useEffect(() => {
    if (!state?.id) {
      navigate(-1);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <Layout>
      <ServiceDetailsComp service={service} />
    </Layout>
  );
};

export default ServiceDetail;
