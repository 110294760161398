import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import apis from "../../services";
import toast from "react-hot-toast";
import { useMutation } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
// import imageIcon from "../assets/imageIcon.png";
// import { TbPhotoEdit } from "react-icons/tb";
import { setCategory } from "../../redux/slices/userSlice";
import { BeatLoader } from "react-spinners";

const AddCategoryModal = ({
  showModal,
  setShowModal,
  isEdit,
  setCategory1,
  category,
}) => {
  const { storeInfo, user, storeID } = useSelector((e) => e?.user);
  const [previewImage, setPreviewImage] = useState(null);
  const dispatch = useDispatch();
  const [data, setData] = useState({
    store_Id: user?.role === "admin" ? storeID : storeInfo?._id,
    name: "",
    // image: "",
  });

  useEffect(() => {

    if (isEdit) {
      setData({
        store_Id: "",
        name: category?.name,
        // image: isEdit.image,
      });
    } else {
      setData({
        store_Id: user.role==="admin" ? storeID : storeInfo?._id,
        name: "",
      });
    }
  }, [showModal]);

  const closeModal = () => {
    setData({
      store_Id: "",
      name: "",
    });
    setCategory1(null);
    setShowModal(false);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const { mutate, isLoading } = useMutation(apis.add_service_category, {
    onError: function ({ message }) {
      toast.error(message?.message, { id: 1 });
    },
    onSuccess: ({ data, status }) => {
      if (status === 201) {
        setData((prevData) => ({ ...prevData, image: null }));
        setPreviewImage(null);
        toast.success(data?.message, { id: 1 });
        dispatch(setCategory(data?.categories));
        setShowModal(false);
      }
    },
  });

  const { mutate: updateCategory, isLoading: isUpdating } = useMutation(
    apis.updateServiceCategory,
    {
      onError: function ({ message }) {
        toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          setData((prevData) => ({ ...prevData, name: "", image: null }));
          setPreviewImage(null);
          toast.success(data?.message, { id: 1 });
          dispatch(setCategory(data?.serviceCategories));
          setShowModal(false);
        }
      },
    }
  );

  const save_category = (e) => {
    e.preventDefault();
    const { store_Id, ...rest } = data;
    const form_data = new FormData();
    for (const [key, value] of Object.entries(isEdit ? rest : data)) {
      form_data.append(key, value);
    }
    if (data?.name) {
      if (isEdit) {
        updateCategory({
          id: category?._id,
          body: form_data,
          role: user?.role,
        });
      } else {
        mutate({ body: form_data, role: user?.role });
      }
    }
  };

  const fileInputRef = useRef();

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleFileSelection = () => {
    const selectedFile = fileInputRef.current.files[0];
    setPreviewImage(URL.createObjectURL(selectedFile));
    setData((prevData) => ({
      ...prevData,
      image: selectedFile,
    }));
  };

  return (
    <div>
      <Modal centered show={showModal} onHide={closeModal}>
      <Modal.Header className="bg-white" closeButton>
          <Modal.Title> {isEdit ? "Update Category" : "Add Category"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-white ">
          <div className="container">
            <form onSubmit={save_category}>
              
              {/* <div className="profile_image_wrapper">
                <img
                  src={previewImage || imageIcon}
                  onClick={triggerFileInput}
                  className="img-fluid d-block mx-auto salonProfileImage"
                  alt="cate"
                />
                <TbPhotoEdit
                  onClick={triggerFileInput}
                  className="text-center hc d-block mx-auto fs-1 mt-1 inputImage"
                />
              </div> */}

              <div className="mb-3">
                <label htmlFor="" className="ms-1">
                  Category Name
                </label>
                <input
                  type="text"
                  name="name"
                  required
                  value={data?.name}
                  onChange={onChange}
                  className="form-control"
                />
              </div>

              <input
                type="file"
                name="image"
                accept="image/png, image/gif, image/jpeg"
                onChange={handleFileSelection}
                ref={fileInputRef}
                className="d-none"
              />

              <div className="col-lg-4 col-md-6 col-sm-10 mx-auto">
                <button
                  className="login-btn mt-3 mb-2 fw-bold"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading || isUpdating ? (
                    // <Spinner size="sm" />
                    <BeatLoader size={15} color="#fff" />
                  ) : isEdit ? (
                    "UPDATE"
                  ) : (
                    "ADD"
                  )}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddCategoryModal;
