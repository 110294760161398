import React from "react";

const WalletComp = () => {
  return (
    <div className="p-3">
      <h3 className="mb-5 fw-bold">Wallet</h3>
      <div className="row justify-content-center  mt-5 text-center">
        <div className="col-md-6 col-lg-4 my-3 ">
          <div className="w-100 h-100 bg-white p-3 rounded-3 shadow-sm ">
            <h3 className="text-secondary text-uppercase ">Balance</h3>
            <h2 className="fw-bold">0</h2>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 my-3 ">
          <div className="w-100 h-100 bg-white p-3 rounded-3 shadow-sm ">
            <h3 className="text-secondary text-uppercase ">Profit</h3>
            <h2 className="fw-bold">0</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletComp;
