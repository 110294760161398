import React from "react";
import Chart from "react-apexcharts";

const ServiceChart = ({ data }) => {
  const chartData = {
    options: {
      chart: {
        id: "basic-column-chart",
        type: "bar",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      xaxis: {
        categories: Object?.keys(data || {}),
      },
      colors: ["#FF69B4"],
    },
    series: [
      {
        name: "Service Sales",
        data: Object?.values(data || {}),
      },
    ],
  };

  return (
    <div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        width="100%"
        height="300px"
      />
    </div>
  );
};

export default ServiceChart;
