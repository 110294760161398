import React, { useState, useRef, useEffect } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import apis from "../../services";
import toast from "react-hot-toast";
import imageIcon from "../assets/imageIcon.png";
import { useDispatch, useSelector } from "react-redux";
import { errorValidate } from "../../validations/errorHandleJoi";
import { storeValidate } from "../../validations/StoreValidation";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { TbPhotoEdit } from "react-icons/tb";
import { setStoreInfo } from "../../redux/slices/userSlice";
import Select from "react-select";
import { BeatLoader } from "react-spinners";
import SalonMap from "./SalonMap";

const AddSalon = ({ isEdit }) => {
  const { data: categories, isLoading } = useQuery(
    ["get_store_categories"],
    () => apis.getStoreCategory()
  );
  const { user, storeInfo } = useSelector((e) => e.user);

  const dispatch = useDispatch();
  const [imagePreview, setImagePreview] = useState(null);

  const [data, setData] = useState({
    name: "",
    segment_Id: "",
    salon_owner_Id: user?._id,
    category_Ids: [],
    country: "",
    city: "",
    phone: "",
    details: "",
    no_of_slots: "",
    location: "",
    latitude: "",
    longitude: "",
    image: null,
  });

  console.log(data, "data");
  useEffect(() => {
    if (storeInfo) {
      setData({
        name: storeInfo?.name,
        segment_Id: storeInfo?.segment_Id,
        salon_owner_Id: user?._id,
        // category_Ids: storeInfo?.category_Ids,
        country: storeInfo?.country,
        city: storeInfo?.city,
        phone: storeInfo?.phone,
        details: storeInfo?.details,
        no_of_slots: storeInfo?.no_of_slots,
        location: storeInfo?.location,
        latitude: storeInfo?.latitude,
        longitude: storeInfo?.longitude,
        image: storeInfo?.image,
      });
    }
    setData((prev) => ({
      ...prev,
      category_Ids:
        storeInfo?.category_Ids?.length > 0 &&
        storeInfo?.category_Ids?.map((e) => e?._id),
    }));

    setImagePreview(storeInfo?.image);
    // eslint-disable-next-line
  }, [storeInfo]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  let [error, setError] = useState();
  console.log(error, "error");
  const { mutate, isLoading: isSubmitting } = useMutation(apis.create_store, {
    onError: function ({ message }) {
      setError("");
      toast.error(message?.message, { id: 1 });
    },
    onSuccess: ({ data: user, status }) => {
      if (status === 201) {
        setData(null);
        dispatch(setStoreInfo(user?.store));
        toast.success(user?.message, { id: 1 });
      }
    },
  });
  const { mutate: update_store, isLoading: isUpdating } = useMutation(
    apis.updateStore,
    {
      onError: function ({ message }) {
        setError("");
        toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data: user, status }) => {
        if (status === 200) {
          dispatch(setStoreInfo(user?.store));
          toast.success(user?.message, { id: 1 });
        }
      },
    }
  );

  const create__store = (e) => {
    e.preventDefault();
    const form_data = new FormData();
    for (const [key, value] of Object.entries(data)) {
      if (isEdit) {
        if (key === "salon_owner_Id" || key === "category_Ids") {
          continue;
        }
      } else if (key === "category_Ids") {
        continue;
      }
      form_data.append(key, value);
    }
    let response = errorValidate(storeValidate(data));
    if (response === true) {
      data?.category_Ids?.forEach((categoryId, index) => {
        form_data.append(`category_Ids[${index}]`, categoryId);
      });
      if (data?.latitude || data?.longitude) {
        if (data?.image) {
          if (isEdit) {
            update_store({ id: storeInfo?._id, body: form_data });
          } else {
            mutate(form_data);
          }
        } else {
          toast.error("Please Select Image", { id: 1 });
        }
      }else{
        toast.error("Please Select valid Location", { id: 1 });
      }
    } else {
      setError(response);
    }
  };

  const fileInputRef = useRef();
  const triggerFileInput = () => {
    fileInputRef.current.click();
  };
  const handleFileSelection = () => {
    const selectedFile = fileInputRef.current.files[0];
    setImagePreview(URL.createObjectURL(selectedFile));
    setData((prevData) => ({
      ...prevData,
      image: selectedFile,
    }));
  };

  const handleSelectChange = (selectedValues) => {
    const selectedCategoryIds = selectedValues?.map((option) => option?._id);

    setData((prevData) => ({
      ...prevData,
      category_Ids: Array.isArray(selectedCategoryIds)
        ? selectedCategoryIds
        : [selectedCategoryIds],
    }));
  };

  return (
    <div className={`${isEdit ? "py-3" : "container"}`}>
      {isLoading && <div id="cover-spin"></div>}
      <h3 className="fw-bold text-uppercase">
        {isEdit ? "Update Salon" : "Register Salon"}
      </h3>
      <form onSubmit={create__store} className="bg-white p-3 rounded-3 ">
        <input
          type="file"
          name="image"
          accept="image/*"
          className="d-none"
          onChange={handleFileSelection}
          ref={fileInputRef}
        />
        <div className="profile_image_wrapper">
          <img
            src={imagePreview || imageIcon}
            onClick={triggerFileInput}
            className="img-fluid hc d-block mx-auto salonProfileImage shadow-sm"
            alt=""
          />
          <TbPhotoEdit
            onClick={triggerFileInput}
            className="text-center hc d-block mx-auto fs-1 mt-1 inputImage"
          />
        </div>

        <div className="row">
          <div className="col-md-6 col-lg-4 mt-3">
            <div className="form-group">
              <label className="ms-1"> Salon Name</label>
              <input
                type="text"
                name="name"
                onChange={onChange}
                value={data?.name}
                className="form-control"
              />
              {error?.name && <p className="text-danger m-0">{error?.name}</p>}
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mt-3">
            <div className="form-group">
              <label className="ms-1">Salon Type</label>
              <select
                id="inputState"
                onChange={onChange}
                name="segment_Id"
                value={data?.segment_Id}
                className="form-control"
              >
                <option disabled="" selected="">
                  Choose...
                </option>
                <option value={1}>Gents</option>
                <option value={2}>Ladies</option>
                <option value={3}>Both</option>
              </select>
              {error?.segment_Id && (
                <p className="text-danger m-0">{error?.segment_Id}</p>
              )}
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mt-3">
            <div className="form-group">
              <label className="ms-1">Salon Category</label>
              {/* <select
                id="inputState"
                onChange={onChange}
                name="category_Id"
                value={data?.category_Id}
                className="form-control"
              >
                <option disabled="" selected="">
                  Choose...
                </option>
                {categories?.data?.categories?.length > 0 &&
                  categories?.data?.categories?.map((cat) => {
                    return <option value={cat?._id}>{cat?.name}</option>;
                  })}
              </select> */}
              <Select
                isMulti
                options={categories?.data?.categories}
                value={categories?.data?.categories?.filter(
                  (option) =>
                    data?.category_Ids?.length > 0 &&
                    data?.category_Ids?.includes(option._id)
                )}
                onChange={handleSelectChange}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option._id}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    backgroundColor: "#edf2ff",
                    borderRadius: "10px",
                    borderColor: "transparent",
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: "#000",
                  }),
                }}
                placeholder="Select Categories"
              />
              {error?.category_Id && (
                <p className="text-danger m-0">{error?.category_Id}</p>
              )}
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mt-3">
            <div className="form-group">
              <label className="ms-1">Salon Phone</label>
              <PhoneInput
                country={"pk"}
                value={data?.phone}
                name="phone"
                onlyCountries={["pk"]}
                onChange={(value) => setData({ ...data, phone: value })}
              />
              {error?.phone && (
                <p className="text-danger m-0">{error?.phone}</p>
              )}
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mt-3">
            <div className="form-group">
              <label className="ms-1">Country</label>
              <select
                id="inputState"
                onChange={onChange}
                value={data?.country}
                name="country"
                onlyCountries={["pk"]}
                className="form-control"
              >
                <option disabled="" selected="">
                  Choose...
                </option>
                <option>Pakistan</option>
              </select>
              {error?.country && (
                <p className="text-danger m-0">{error?.country}</p>
              )}
            </div>
          </div>

          <div className="col-md-6 col-lg-4 mt-3">
            <div className="form-group">
              <label className="ms-1">City</label>
              <select
                id="inputState"
                onChange={onChange}
                name="city"
                value={data?.city}
                className="form-control"
              >
                <option selected>Choose...</option>
                <option value="Karachi">Karachi</option>
                <option value="Hyderabad">Hyderabad</option>
                <option value="Sukkar">Sukkar</option>
                <option value="Lahore">Lahore</option>
                <option value="Islamabad">Islamabad</option>
                <option value="Quetta">Quetta</option>
                <option value="Peshawar">Peshawar</option>
              </select>
              {error?.city && <p className="text-danger m-0">{error?.city}</p>}
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mt-3">
            <div className="form-group">
              <label className="ms-1">Location</label>
              {/* <input
                type="text"
                onChange={onChange}
                name="location"
                value={data?.location}
                className="form-control"
              /> */}
              <SalonMap data={data} setData={setData} />
              {error?.location && (
                <p className="text-danger m-0">{error?.location}</p>
              )}
            </div>
          </div>

          <div className="col-md-6 col-lg-4 mt-3">
            <div className="form-group">
              <label className="ms-1">No Of Slots</label>
              <select
                id="inputState"
                onChange={onChange}
                name="no_of_slots"
                value={data?.no_of_slots}
                className="form-control"
              >
                <option value="option" disabled="" selected="">
                  Choose...
                </option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
              {error?.no_of_slots && (
                <p className="text-danger m-0">{error?.no_of_slots}</p>
              )}
            </div>
          </div>

          <div className="col-md-6 col-lg-4 mt-3">
            <div className="form-group">
              <label className="ms-1">Details</label>
              {/* <input
                type="text"
                onChange={onChange}
                name="details"
                value={data?.details}
                className="form-control"
              /> */}
              <textarea
                onChange={onChange}
                name="details"
                value={data?.details}
                rows="4"
                className="form-control"
              ></textarea>
              {error?.details && (
                <p className="text-danger m-0">{error?.details}</p>
              )}
            </div>
          </div>
          <div className="col-lg-3 mx-auto  col-md-4 col-sm-10 ">
            <button className="login-btn mt-3 fw-bold d-flex justify-content-center align-items-center">
              {isSubmitting || isUpdating ? (
                // <Spinner />
                <BeatLoader size={15} color="#fff" />
              ) : (
                "SAVE"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddSalon;
