import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../components/layout/Layout";
import StaffTable from "../components/Table/StaffTable";
import { setNoOfStaff, setStaffs } from "../redux/slices/userSlice";
import apis from "../services";
import { useMutation } from "@tanstack/react-query";

const Staff = () => {
  const { staffs, user, storeInfo } = useSelector((e) => e?.user);
  const dispatch = useDispatch();

  const { mutate: getStaff, isLoading } = useMutation(
    () =>
      apis.getStoreStaff({
        id: user?.role === "store" ? storeInfo?._id : user?._id,
        role: user?.role,
      }),
    {
      onError: function ({ message }) {
        // toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          dispatch(setStaffs(data?.staff));
          dispatch(setNoOfStaff(data?.staff.length || 0));
        }
      },
    }
  );

  useEffect(() => {
    getStaff();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Layout>
       {isLoading && <div id="cover-spin"></div>}
      <div className="p-2">
        <div className="d-flex p-2 flex-wrap align-items-center w-100 justify-content-between">
          <h3 className="m-0 my-3 fw-bold ">Staff</h3>
          {user?.role !== "admin" && (
            <div className="col-lg-2 col-md-3 col-sm-4">
              <Link to={`/${user?.role}/staff/add`}>
                <button className="login-btn my-3 px-3 fw-bold addbtn">
                  ADD STAFF
                </button>
              </Link>
            </div>
          )}
        </div>

        <StaffTable role={user?.role} staffs={staffs} />
      </div>
    </Layout>
  );
};

export default Staff;
