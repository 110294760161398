import React from "react";
import { Link } from "react-router-dom";
import { FaUsers, FaCheckToSlot } from "react-icons/fa6";
import { MdOutlineDesignServices } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { IoStarHalfOutline } from "react-icons/io5";
import { FaCheck } from "react-icons/fa6";
import { FaCity } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import SalonMap from "./SalonMap";
import GoogleMaps from "./GoogleMaps";

const SalonInfomation = ({ storeInfo, user,services,staffs }) => {
  return (
    <div className="p-3">
      <div className="d-flex flex-column-reverse  flex-lg-row">
        <div className="salon_title bg-white p-2 rounded-3">
          <div className="topPar">
            <h3 className="mt-2 text-center text-lg-start fw-bold   ">
              {storeInfo?.name}
            </h3>
            <div className="d-flex align-items-start ">
              <MdLocationOn className="text-pink fs-3 me-1" />
              <h6 className=" m-0 ms-1">{storeInfo?.location}</h6>
            </div>
            <div className="d-flex align-items-center mt-2">
              <FaCity className="text-pink fs-4 me-2" />
              <h6 className=" m-0 ms-1">{storeInfo?.city}</h6>
            </div>

            <div className="d-flex align-items-center mt-3">
              <h6 className="fw-bold m-0">
                <FaPhoneAlt className="text-pink fs-5 me-2" />
              </h6>
              <h6 className="m-0 ms-1">+{storeInfo?.phone}</h6>
            </div>
            <div className="d-flex align-items-center mt-3">
              <h6 className="fw-bold m-0">
                <MdOutlineDesignServices className="text-pink fs-4 me-2" />
              </h6>
              <h6 className=" m-0 ms-1">{services?.length || 0} services</h6>
            </div>
            <div className="d-flex align-items-center mt-3">
              <h6 className="fw-bold m-0">
                <FaUsers className="text-pink fs-4 me-2" />
              </h6>
              <h6 className="m-0 ms-1">{staffs?.length || 0} staffs</h6>
            </div>
            <div className="d-flex align-items-center mt-3">
              <h6 className="fw-bold m-0">
                <IoStarHalfOutline className="text-pink fs-4 me-2" />
              </h6>
              <h6 className="m-0 ms-1">{storeInfo?.rating?.toFixed(1) || 0}</h6>
            </div>
            <div className="d-flex align-items-center mt-3">
              <h6 className="fw-bold m-0">
                <FaCheckToSlot className="text-pink fs-4 me-2" />
              </h6>
              <h6 className="m-0 ms-1">{storeInfo?.no_of_slots || 0} slots</h6>
            </div>
            <div className="d-flex mt-3">
              <h6 className="fw-bold m-0">Salon Type</h6>
              <h6 className=" text-pink m-0 ms-2">
                {storeInfo?.segment_Id === 1
                  ? "MALE"
                  : storeInfo?.segment_Id === 2
                  ? "FEMALE"
                  : storeInfo?.segment_Id === 3
                  ? "BOTH"
                  : ""}
              </h6>
            </div>
            <h6 className="fw-bold mt-2 ">Category</h6>
            <div className="d-flex flex-wrap">
              {storeInfo?.category_Ids?.map((e) => (
                <div className="d-flex align-items-center mt-2 me-2">
                  <FaCheck className="text-pink" />{" "}
                  <span className="fs-6 ms-2">{e.name}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-4 col-lg-12 mx-auto">
            <Link to={`/${user?.role}/my-salon/edit`}>
              <button className="login-btn mt-3 px-3">EDIT</button>
            </Link>
          </div>
        </div>
        <img
          src={storeInfo?.image}
          className="img-fluid d-block ms-lg-auto salonMainImage rounded-3"
          alt=""
        />
      </div>
      <div className="d-flex justify-content-end align-items-center my-3">
        <div className="col-md-3 col-lg-2"></div>
      </div>
      <div className="row mt-1">
        <h3>About</h3>
        <h5 className="m-0 fw-normal">{storeInfo?.details}</h5>
      </div>

      <h3 className="mt-5">Opening times</h3>
      <div class=" col-lg-4 p-1 rounded-2 ">
        <thead>
          <tr>
            <th scope="col">Day</th>
            <th scope="col" className="ps-3 ps-md-4">
              From-To
            </th>
          </tr>
        </thead>

        {storeInfo?.store_timings?.length > 0 &&
          storeInfo?.store_timings?.map((working, i) => {
            return (
              <tr>
                <th className="py-1">{working?.day}</th>
                <td className="text-lowercase ps-3 ps-md-4 d-flex ">
                  {working?.isAvailable ? (
                    <>
                      <div className="fromTime">{working?.from}</div>{" "}
                      <div className="mx-2">-</div> <div>{working?.to}</div>
                    </>
                  ) : (
                    "CLOSE"
                  )}
                </td>
              </tr>
            );
          })}
      </div>
    </div>
  );
};

export default SalonInfomation;
