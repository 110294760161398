import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import Dashboard from "../components/dashboard/Dashboard";
import { useDispatch, useSelector } from "react-redux";
// import reduxApis from "../redux/api";
import apis from "../services";
import { setAnalytics, setGraphs } from "../redux/slices/userSlice";

const Home = () => {
  const { graphs, analytics, user, staffDetails, storeInfo } = useSelector(
    (e) => e.user
  );

  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const getAnalytics = async () => {
    let analytics;
    let graphs;
    try {
      setLoader(true);

      if (user?.role === "store") {
        analytics = await apis.getStoreInfo(storeInfo?._id);
        graphs = await apis.getStoreGraph(storeInfo?._id);
      } else if (user?.role === "staff") {
        analytics = await apis.getStaffAnalytics(staffDetails?._id);
        graphs = await apis.getStaffGraphs(staffDetails?._id);
      } else if (user?.role === "admin") {
        analytics = await apis.getAdminAnalytics(user?._id);
        graphs = await apis.getAdminGraphs(user?._id);
      }
      setLoader(false);
      if (analytics?.data && graphs?.data) {
        dispatch(setAnalytics(analytics.data));
        dispatch(setGraphs(graphs.data));
      }
    } catch (error) {
      setLoader(false);
    }
  };
  useEffect(() => {
    getAnalytics();
  }, []);

  return (
    <Layout>
      {loader && <div id="cover-spin"></div>}
      <Dashboard info={analytics} storeGraphs={graphs} />
    </Layout>
  );
};

export default Home;
