import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import toast from "react-hot-toast";
import { BookingValidate } from "../validations/BookingValidation";
import { errorValidate } from "../validations/errorHandleJoi";
import { useMutation } from "@tanstack/react-query";
import apis from "../services";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import DateScroll from "../components/bookings/DateScroll";
import { Spinner } from "react-bootstrap";
import BookingServiceTable from "../components/Table/BookingServiceTable";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import reduxApis from "../redux/api";
import { StepBar } from "../components/bookings/StepBar";
import StaffSelection from "../components/bookings/StaffSelection";
import BookingCheckout from "../components/bookings/BookingCheckout";

const AddBooking = () => {
  const navigate = useNavigate();
  const {
    staffs: storeStaff,
    services,
    storeInfo,
    user
  } = useSelector((e) => e?.user);

  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedStaffs, setSelectedStaffs] = useState([]);

  let seletedService = services?.filter((e) =>
    selectedServices?.includes(e._id)
  );
  const sumOfDurations = seletedService?.reduce(
    (acc, obj) => acc + obj?.duration,
    0
  );
  const totalAmount = seletedService?.reduce((acc, obj) => acc + obj?.value, 0);
  const [isLoading, setIsLoading] = useState(false);
  const [storeSlots, setStoreSlots] = useState([]);
  const dispatch = useDispatch();
  const [selectdSlots, setSelectedSlots] = useState();
  const [coupon, setCoupon] = useState(null);

  const [data, setData] = useState({
    name: "",
    phone: "",
    email: "",
    store_Id: storeInfo?._id,
    staff_Id: "",
    advance_Amount: 0,
    booking_type: "manual",
    service_Ids: selectedServices,
    time: "",
    date: "",
  });

  // console.log(data,"data")

  useEffect(() => {
    setData({ ...data, staff_Id: selectedStaffs?.[0] });
    // eslint-disable-next-line
  }, [selectedStaffs]);

  const [error, setError] = useState(null);
  console.log(error, "error");
  useEffect(() => {
    setData((prevData) => ({ ...prevData, service_Ids: selectedServices }));
  }, [selectedServices]);

  const get_slot = async () => {
    try {
      setIsLoading(true);
      let slots;
      if (data?.staff_Id) {
        slots = await apis.get_slots({
          id: storeInfo?._id,
          duration: sumOfDurations,
          staff_Id: data?.staff_Id,
        });
      } else {
        slots = await apis.get_slots_no_staff({
          id: storeInfo?._id,
          duration: sumOfDurations,
        });
      }
      setStoreSlots(slots?.data?.data);
      setIsLoading(false);
      setSelectedSlots(slots?.data?.data?.[0]);
      setData((prevData) => ({
        ...prevData,
        date: moment(slots?.data?.data?.[0]?.date?.completeDate).format(
          "YYYY-MM-DD"
        ),
      }));
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const { mutate, isLoading: isBooking } = useMutation(apis.create_booking, {
    onError: function ({ message }) {
      toast.error(message?.message, { id: 1 });
    },
    onSuccess: ({ data, status }) => {
      if (status === 201) {
        toast.success(data?.message, { id: 1 });
        setTimeout(() => {
          (async () => {
            await dispatch(reduxApis?.getCompleteInfo(storeInfo?._id));
          })();
        }, 2000);
        navigate(`/${user?.role}/booking`);
      }
    },
  });

  const { mutate: validateCoupon, isLoading: isValidating } = useMutation(
    apis.validateCoupon,
    {
      onError: function ({ message }) {
        toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data: user, status }) => {
        if (status === 200) {
          setCoupon(user?.coupon);
          toast.success(user?.message, { id: 1 });
        }
      },
    }
  );

  useEffect(() => {
    setData((prevData) => ({ ...prevData, time: "" }));
    // eslint-disable-next-line
  }, [selectedDate]);

  const BookSlot = (e) => {
    e.preventDefault();
    const {
      store_Id,
      staff_Id,
      booking_type,
      service_Ids,
      time,
      date,
      email,
      ...rest
    } = data;

    const response = errorValidate(BookingValidate(rest));
    if (response === true) {
      if (data?.advance_Amount > totalAmount) {
        toast.error("Advance amount should be less then total amount", {
          id: 1,
        });
      } else {
        const { email, staff_Id, ...rest } = data || {};
        mutate({
          ...(staff_Id !== "" && { staff_Id: data?.staff_Id }),
          ...(email !== "" && { email: data?.email }),
          ...(coupon !== null && { couponCode: coupon?.value }),
          ...rest,
        });
      }
    } else {
      setError(response);
    }
  };

  const [activeStep, setActiveStep] = useState(1);
  const [showBooking, setShowBooking] = useState(false);

  const updateStep = (newStep) => {
    setActiveStep(newStep);
  };



  return (
    <Layout>
      <div className="p-4 mt-4">
        {isLoading && <div id="cover-spin"></div>}
        <div className="d-flex flex-column flex-md-row ">
          <h3 className="mb-4 fw-bold w-max-content">Add Booking</h3>
          <div className="col-md-8 col-lg-5 width-100  mx-auto ">
            <StepBar activeStep={activeStep} />
          </div>
        </div>

        <form onSubmit={BookSlot}>
          <div className="row">
            <div className="col-lg-8 mb-5 mt-lg-4 py-2  rounded-3  bg-white">
              {activeStep === 1 && (
                <>
                  <h4 className="fw-bold my-3 text-center text-lg-start">
                    Select Service
                  </h4>
                  <BookingServiceTable
                    selectedServices={selectedServices}
                    services={services}
                    setSelectedServices={setSelectedServices}
                    get_slot={get_slot}
                  />
                </>
              )}

              {activeStep === 2 && (
                <>
                  <h4 className="fw-bold my-3 text-center text-lg-start">
                    Select professional
                  </h4>
                  <StaffSelection
                    selectedServices={selectedServices}
                    storeStaff={storeStaff}
                    selectedStaffs={selectedStaffs}
                    setSelectedStaffs={setSelectedStaffs}
                    services={services}
                    updateStep={updateStep}
                  />
                </>
              )}

              {activeStep === 3 && (
                <>
                  <h4 className="fw-bold my-3 text-center text-lg-start">
                    Select Time
                  </h4>
                  <DateScroll
                    get_slot={get_slot}
                    storeSlots={storeSlots}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    data={data}
                    selectdSlots={selectdSlots}
                    setSelectedSlots={setSelectedSlots}
                    setData={setData}
                  />
                </>
              )}

              {activeStep === 4 && window.innerWidth < 992 && (
                <div className="mb-2 d-lg-none">
                  <BookingCheckout
                    isPhone={true}
                    selectedStaffs={selectedStaffs}
                    isBooking={isBooking}
                    selectedServices={selectedServices}
                    updateStep={updateStep}
                    activeStep={activeStep}
                    data={data}
                    get_slot={get_slot}
                    BookSlot={BookSlot}
                    isValidating={isValidating}
                    validateCoupon={validateCoupon}
                    coupon={coupon}
                  />
                </div>
              )}

              {activeStep === 4 && window.innerWidth < 992 && <hr />}

              {activeStep === 4 && (
                <>
                  <h4 className="fw-bold my-3 text-center text-lg-start">
                    Customer details
                  </h4>

                  <div className="col-md-6  mx-auto bg-white p-3 rounded-3 mt-lg-5">
                    <div className="mb-3">
                      <label htmlFor="email" className="ms-2 mb-1">
                        Customer Name
                      </label>
                      <input
                        type="text"
                        name="names"
                        value={data?.name}
                        className="form-control"
                        onChange={(e) =>
                          setData((prevData) => ({
                            ...prevData,
                            name: e.target.value,
                          }))
                        }
                      />
                      {error?.name && (
                        <p className="text-danger">{error?.name}</p>
                      )}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="ms-2 mb-1">
                        Customer Email (optional)
                      </label>
                      <input
                        type="email"
                        name="names"
                        value={data?.email}
                        className="form-control"
                        onChange={(e) =>
                          setData((prevData) => ({
                            ...prevData,
                            email: e.target.value,
                          }))
                        }
                      />
                      {error?.email && (
                        <p className="text-danger">{error?.email}</p>
                      )}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="ms-2 mb-1">
                        Customer Phone
                      </label>
                      <PhoneInput
                        country={"pk"}
                        value={data?.customerPhone}
                        name="phone"
                        onlyCountries={["pk"]}
                        onChange={(value) =>
                          setData((prevData) => ({
                            ...prevData,
                            phone: value,
                          }))
                        }
                      />
                      {error?.phone && (
                        <p className="text-danger">{error?.phone}</p>
                      )}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="ms-2 mb-1">
                        Advance Payment
                      </label>
                      <input
                        type="number"
                        value={data?.advance_Amount}
                        className="form-control"
                        onChange={(e) =>
                          setData((prevData) => ({
                            ...prevData,
                            advance_Amount: e.target.value,
                          }))
                        }
                      />

                      {error?.advance_Amount && (
                        <p className="text-danger">{error?.advance_Amount}</p>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="col-lg-4 d-none d-lg-block sticky-div">
              <BookingCheckout
                isBooking={isBooking}
                isValidating={isValidating}
                selectedStaffs={selectedStaffs}
                validateCoupon={validateCoupon}
                coupon={coupon}
                selectedServices={selectedServices}
                updateStep={updateStep}
                activeStep={activeStep}
                data={data}
                get_slot={get_slot}
                BookSlot={BookSlot}
              />
            </div>
          </div>

          <div className="booking_footer">
            <div className="container">
              <div className="col-md-6 mx-auto">
                <div className="d-flex px-3 mt-2 w-100">
                  {activeStep !== 1 && (
                    <button
                      type="button"
                      className="login-btn me-2 px-2"
                      onClick={() => {
                        updateStep(activeStep - 1);
                      }}
                    >
                      Previous
                    </button>
                  )}
                  {activeStep < 4 ? (
                    <button
                      className="login-btn ms-2 px-2"
                      onClick={async () => {
                        if (activeStep === 1) {
                          if (selectedServices?.length > 0) {
                            updateStep(activeStep + 1);
                          } else {
                            toast.error("Please Select Service");
                          }
                        }
                        if (activeStep === 3) {
                          if (data?.time !== "") {
                            updateStep(activeStep + 1);
                            setShowBooking(true);
                          } else {
                            toast.error("Please Select Timeslot");
                          }
                        }
                        if (activeStep === 2) {
                          updateStep(activeStep + 1);
                        }
                      }}
                      type="button"
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      className="login-btn ms-2 px-2"
                      type="submit"
                      disabled={isBooking}
                    >
                      {isBooking ? <Spinner size="sm" /> : "Book"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default AddBooking;
