import React, { useEffect, useRef, useState } from "react";
import { IoIosSend } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

const ChatStaff = ({ socket, staffDetails, staffs, user,customerList }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showMessages, setShowMessages] = useState(true);
  const [selectedUser, setSelectedUser] = useState(staffDetails?.store_Id);
  const [chatWith, setChatWith] = useState("store_owner");
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  
  socket.on("receiveMessage", (data) => {
    setMessages(data.previousMessages || []);
  });

  useEffect(() => {
    setMessages([])
    socket.emit(
      "sendHistory",
      {
        receiver_Id:
          chatWith === "store_owner" ? selectedUser?.salon_owner_Id?._id : "",
        sender_Id: user?._id,
      },
      (data) => {
        setMessages(data?.previousMessages || []);
      }
    );
  }, [setSelectedUser]);
  const messagesEndRef = useRef(null);
  const sendMessage = () => {
    // Emit 'sendMessage' event to the server
    socket.emit(
      "sendMessage",
      {
        receiver_Id:
          chatWith === "store_owner" ? selectedUser?.salon_owner_Id?._id : "",
        sender_Id: user?._id,
        message: messageInput,
      },
      (ack) => {
        setMessages(ack?.previousMessages || []);
      }
    );

    // Clear the message input field
    setMessageInput("");
    messagesEndRef.current.scrollTo(0,0);

  };

  return (
    <>
      <div className="p-3">
        <div className="row clearfix mt-4">
          <div className="col-lg-12">
            <div className="chat-app bg-white rounded-3 w-100 d-flex">
              <div
                className={`people-list ${
                  windowWidth < 768 && !showMessages && "d-none"
                }`}
              >
                <div className="input-group p-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                  />
                </div>
                <div className="overflow-y-auto px-3">
                  <ul className="list-unstyled chat-list mt-2 mb-0">
                    <li
                      className={`clearfix d-flex align-items-center`}
                      onClick={() => {
                        setSelectedUser(staffDetails?.store_Id);
                        setChatWith("store_owner");
                      }}
                    >
                      <img
                        src={staffDetails?.store_Id?.image}
                        className="img-fluid chatUserListImg"
                        alt="avatar"
                      />
                      <div className="about">
                        <div className="name text-nowrap textEclips">
                          {staffDetails?.store_Id?.name}
                        </div>
                        <div className="status textEclips">
                         Salon Owner
                        </div>
                      </div>
                    </li>
                    {staffs?.map((staff, index) => (
                      <li
                        className={`clearfix d-flex align-items-center ${
                          chatWith === index && "active"
                        }`}
                        onClick={() => {
                          setSelectedUser(staff);
                          setChatWith(index);
                        }}
                        key={index}
                      >
                        <img
                          src={staff?.image}
                          className="img-fluid chatUserListImg"
                          alt="avatar"
                        />
                        <div className="about">
                          <div className="name textEclips">{staff?.name}</div>
                          <div className="status">
                            {/* <i className="fa fa-circle offline"></i> left 7 mins
                            ago{" "} */}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div
             id="message" ref={messagesEndRef}
                className={`chat ${
                  windowWidth < 768 && showMessages && "d-none"
                }`}
              >
                <div className="chat-header clearfix">
                  <div className="row">
                    <div className="col-lg-6 d-flex">
                      {windowWidth < 768 ? (
                        <IoIosArrowBack
                          onClick={() => setShowMessages(!showMessages)}
                          className="fs-1 hc mt-2 me-3"
                        />
                      ) : (
                        ""
                      )}
                      <div className="d-flex align-items-center">
                        <img
                          src={selectedUser?.image}
                          className="img-fluid object-fit-cover chatUserListImg"
                          alt="avatar"
                        />
                        <div className="chat-about">
                          <h6 className="m-b-0 mb-0 textEclips1">
                            {selectedUser?.name}
                          </h6>
                          <small className="textEclips1">Salon Owner</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="chat-history">
                  <ul className="m-b-0">
                    {messages?.map((message) => {
                      return (
                        <li className="clearfix">
                          <div
                            className={`message ${
                              message?.sender_Id === user?._id
                                ? "my-message float-right"
                                : "other-message"
                            }`}
                          >
                            {message?.message}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="chat-message clearfix">
                  <div className="position-relative">
                    <IoIosSend
                      type="button"
                      onClick={sendMessage}
                      className="sendIcon"
                    />
                    <input
                      type="text"
                      value={messageInput}
                      onChange={(e) => setMessageInput(e.target.value)}
                      className="form-control"
                      placeholder="Enter text here..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatStaff;
