import React from "react";
import ServiceTable from "../Table/ServiceTable";
import { Link } from "react-router-dom";

const SalonService = ({ services,user }) => {
  return (
    <div className="p-2">
      <div className="d-flex flex-wrap p-2 flex-wrap align-items-center w-100 justify-content-between">
        <h3 className="m-0 my-3 fw-bold ">Services</h3>
        {user?.role !=="admin" &&
        <div className="col-lg-2 col-md-3 col-sm-4">
          <Link to={`/${user?.role}/service/add`}>
            <button className="login-btn my-3 px-3 fw-bold">ADD</button>
          </Link>
        </div>}
      </div>

      <ServiceTable services={services} />
    </div>
  );
};

export default SalonService;
