import React, { useEffect, useRef, useState } from "react";
import { IoIosSend } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

const StoreCustomerChat = ({ socket, customers, user }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showMessages, setShowMessages] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(customers?.[0]);
  const [chatWith, setChatWith] = useState(0);
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const [imageError, setImageError] = useState([]);
  const chatHistoryRef = useRef(null);
  const handleImageError = (index) => {
    setImageError((prevErrors) => {
      let newErrors = [...prevErrors];
      newErrors[index] = true;
      return newErrors;
    });
  };

  socket.on("receiveMessage", (data) => {
    setMessages(data.previousMessages || []);
  });

  useEffect(() => {
    setMessages([])
    if (selectedCustomer?._id && user?._id) {
      socket.emit(
        "sendHistory",
        { receiver_Id: selectedCustomer?._id, sender_Id: user?._id },
        (data) => {
          setMessages(data?.previousMessages || []);
        }
      );
    }
  }, [selectedCustomer]);

  const sendMessage = (e) => {
    e.preventDefault();
    if (selectedCustomer?._id && user?._id) {
      socket.emit(
        "sendMessage",
        {
          receiver_Id: selectedCustomer?._id,
          sender_Id: user?._id,
          message: messageInput,
        },
        (ack) => {
          setMessages(ack?.previousMessages || []);
        }
      );
    }
    // Clear the message input field
    setMessageInput("");
  };

  const [customerName, setCustomerName] = useState("");

  const filterCustomer = () => {
    let customer;
    const lowercaseCustomerName = customerName.toLowerCase();
    customer = customers?.filter((e) =>
      e?.name?.toLowerCase()?.includes(lowercaseCustomerName)
    );
    return customer;
  };


  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <>
      <div className="p-3">
        <div className="row clearfix mt-4">
          <div className="col-lg-12">
            <div className="chat-app bg-white rounded-3 w-100 d-flex">
              <div
                className={`people-list overflow-hidden  ${
                  windowWidth < 768 && showMessages && "d-none"
                }`}
              >
                <div className="input-group p-3">
                  <input
                    type="text"
                    value={customerName}
                    onChange={(e) => setCustomerName(e.target.value)}
                    className="form-control"
                    placeholder="Search..."
                  />
                </div>
                <div className="overflow-y-auto px-3">
                  <ul className="list-unstyled chat-list mt-2 mb-0">
                    {filterCustomer()?.map((customer, index) => (
                      <li
                        className={`clearfix d-flex align-items-center ${
                          chatWith === index && "active"
                        }`}
                        onClick={() => {
                          setShowMessages(true);
                          setSelectedCustomer(customer);
                          setChatWith(index);
                        }}
                        key={index}
                      >
                        {customer?.image && (
                          <img
                            src={customer?.image}
                            className="img-fluid chatUserListImg"
                            alt="avatar"
                            style={{
                              display: imageError[index] ? "none" : "block",
                            }}
                            onError={() => handleImageError(index)}
                          />
                        )}
                        {(imageError[index] || !customer?.image) && (
                          <div className="alterWrapper text-uppercase ">
                            {customer?.name?.substring(0, 1)}
                          </div>
                        )}
                        <div className="about">
                          <div className="name textEclips">
                            {customer?.name}
                          </div>
                          <div className="status textEclips text-nowrap">
                            Customer
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div
                className={`chat ${
                  windowWidth < 768 && !showMessages && "d-none"
                }`}
              >
                <div className="chat-header clearfix">
                  <div className="row">
                    <div className="col-lg-6 d-flex">
                      {windowWidth < 768 ? (
                        <IoIosArrowBack
                          onClick={() => setShowMessages(!showMessages)}
                          className="fs-1 hc mt-2 me-3"
                        />
                      ) : (
                        ""
                      )}
                      {selectedCustomer?.image && (
                        <img
                          src={selectedCustomer?.image}
                          className="img-fluid object-fit-cover"
                          alt="avatar"
                          style={{
                            display: imageError[chatWith] ? "none" : "block",
                          }}
                        />
                      )}
                      {(imageError[chatWith] || !selectedCustomer?.image) && (
                        <div className="alterWrapper text-uppercase ">
                          {selectedCustomer?.name?.substring(0, 1)}
                        </div>
                      )}

                      <div className="chat-about">
                        <h6 className="m-b-0 mb-0 textEclips1">
                          {selectedCustomer?.name}
                        </h6>
                        <smal className="textEclips1">Customer</smal>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="chat-history" ref={chatHistoryRef}>
                  <ul className="m-b-0">
                    {messages?.map((message) => {
                      return (
                        <li className="clearfix">
                          <div
                            className={`message ${
                              message?.sender_Id === user?._id
                                ? "my-message float-right"
                                : "other-message"
                            }`}
                          >
                            {message?.message}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <form onSubmit={sendMessage}>
                  <div className="chat-message clearfix">
                    <div className="position-relative">
                      <IoIosSend type="button" className="sendIcon" />
                      <input
                        type="text"
                        value={messageInput}
                        onChange={(e) => setMessageInput(e.target.value)}
                        className="form-control"
                        placeholder="Enter text here..."
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StoreCustomerChat;
