import React from "react";
import TransactionsComp from "../components/transactions/TransactionsComp";
import Layout from "../components/layout/Layout";
import { useSelector } from "react-redux";
const Transactions = () => {
  const { transactions } = useSelector((e) => e?.user);
  return (
    <Layout>
      <TransactionsComp transactions={transactions} />
    </Layout>
  );
};

export default Transactions;
