import React from "react";
import Chart from "react-apexcharts";

const ColumnChart = ({ data }) => {
  const chartData = {
    series: [
      {
        name: "New Sales",
        data: data || [],
      },
      
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false, // Hide the toolbar
        }, // Change type to 'bar' for a column chart
        zoom: {
          enabled: false,
        },
      },

      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        labels: {
          show: true, // Hide x-axis labels
        },
      },
      yaxis: {
        show: true, // Hide y-axis
      },
      fill: {
        colors: ["#FF4081"], // Pink color
      },
      toolbar: {
        show: false, // Hide the download option
      },
    },
  };

  return (
    <div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={310}
      />
    </div>
  );
};

export default ColumnChart;
