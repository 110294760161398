import React, { useEffect, useState } from "react";
import moment from "moment";
import Paper from "@mui/material/Paper";
import { ViewState } from "@devexpress/dx-react-scheduler";
import BookingDetailsModal from "../Modal/BookingDetailsModal";
import {
  Scheduler,
  WeekView,
  DayView,
  MonthView,
  Appointments,
  Toolbar,
  DateNavigator,
  TodayButton,
} from "@devexpress/dx-react-scheduler-material-ui";

const SpecificStaffCalander = ({ calanderView, bookings }) => {
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    setCurrentDate(new Date());
  }, []);

  const [combinedAppointments, setCombinedAppointments] = useState([]);
  const [show, setShow] = useState(false);
  const [appointmentData, setAppointmentData] = useState({});

  useEffect(() => {
    const formattedAppointments = bookings?.map((book) => {
      let { date, time, end, user_Id, _id } = book || {};
      const startDate = moment(`${date} ${time}`, "DD MMMM YYYY hh:mmA");
      const endDate = moment(`${date} ${end}`, "DD MMMM YYYY hh:mmA");
      return {
        startDate: startDate.format("YYYY-MM-DDTHH:mm"),
        endDate: endDate.format("YYYY-MM-DDTHH:mm"),
        id: _id,
        title: user_Id?.name,
        // color: getRandomPinkColor(),
        rest: book,
      };
    });

    setCombinedAppointments(formattedAppointments);
  }, [bookings]);

  const CustomAppointment = ({ style, data, ...restProps }) => (
    <Appointments.Appointment
      {...restProps}
      // style={{ ...style, backgroundColor: data.color }}
      onClick={() => handleAppointmentClick(data)}
    />
  );

  const handleAppointmentClick = (appointmentId) => {
    setAppointmentData(appointmentId?.rest);
    setShow(true);
  };

  return (
    <>
      <BookingDetailsModal
        show={show}
        setShow={setShow}
        data={appointmentData}
        isCalander={true}
      />

      <Paper>
        <Scheduler data={combinedAppointments}>
          <ViewState
            currentDate={currentDate}
            onCurrentDateChange={(date) => setCurrentDate(date)}
          />
          {calanderView === "week" ? (
            <WeekView startDayHour={9} endDayHour={24} />
          ) : calanderView === "day" ? (
            <DayView startDayHour={9} endDayHour={24} />
          ) : (
            <MonthView startDayHour={9} endDayHour={24} />
          )}

          <Toolbar />
          <DateNavigator />
          <TodayButton />
          <Appointments appointmentComponent={CustomAppointment} />
        </Scheduler>
      </Paper>
    </>
  );
};

export default SpecificStaffCalander;
