import React, { useEffect } from "react";
import Layout from "../components/layout/Layout";
import SendNotification from "../components/nofitication/SendNotification";
import { setBookings, setStaffs } from "../redux/slices/userSlice";
import { useMutation } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import apis from "../services";

const SendNotifications = () => {
  const dispatch = useDispatch();
  const { user, storeInfo, staffDetails } = useSelector(
    (e) => e?.user
  );
  const { mutate: getBookings, isLoading: gettingBooking } = useMutation(
    () =>
      user?.role === "store"
        ? apis.getStoreBookings(storeInfo?._id)
        : user?.role === "staff"
        ? apis.getStaffBookings(staffDetails?._id)
        : null,
    {
      onError: function ({ message }) {
        console.log(message, "message");
        // toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          dispatch(setBookings(data?.booking));
        }
      },
    }
  );

  const { mutate: getStaff, isLoading } = useMutation(
    () =>
      apis.getStoreStaff({
        id: user?.role === "store" ? storeInfo?._id : user?._id,
        role: user?.role,
      }),
    {
      onError: function ({ message }) {
        // toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          dispatch(setStaffs(data?.staff));
        
        }
      },
    }
  );

 
  return (
    <Layout>
      {(gettingBooking || isLoading) && <div id="cover-spin"></div>}
      <SendNotification getStaff={getStaff} getBookings={getBookings} />
    </Layout>
  );
};

export default SendNotifications;
