import React from "react";
import TransactionTable from "../Table/TransactionTable";
import nodata from "../assets/no_data_found.webp";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { GiExpense } from "react-icons/gi";
import { GiMoneyStack } from "react-icons/gi";

const TransactionsComp = ({ transactions }) => {
  const earning_upto_date =
    transactions?.length > 0 &&
    transactions?.reduce((accumulator, expense) => {
      return accumulator + expense.amount;
    }, 0);

  return (
    <div className="p-3">
      <h3 className="fw-bold my-3">Transactions</h3>
      <div className="row text-uppercase">
        <div className="col-md-4 my-3">
          <div className="revenue__card d-flex justify-content-between  shadow p-3">
            <div>
              <h4 className="fw-bold">Balance available</h4>
              <h3 className="text-secondary">6500 PKR</h3>
            </div>
            <MdOutlineAccountBalanceWallet className="fs-1 text-secondary " />
          </div>
        </div>
        <div className="col-md-4 my-3">
          <div className="revenue__card d-flex justify-content-between  shadow p-3">
            <div>
              <h4 className="fw-bold">Earnings to date</h4>
              <h3 className="text-secondary">{earning_upto_date} PKR</h3>
            </div>
            <GiMoneyStack className="fs-1 text-secondary " />
          </div>
        </div>
        <div className="col-md-4 my-3">
          <div className="revenue__card d-flex justify-content-between  shadow p-3">
            <div>
              <h4 className="fw-bold">Expenses to date</h4>
              <h3 className="text-secondary">2500 PKR</h3>
            </div>

            <GiExpense className="fs-1 text-secondary " />
          </div>
        </div>
      </div>

      {transactions?.length > 0 ? (
        <TransactionTable transactions={transactions} />
      ) : (
        <div className="col-md-3 mx-auto my-4">
          <img
            src={nodata}
            className="img-fluid m-0 d-block mx-auto"
            alt="no data found"
          />
        </div>
      )}
    </div>
  );
};

export default TransactionsComp;
