import React, { useState } from "react";
import StaffDetail from "../components/staff/StaffDetail";
import Layout from "../components/layout/Layout";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const StaffDetails = () => {
  const { state } = useLocation();
  const { staffs } = useSelector((e) => e.user);
  const [staff] = staffs?.filter((e) => e?._id === state?.id);
  const [imageError, setImageError] = useState(false);
  const navigate = useNavigate();
  console.log(staff,"imageError")
  useEffect(() => {
    if (!state?.id) {
      navigate(-1);
    }
    // return ()=>{
    //   setImageError(false);
    // }
    // eslint-disable-next-line
  }, []);
  return (
    <Layout>
      <StaffDetail imageError={imageError} setImageError={setImageError} staff={staff} />
    </Layout>
  );
};

export default StaffDetails;
