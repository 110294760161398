import React, { useState, useEffect, useRef } from "react";
import apis from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { errorValidate } from "../../validations/errorHandleJoi";
import { serviceValidate } from "../../validations/ServiceValidation";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";
// import { TbPhotoEdit } from "react-icons/tb";
// import imageIcon from "../assets/imageIcon.png";
import AddCategoryModal from "../Modal/AddCategoryModal";
import {
  setCategory,
  setNoOfCategory,
  setNoOfServices,
  setServices,
} from "../../redux/slices/userSlice";
import { BeatLoader } from "react-spinners";

const AddServiceComp = () => {
  const { pathname, state } = useLocation();
  let service = state?.service;

  const dispatch = useDispatch();
  const { categories, user, storeID } = useSelector((e) => e.user);
  const isEdit = pathname?.includes("update");
  const navigate = useNavigate();
  const { storeInfo } = useSelector((e) => e?.user);
  const [showModal, setShowModal] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const filterCategory =
    categories?.filter((e) =>
      user?.role === "admin" ? (e?.store_Id?._id ? e?.store_Id?._id : e?.store_Id) === storeID : e
    ) || [];

    console.log(categories,"categories123")

  const { mutate: getCategories, isLoading } = useMutation(
    () =>
      apis.getServiceCategory({
        id: storeInfo?._id,
        role: user?.role,
      }),
    {
      onError: function ({ message }) {
        console.log(message, "message");
        // toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          dispatch(setCategory(data?.serviceCategories));
          dispatch(setNoOfCategory(data?.serviceCategories?.length || 0));
        }
      },
    }
  );

  let [data, setData] = useState({
    store_Id: user?.role === "admin" ? storeID : storeInfo?._id,
    segment_Id: "",
    service_category_Id: "",
    name: "",
    value: "",
    // noOfPeople: "",
    duration: "",
    description: "",
    image: "",
  });

  useEffect(() => {
    getCategories();
    if (service) {
      setData({
        store_Id:
          user?.role === "admin"
            ? service?.store_Id?._id
              ? service?.store_Id?._id
              : service?.store_Id
            : service?.store_Id,
        segment_Id: service?.segment_Id,
        service_category_Id: service?.service_category_Id?._id,
        name: service?.name,
        value: service?.value,
        // noOfPeople: service?.noOfPeople,
        duration: service?.duration,
        description: service?.description,
        image: service?.image,
      });
    }
    if (service?.image) {
      setImagePreview(service?.image);
    }
    // eslint-disable-next-line
  }, [service]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  let [error, setError] = useState();
  console.log(error, "error");
  const { mutate, isLoading: isSubmitting } = useMutation(apis.add_service, {
    onError: function ({ message }) {
      setError("");
      toast.error(message?.message, { id: 1 });
    },
    onSuccess: ({ data, status }) => {
      if (status === 201) {
        dispatch(setServices(data?.services));
        dispatch(setNoOfServices(data?.services?.length || 0));
        toast.success(data?.message, { id: 1 });
        // navigate(`/${user?.role}/service`);
        if (user?.role === "admin") {
          // console.log(data,"data0000000")
          navigate(-1, { state: { tab: "service" } });
        } else {
          navigate(`/${user?.role}/service`);
        }
      }
    },
  });

  const { mutate: updateService, isLoading: updateLoad } = useMutation(
    apis.update_Service,
    {
      onError: function ({ message }) {
        setError("");
        toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          dispatch(setServices(data?.services));
          dispatch(setNoOfServices(data?.services?.length || 0));
          toast.success(data?.message, { id: 1 });
          if (user?.role === "admin") {
            navigate(-1, { state: { tab: "service" } });
            // navigate(`/admin/stores/details/${storeInfo?.slug}`,{state:{tab:"service"}})
          } else {
            navigate(`/${user?.role}/service`);
          }
        }
      },
    }
  );

  const add_Service = (e) => {
    e.preventDefault();
    const form_data = new FormData();
    // for (const [key, value] of Object.entries(data)) {
    //   if (isEdit && key === "store_Id") {
    //     continue;
    //   }

    //   form_data.append(key, value);
    // }

    for (const [key, value] of Object.entries(data)) {
      if (isEdit && key === "store_Id") {
        continue;
      }
      if (key === "image" && value === "") {
        continue;
      }
      form_data.append(key, value);
    }

    let response = errorValidate(serviceValidate(data));
    if (response === true) {
      // if (data?.image) {
      if (isEdit) {
        updateService({ id: service?._id, body: form_data });
      } else {
        mutate(form_data);
      }
      // } else {
      //   toast.error("Please Select Image");
      // }
    } else {
      setError(response);
    }
  };

  const fileInputRef = useRef();

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleFileSelection = () => {
    const selectedFile = fileInputRef.current.files[0];
    setImagePreview(URL.createObjectURL(selectedFile));
    setData((prevData) => ({
      ...prevData,
      image: selectedFile,
    }));
  };

  const [category1,setCategory1]=useState()

  return (
    <div className="p-3">
      {isLoading && <div id="cover-spin"></div>}
      <AddCategoryModal
        category={category1}
        showModal={showModal}
        isEdit={false}
        setCategory1={setCategory1}
        setShowModal={setShowModal}
      />
      <input
        type="file"
        name="image"
        accept="image/png, image/gif, image/jpeg"
        onChange={handleFileSelection}
        ref={fileInputRef}
        className="d-none"
      />

      <div className="d-flex justify-content-between align-items-center">
        <h3 className="mb-4 fw-bold ">
          {" "}
          {isEdit ? "Update Service" : "Add Service"}
        </h3>
        <div className="col-md-4 col-lg-2 ">
          <button
            className="login-btn mb-4 fw-bold"
            onClick={() => setShowModal(true)}
          >
            ADD CATEGORY
          </button>
        </div>
      </div>
      <form onSubmit={add_Service} className="bg-white p-3 py-4 rounded-3 ">
        {/* <div className="profile_image_wrapper">
          <img
            src={imagePreview || imageIcon}
            onClick={triggerFileInput}
            className="img-fluid d-block hc mx-auto salonProfileImage shadow-sm"
            alt="cate"
          />
          <TbPhotoEdit
            onClick={triggerFileInput}
            className="text-center hc d-block mx-auto fs-1 mt-1 inputImage"
          />
        </div> */}
        <div className="row">
          <div className="col-sm-6 col-lg-4">
            <div className="mb-3">
              <label className="text-black ms-1">Service Name</label>
              <input
                type="text"
                name="name"
                id="name"
                value={data?.name}
                onChange={onChange}
                className="form-control"
              />
              {error?.name && (
                <div className="text-danger fs-12">{error?.name}</div>
              )}
            </div>
          </div>

          <div className="col-sm-6 col-lg-4">
            <div className="mb-3">
              <label className="text-black ms-1">Service Type</label>
              <select
                className="form-control"
                name="segment_Id"
                value={data?.segment_Id}
                onChange={onChange}
                id="segment_Id"
              >
                <option value="">Choose...</option>
                <option value={1}>Male</option>
                <option value={2}>Female</option>
                <option value={3}>Both</option>
              </select>
              {error?.segment_Id && (
                <div className="text-danger fs-12">{error?.segment_Id}</div>
              )}
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="mb-3">
              <label className="text-black ms-1">Service Category</label>
              <select
                className="form-control"
                name="service_category_Id"
                onChange={onChange}
                value={data?.service_category_Id}
              >
                <option value="">Choose...</option>

                {filterCategory?.length > 0 ? (
                  filterCategory?.map((cat, i) => {
                    return (
                      <option value={cat?._id} key={i}>
                        {cat?.name}
                      </option>
                    );
                  })
                ) : (
                  <option disabled>No Service Category Found</option>
                )}
              </select>
              {error?.service_category_Id && (
                <div className="text-danger fs-12">
                  {error?.service_category_Id}
                </div>
              )}
            </div>
          </div>

          <div className="col-sm-6 col-lg-4">
            <div className="mb-3">
              <label className="text-black ms-1">
                Duration <span className="text-secondary"> (in minutes)</span>{" "}
              </label>
              <input
                type="number"
                name="duration"
                value={data?.duration}
                id="duration"
                onChange={onChange}
                className="form-control"
              />
              {error?.duration && (
                <div className="text-danger fs-12">{error?.duration}</div>
              )}
            </div>
          </div>

          {/* <div className="col-sm-6 col-lg-4">
            <div className="mb-3">
              <label className="text-black ms-1">No Of Peoples</label>
              <input
                type="number"
                name="noOfPeople"
                id="noOfPeople"
                value={data?.noOfPeople}
                onChange={onChange}
                className="form-control"
              />
              {error?.noOfPeople && (
                <div className="text-danger fs-12">{error?.noOfPeople}</div>
              )}
            </div>
          </div> */}

          <div className="col-sm-6 col-lg-4">
            <div className="mb-3">
              <label className="text-black ms-1">Price</label>
              <input
                type="number"
                name="value"
                value={data?.value}
                id="value"
                onChange={onChange}
                className="form-control"
              />
              {error?.value && (
                <div className="text-danger fs-12">{error?.value}</div>
              )}
            </div>
          </div>

          <div className="col-sm-12 col-lg-4">
            <div className="mb-3">
              <label className="text-black ms-1">Description</label>
              <textarea
                name="description"
                value={data?.description}
                className="form-control rounded-4"
                onChange={onChange}
                rows="4"
              ></textarea>
              {error?.description && (
                <div className="text-danger fs-12">{error?.description}</div>
              )}
            </div>
          </div>
          <div className="col-sm-4 col-lg-3 mx-auto">
            <button
              className="login-btn fw-bold d-flex justify-content-center align-items-center"
              type="submit"
              disabled={isSubmitting || updateLoad}
            >
              {isSubmitting || updateLoad ? (
                // <Spinner />
                <BeatLoader size={15} color="#fff" />
              ) : isEdit ? (
                "UPDATE SERVICE"
              ) : (
                "ADD SERVICE"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddServiceComp;
