import React, { useEffect } from "react";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import apis from "../../services";
import { useMutation } from "@tanstack/react-query";
import { setBookings } from "../../redux/slices/userSlice";

const MarketingCustomers = ({
  handleCheckboxChange,
  selectedIds,
  // setSelectedIds,
}) => {
  const { bookings: users, storeInfo } = useSelector((e) => e?.user);
  const dispatch = useDispatch();
  const { mutate: getBookings, isLoading } = useMutation(
    () => apis.getStoreBookings(storeInfo?._id),
    {
      onError: function ({ message }) {
        console.log(message, "message");
        // toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          dispatch(setBookings(data?.booking));
        }
      },
    }
  );

  useEffect(() => {
    getBookings();
  }, []);

  return (
    <div class="table-responsive noti_table bg-white rounded-3 px-3 mt-5">
        {isLoading && <div id="cover-spin"></div>}
      <table class="table table-hover ">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {users?.length > 0 &&
            Array.from(new Set(users.map((user) => user?.user_Id?._id)))?.map(
              (userId) => {
                const user = users.find((u) => u?.user_Id?._id === userId);
                return (
                  <tr key={user?.user_Id?._id}>
                    <td>{user?.user_Id?.name}</td>

                    <td>
                      {selectedIds?.includes(user?.user_Id?._id) ? (
                        <ImCheckboxChecked
                          onClick={() =>
                            handleCheckboxChange(user?.user_Id?._id)
                          }
                          className="fs-4 text-pink"
                        />
                      ) : (
                        <ImCheckboxUnchecked
                          onClick={() =>
                            handleCheckboxChange(user?.user_Id?._id)
                          }
                          className="fs-4 text-pink"
                        />
                      )}
                    </td>
                  </tr>
                );
              }
            )}
        </tbody>
      </table>
    </div>
  );
};

export default MarketingCustomers;
