import React from "react";
import Layout from "../components/layout/Layout";
import { useSelector } from "react-redux";
import ChatStaff from "../components/chat/ChatStaff";

const Chat = ({ socket }) => {
  const { staffDetails, user } = useSelector((e) => e.user);

  return (
    <Layout>
      <ChatStaff staffDetails={staffDetails} user={user} socket={socket} />
    </Layout>
  );
};

export default Chat;
