import React, { useRef, useState } from "react";
// import { MdCardMembership } from "react-icons/md";
// import { RiCoupon2Line } from "react-icons/ri";
// import { FaAllergies } from "react-icons/fa";
import Cards from "./Cards";
// import { IoIosAddCircleOutline } from "react-icons/io";
import { IoChevronBackOutline } from "react-icons/io5";
import TemplateDesign from "./TemplateDesign";
import DiscountTemplate from "./DiscountTemplate";
import DealTemplate from "./DealTemplate";
import OfferTemplate from "./OfferTemplate";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import html2canvas from "html2canvas";
import apis from "../../services";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { setMarketing } from "../../redux/slices/userSlice";

const CreateTemplateComp = () => {
  const { storeInfo,user } = useSelector((e) => e?.user);
  // const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const divRef = useRef(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [mainData, setMainData] = useState({
    currentActive: "",
    step: 1,
    target: "all",
  });

  const captureAndSendImage = () => {
    const divElement = divRef.current;
    if (divElement) {
      setisLoading(true);
      html2canvas(divElement)
        .then((imageBlob) => {
          const form_data = new FormData();
          form_data.append("image", imageBlob.toDataURL("image/png"));
          selectedIds?.forEach((userId, index) => {
            form_data.append(`userIds[${index}]`, userId);
          });
          form_data.append("store_Id", storeInfo?._id);
          form_data.append("target", mainData?.target);
          return mutate(form_data);
        })
        .catch((error) => {
          setisLoading(false);
          console.error("Error capturing and sending image:", error);
        });
    }
  };

  const handleCheckboxChange = (id) => {
    // if (data?.target === "specific") {
    setSelectedIds((prevIds) => {
      if (prevIds.includes(id)) {
        return prevIds.filter((prevId) => prevId !== id);
      } else {
        return [...prevIds, id];
      }
    });
    // } else {
    //   setSelectedIds([id]);
    // }
  };

  const { mutate } = useMutation(apis.sendMarketingTemplate, {
    onError: function ({ message }) {
      toast.error(message?.message, { id: 1 });
      setisLoading(false);
    },
    onSuccess: ({ data, status }) => {
      if (status === 200) {
        navigate(`/${user?.role}/view-marketing-template`);
        dispatch(setMarketing(data?.storeMarketing));
        toast.success(data?.message, { id: 1 });
        setisLoading(false);
      }
    },
  });

  return (
    <div className="p-3">
      {mainData?.step > 1 && (
        <button
          className="backBtn d-flex align-items-center"
          onClick={() => setMainData((prev) => ({ ...prev, step: 1 }))}
        >
          <IoChevronBackOutline className="me-1" />
          Back
        </button>
      )}

      {mainData?.step === 1 && <Cards setMainData={setMainData} />}
      {mainData?.step === 2 && mainData?.currentActive === "Discount" && (
        <DiscountTemplate
          handleCheckboxChange={handleCheckboxChange}
          captureAndSendImage={captureAndSendImage}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          mainData={mainData}
          setMainData={setMainData}
          isLoading={isLoading}
          divRef={divRef}
        />
      )}
      {mainData?.step === 2 && mainData?.currentActive === "Deal" && (
        <DealTemplate
          handleCheckboxChange={handleCheckboxChange}
          captureAndSendImage={captureAndSendImage}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          mainData={mainData}
          setMainData={setMainData}
          isLoading={isLoading}
          divRef={divRef}
        />
      )}
      {mainData?.step === 2 && mainData?.currentActive === "offer" && (
        <OfferTemplate
          handleCheckboxChange={handleCheckboxChange}
          captureAndSendImage={captureAndSendImage}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          mainData={mainData}
          setMainData={setMainData}
          isLoading={isLoading}
          divRef={divRef}
        />
      )}
      {mainData?.step === 2 && mainData?.currentActive === "sale" && (
        <TemplateDesign
          handleCheckboxChange={handleCheckboxChange}
          captureAndSendImage={captureAndSendImage}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          mainData={mainData}
          setMainData={setMainData}
          isLoading={isLoading}
          divRef={divRef}
        />
      )}
    </div>
  );
};

export default CreateTemplateComp;
