import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { errorValidate } from "../../validations/errorHandleJoi";
import { validateNotification } from "../../validations/notificationValidation";
import toast from "react-hot-toast";
import { useMutation } from "@tanstack/react-query";
import apis from "../../services";
import { Spinner } from "react-bootstrap";
import { useRef } from "react";
import imageIcon from "../assets/imageIcon.png";
import { TbPhotoEdit } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";

const SendNotification = ({ getBookings, getStaff }) => {
  const {
    storeInfo,
    user,
    staffs,
    bookings: users,
  } = useSelector((e) => e?.user);
  const [selectedIds, setSelectedIds] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const navigate = useNavigate();

  const [data, setData] = useState({
    store_Id: storeInfo?._id,
    title: "",
    userIds: "",
    from: user?.role,
    type: "news",
    to: "",
    target: "",
    image: "",
  });

  useEffect(() => {
    if (data?.target === "Staffs" || data?.target === "Specific-Staff") {
      setData({ ...data, to: "staffs" });
      if (data?.target === "Specific-Staff") {
        getStaff();
      }
    }
    if (data?.target === "Users" || data?.target === "Specific-User") {
      setData({ ...data, to: "users" });
      if (data?.target === "Specific-User") {
        getBookings();
      }
    }
    setSelectedIds([]);
    // eslint-disable-next-line
  }, [data?.target]);

  useEffect(() => {
    setData({ ...data, userIds: selectedIds });
    // eslint-disable-next-line
  }, [selectedIds]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const imageUpload = useRef();
  const triggerFileInput = () => {
    imageUpload.current.click();
  };

  const handleFileSelection = () => {
    const selectedFile = imageUpload.current.files[0];
    setData({ ...data, image: selectedFile });
    setImagePreview(URL.createObjectURL(selectedFile));
  };

  const [error, setError] = useState();
  console.log(error, "error");

  const { mutate, isLoading: isSubmitting } = useMutation(
    apis.sendStaffNotification,
    {
      onError: function ({ message }) {
        setError("");
        toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          navigate(`/${user?.role}/notifications`);
          toast.success(data?.message, { id: 1 });
        }
      },
    }
  );

  const createNoti = (e) => {
    e.preventDefault();
    const { userIds, ...rest } = data;
    const form_data = new FormData();
    for (const [key, value] of Object.entries(rest)) {
      form_data.append(key, value);
    }
    selectedIds?.forEach((userId, index) => {
      form_data.append(`userIds[${index}]`, userId);
    });
    let response = errorValidate(validateNotification(data));
    if (response === true) {
      mutate({ id: storeInfo?._id, body: form_data });
    } else {
      setError(response);
    }
  };

  const handleCheckboxChange = (id) => {
    if (data?.target === "Specific-Staff" || data?.target === "Specific-User") {
      setSelectedIds((prevIds) => {
        if (prevIds.includes(id)) {
          return prevIds.filter((prevId) => prevId !== id);
        } else {
          return [...prevIds, id];
        }
      });
    } else {
      setSelectedIds([id]);
    }
  };

  return (
    <div className="p-3">
      <h3 className="fw-bold">SEND NOTIFICATION</h3>
      <div className="bg-white p-3 py-md-5  rounded-3">
        <form onSubmit={createNoti}>
          <input
            type="file"
            name="image"
            accept="image/png, image/gif, image/jpeg"
            onChange={handleFileSelection}
            ref={imageUpload}
            className="d-none"
          />

          <div className="profile_image_wrapper">
            <img
              src={imagePreview || imageIcon}
              onClick={triggerFileInput}
              className="img-fluid d-block hc mx-auto salonProfileImage"
              alt="cate"
            />
            <TbPhotoEdit
              onClick={triggerFileInput}
              className="text-center hc d-block mx-auto fs-1 mt-1 inputImage"
            />
            <p className="m-0 text-center">Image (optional)</p>
          </div>

          <div className="row mt-5 ">
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="" className="ms-1">
                  Title
                </label>
                <input
                  type="text"
                  name="title"
                  onChange={onChange}
                  className="form-control"
                />
                {error?.title && (
                  <p className="text-danger m-0">{error?.title}</p>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="text-black">Type</label>
                <select
                  name="type"
                  value={data.type}
                  onChange={onChange}
                  className="form-control form-control-lg pe-2"
                >
                  <option value="news">News</option>
                  <option value="offer">Offer</option>
                </select>
                {error?.type && (
                  <p className="text-danger m-0">{error?.type}</p>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="" className="ms-1">
                  Message
                </label>
                <textarea
                  className="form-control rounded-3"
                  value={data.body}
                  onChange={onChange}
                  name="body"
                  rows="3"
                ></textarea>
                {error?.body && (
                  <p className="text-danger m-0">{error?.body}</p>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="text-black">Select Target</label>
                <select
                  name="target"
                  id="target"
                  value={data.target}
                  onChange={onChange}
                  className="form-control form-control-lg pe-2"
                >
                  <option selected>Select target</option>
                  <option value="Users">All Customers</option>
                  {user?.role === "store" && (
                    <option value="Staffs">All Staff</option>
                  )}
                  <option value="Specific-User">Specific Customer</option>
                  {user?.role === "store" && (
                    <option value="Specific-Staff">Specific Staff</option>
                  )}
                </select>
                {error?.target && (
                  <p className="text-danger m-0">{error?.target}</p>
                )}
              </div>
            </div>
            {data?.target === "Specific-Staff" && (
              <div class="table-responsive noti_table bg-white rounded-3 px-3 mt-5">
                <table class="table table-hover ">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Designation</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {staffs?.length > 0 &&
                      staffs?.map((staff) => {
                        return (
                          <tr>
                            <td>{staff?.name}</td>
                            <td>{staff?.title}</td>
                            <td>
                              {/* <input
                                type="checkbox"
                                className="checkbox"
                                checked={selectedIds.includes(staff._id)}
                                onChange={() => handleCheckboxChange(staff._id)}
                              /> */}

                              {selectedIds.includes(
                                staff?.salon_staff_Id?._id
                              ) ? (
                                <ImCheckboxChecked
                                  onClick={() =>
                                    handleCheckboxChange(
                                      staff?.salon_staff_Id?._id
                                    )
                                  }
                                  className="fs-4 text-pink"
                                />
                              ) : (
                                <ImCheckboxUnchecked
                                  onClick={() =>
                                    handleCheckboxChange(
                                      staff?.salon_staff_Id?._id
                                    )
                                  }
                                  className="fs-4 text-pink"
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}
            {data?.target === "Specific-User" && (
              <div class="table-responsive noti_table bg-white rounded-3 px-3 mt-5">
                <table class="table table-hover ">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Gender</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users?.length > 0 &&
                      Array.from(
                        new Set(users.map((user) => user?.user_Id?._id))
                      )?.map((userId) => {
                        const user = users.find(
                          (u) => u?.user_Id?._id === userId
                        );
                        return (
                          <tr key={user?.user_Id?._id}>
                            <td>{user?.user_Id?.name}</td>
                            <td>{user?.user_Id?.gender}</td>
                            <td>
                              {/* <input
                                type="checkbox"
                                className="checkbox"
                                checked={selectedIds.includes(
                                  user?.user_Id?._id
                                )}
                                onChange={() =>
                                  handleCheckboxChange(user?.user_Id?._id)
                                }
                              /> */}
                              {selectedIds.includes(user?.user_Id?._id) ? (
                                <ImCheckboxChecked
                                  onClick={() =>
                                    handleCheckboxChange(user?.user_Id?._id)
                                  }
                                  className="fs-4 text-pink"
                                />
                              ) : (
                                <ImCheckboxUnchecked
                                  onClick={() =>
                                    handleCheckboxChange(user?.user_Id?._id)
                                  }
                                  className="fs-4 text-pink"
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}
            <div className="col-lg-3 col-md-4 col-sm-10 mx-auto mt-3">
              <button className="login-btn mt-2" type="submit">
                {isSubmitting ? <Spinner /> : " SEND NOTIFICATION"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SendNotification;
