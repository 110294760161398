import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import CategoryTable from "../components/Table/CategoryTable";
import AddCategoryModal from "../components/Modal/AddCategoryModal";
import { setCategory, setNoOfCategory } from "../redux/slices/userSlice";
import apis from "../services";
import { useMutation } from "@tanstack/react-query";

const Category = () => {
  const { categories, storeInfo, user } = useSelector((e) => e?.user);
  const [showModal, setShowModal] = useState(false);
  const [category, setCategory1] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();
  console.log(category, "category");
  const updating = async (data) => {
    await setCategory1(data);
    setShowModal(true);
    setIsEdit(true);
  };

  const { mutate: getCategories, isLoading } = useMutation(
    () =>
      apis.getServiceCategory({
        id: user?.role === "store" ? storeInfo?._id : user?._id,
        role: user?.role,
      }),
    {
      onError: function ({ message }) {
        console.log(message, "message");
        // toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          dispatch(setCategory(data?.serviceCategories));
          dispatch(setNoOfCategory(data?.serviceCategories?.length || 0));
        }
      },
    }
  );

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <Layout>
      {isLoading && <div id="cover-spin"></div>}
      <AddCategoryModal
        category={category}
        showModal={showModal}
        isEdit={isEdit}
        setCategory1={setCategory1}
        setShowModal={setShowModal}
      />
      <div className="p-3">
        <div className="d-flex flex-wrap flex-wrap align-items-center w-100 justify-content-between">
          <h3 className="m-0 my-3 fw-bold ">Service Category</h3>
          <div className="col-lg-2 col-md-3 col-sm-4">
            <button
              className="login-btn my-3 fw-bold"
              onClick={() => {
                setIsEdit(false);
                setShowModal(true);
              }}
            >
              ADD CATEGORY
            </button>
          </div>
        </div>
        <CategoryTable
          role={user?.role}
          updating={updating}
          categories={categories}
        />
      </div>
    </Layout>
  );
};

export default Category;
