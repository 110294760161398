import React, { useState } from "react";

const ReferralTable = ({ referral }) => {
  const itemsPerPage = 10; // Define number of items per page
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = referral?.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const totalPages = Math.ceil(referral?.length / itemsPerPage);

  return (
    <div class="p-3 mt-md-5">
      <div class="table-responsive bg-white rounded-3">
        <table class="table table-hover ">
          <thead className="table-secondary">
            <tr>
              <th scope="col">Refered By</th>
              <th scope="col">Referral User</th>
              <th scope="col">Role</th>
              <th scope="col">Rewarded Amount</th>
              <th scope="col">Level</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {currentItems?.length > 0 ? (
              currentItems?.map((ref, i) => {
                return (
                  <tr key={i}>
                    <td>{ref?.from_referral_userId?.name}</td>
                    <td>{ref?.to_referral_userId?.name}</td>
                    <td>{ref?.to_referral_userId?.role}</td>
                    <td>{ref?.rewarded_amount}</td>
                    <td>{ref?.rewarded_level}</td>
                    <td>{ref?.status}</td>
                  </tr>
                );
              })
            ) : (
              <td colSpan={6} className="text-center py-5">No data found</td>
            )}
          </tbody>
        </table>
        {referral?.length > 0 && (
          <div className="w-90 position-absolute bottom-0 start-50 translate-middle-x mb-3">
            <div className="row align-items-center text-center text-md-start">
              <div className="col-sm-6 ms-auto col-xs-6">
                <div className="d-flex align-items-center justify-content-center">
                  <button
                    className="pagination_btn"
                    onClick={() =>
                      setCurrentPage(
                        currentPage > 1 ? currentPage - 1 : currentPage
                      )
                    }
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  {[...Array(totalPages)].map((_, i) => (
                    <div
                      key={i}
                      className={`pageNo ${
                        i + 1 === currentPage ? "pageNo_active" : ""
                      }`}
                      onClick={() => paginate(i + 1)}
                    >
                      {i + 1}
                    </div>
                  ))}
                  <button
                    className="pagination_btn"
                    onClick={() =>
                      setCurrentPage(
                        currentPage < totalPages ? currentPage + 1 : currentPage
                      )
                    }
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReferralTable;
