// // SalonMap.js
// import React, { useState } from "react";
// import GooglePlacesAutocomplete from "react-google-places-autocomplete";

// const SalonMap = () => {
//   const [selectedLocation, setSelectedLocation] = useState(null);

//   const handleSelect = (place) => {
//     // Extract latitude and longitude from the selected place
//     const { lat, lng } = place?.geometry?.location;
//     console.log(place,"place");
//     // Save the selected location in state
//     setSelectedLocation({
//       name: place.label,
//       latitude: lat(),
//       longitude: lng(),
//     });
//   };

//   // console.log(selectedLocation,"selectedLocation")
//   return (
//     <>
//       <GooglePlacesAutocomplete
//       autocompletionRequest={{
//         componentRestrictions: {
//           country: ["pk"],
//         },
//       }}
//       apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
//       />

//       <div>
//         <GooglePlacesAutocomplete
//           onSelect={handleSelect}
//           autocompletionRequest={{
//             componentRestrictions: {
//               country: ["pk"],
//             },
//           }}
//           apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
//         />
//         <h1>selected location</h1>

//         {selectedLocation && (
//           <div>
//             <h2>Selected Location</h2>
//             <p>Name: {selectedLocation.name}</p>
//             <p>Latitude: {selectedLocation.latitude}</p>
//             <p>Longitude: {selectedLocation.longitude}</p>
//           </div>
//         )}
//       </div>
//     </>
//   );
// };

// export default SalonMap;

import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
const SalonMap = ({ data, setData }) => {
  // const [address, setAddress] = useState("");
  // console.log(address,"address")

  const handleChange = (newAddress) => {
    setData((prev) => ({
      ...prev,
      longitude:"",
      latitude:"",
      location: newAddress,
    }));
  };

  // // useEffect(()=>{

  // // },[address])

  const handleSelect = (newAddress) => {
    geocodeByAddress(newAddress)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setData((prev) => ({
          ...prev,
          location: newAddress,
          latitude: latLng?.lat,
          longitude: latLng?.lng,
        }));
        // setAddress(newAddress);
      })
      .catch((error) => console.error("Error", error));
  };

  return (
    <>
      <PlacesAutocomplete
        autocompletionRequest={{
          componentRestrictions: {
            country: ["pk"],
            city: ["Karachi"],
          },
        }}
        value={data?.location}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: "Search Places ...",
                className: "location-search-input form-control",
              })}
            />
            <div className="autocomplete-dropdown-container shadow-sm ">
              {loading && <div>Loading...</div>}
              {suggestions?.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </>
  );
};

export default SalonMap;
