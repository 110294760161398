import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";

const CheckInModal = ({
  staff_Id,
  setStaff_Id,
  booking,
  collectedAmount,
  show,
  setShow,
  setCollectedAmount,
  Function,
  loading,
}) => {
  const [error, setError] = useState("");
  const [staffError, setStaffError] = useState(null);
  const { staffs } = useSelector((e) => e?.user);
  let remaningAmount = booking?.amount - booking?.advance_Amount || 0;
  const handleClose = () => {
    setError("");
    setStaff_Id("");
    setShow(false);
  };

  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Header closeButton className="bg-white">
        <Modal.Title>Do You want to CheckIn?</Modal.Title>
      </Modal.Header>

      <Modal.Body closeButton className="bg-white">
        <input
          type="text"
          value={`Remaning amount ${
            booking?.amount - booking?.advance_Amount || 0
          } PKR`}
          disabled
          className="form-control my-2"
        />
        {booking?.amount - booking?.advance_Amount > 0 && (
          <>
            <label htmlFor="collectedAmount" className="ms-2">
              Collected amount
            </label>
            <input
              type="number"
              onChange={(e) => {
                setCollectedAmount(e?.target?.value);
              }}
              min={0}
              className="form-control mt-2"
            />
            {error && <p className="text-danger">{error}</p>}
          </>
        )}
        {!booking?.salon_staff_Id && (
          <select
            className="form-control mt-3"
            onChange={(e) => {
              setStaff_Id(e?.target?.value);
              if (e) {
                setStaffError("");
              }
            }}
          >
            <option value="" disabled selected>
              Select Staff
            </option>
            {staffs?.length > 0 &&
              staffs?.map((staff) => (
                <option value={staff._id}>{staff.name}</option>
              ))}
          </select>
        )}
        {staffError && <p className="text-danger">{staffError}</p>}
      </Modal.Body>

      <Modal.Footer className="bg-white">
        <div className="d-flex">
          <button
            className="login-btn border-0 me-2 bg-secondary text-white  px-4"
            onClick={() => setShow(false)}
          >
            Cancel
          </button>
          <button
            className="login-btn text-white border-0  px-4 d-flex justify-content-center align-items-center"
            onClick={() => {
              if (remaningAmount === 0) {
                setError("");
                Function();
              } else if (
                collectedAmount > remaningAmount ||
                collectedAmount < remaningAmount
              ) {
                setError(`Amount should be equal to ${remaningAmount} PKR`);
              } else if (!booking?.salon_staff_Id && !staff_Id) {
                setStaffError(`Please Select Staff`);
              } else {
                setError("");
                Function();
              }
            }}
          >
            {!loading ? "CheckIn" : 
            // <Spinner animation="border" />
            <BeatLoader size={13} color="#fff" />
            
            }
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CheckInModal;
