import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../components/layout/Layout";
import CustomerTable from "../components/Table/CustomerTable";
import toast from "react-hot-toast";
import apis from "../services";
import { useMutation } from "@tanstack/react-query";
import { setBookings } from "../redux/slices/userSlice";

const Customer = () => {
  const { bookings, users, storeInfo, user } = useSelector((e) => e?.user);
  const [currect, setCurrent] = useState("customers");
  const dispatch = useDispatch();
  const customerList = () => {
    let list;
    if (currect === "customers") {
      list =
        bookings?.length > 0 &&
        Array.from(
          new Map(
            bookings.map((user) => [user?.user_Id?._id, user?.user_Id])
          ).values()
        );
      return list;
    } else {
      list =
        users?.length > 0 && Array.from(new Set(users.map((user) => user?.id)));
      return list;
    }
  };

  const { mutate: getBookings, isLoading } = useMutation(
    () =>
      user?.role === "admin"
        ? apis?.getAllBookings()
        : apis?.getStoreBookings(storeInfo?._id),
    {
      onError: function ({ message }) {
        console.log(message, "message");
        // toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          dispatch(setBookings(data?.booking));
        }
      },
    }
  );

  useEffect(() => {
    getBookings();
  }, []);

  return (
    <Layout>
      <div className="p-3">
        {isLoading && <div id="cover-spin"></div>}
        <h3 className="fw-bold">Customers</h3>
        <CustomerTable customers={customerList()} />
      </div>
    </Layout>
  );
};

export default Customer;
