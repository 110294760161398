import React, { useState } from "react";
import Layout from "../components/layout/Layout";
import { useSelector } from "react-redux";
import ImageModal from "../components/Modal/ImageModal";
import { Link } from "react-router-dom";

const Gallery = () => {
  const { gallery } = useSelector((e) => e?.user?.storeInfo);
  const [show, setShow] = useState(false);
  const [imageAddress, setImageAddress] = useState(null);

  return (
    <Layout>
      <div className="p-3">
        <ImageModal imageAddress={imageAddress} show={show} setShow={setShow} />
        <div className="d-flex align-items-center justify-content-between ">
          <h3 className="text-center fw-bold m-0">Gallery</h3>
          <div className=" col-md-5 col-lg-2">
            <Link to="/store/gallery/upload">
              <button className="login-btn h-auto px-3 py-1 fw-bold">
                Upload Gallery
              </button>
            </Link>
          </div>
        </div>
        <div className="row align-items-center">
          {gallery?.length > 0 &&
            gallery?.map((e, i) => {
              return (
                <div className="col-lg-4 my-3" key={i}>
                  <img
                    src={e}
                    className="gallery_image hc d-block mx-auto"
                    onClick={() => {
                      setShow(!show);
                      setImageAddress(e);
                    }}
                    alt=""
                  />
                </div>
              );
            })}
        </div>
      </div>
    </Layout>
  );
};

export default Gallery;
