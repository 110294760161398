import React, { useState } from "react";
import apis from "../../services";
import toast from "react-hot-toast";
import { MdDeleteForever } from "react-icons/md";
import { useDispatch } from "react-redux";
import DeleteModal from "../Modal/DeleteModal";
import { setCoupons } from "../../redux/slices/userSlice";
import moment from "moment";
// delete_coupon
const CouponTable = ({ coupons }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState();
  const [show, setShow] = useState(false);
  const itemsPerPage = 10; // Define number of items per page
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = coupons?.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(coupons?.length / itemsPerPage);

  const showModal = (id) => {
    setId(id);
    setShow(true);
  };

  const deleteCoupon = async () => {
    if (id) {
      try {
        setIsLoading(true);
        const response = await apis.delete_coupon(id);
        toast.success(response?.data?.message, { id: 1 });
        setIsLoading(false);
        setShow(false);
        dispatch(setCoupons(response?.data?.coupons));
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    }
  };

  return (
    <div class="">
      <DeleteModal
        deleteFunction={deleteCoupon}
        show={show}
        setShow={setShow}
        loading={isLoading}
      />
      <div class="table-responsive bg-white rounded-3 mt-2">
        <table class="table table-hover ">
          <thead className="table-secondary">
            <tr>
              <th scope="col">Target</th>
              <th scope="col">Quantity</th>
              <th scope="col">Total Amount</th>
              <th scope="col">Type</th>
              <th scope="col">Valid upto</th>
              <th scope="col">Code</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems?.length > 0 ? (
              currentItems.map((coupon, i) => {
                return (
                  <tr key={i}>
                    <td>{coupon?.target}</td>
                    <td>{coupon?.quantity}</td>
                    <td>{coupon?.totalAmount}</td>
                    <td>
                      {coupon?.type?.fixedAmount
                        ? `Fixed Amount ${coupon?.type?.fixedAmount} PKR`
                        : coupon?.type?.percentage
                        ? `Percentage ${coupon?.type?.percentage}%`
                        : ""}
                    </td>
                    <td>
                      {moment(coupon?.validityDate).format("DD MMM YYYY")}
                    </td>
                    <td>
                      {coupon?.value}
                    </td>

                    <td>
                      <MdDeleteForever
                        className="fs-3 text-danger hc"
                        onClick={() => showModal(coupon?._id)}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <td colSpan={5} className="text-center py-5">
                No coupons found
              </td>
            )}
          </tbody>
        </table>
        {coupons?.length > 0 && (
          <div className="w-90 position-absolute bottom-0 start-50 translate-middle-x mb-3">
            <div className="row align-items-center text-center text-md-start">
              <div className="col-sm-6 ms-auto col-xs-6">
                <div className="d-flex align-items-center justify-content-center">
                  <button
                    className="pagination_btn"
                    onClick={() =>
                      setCurrentPage(
                        currentPage > 1 ? currentPage - 1 : currentPage
                      )
                    }
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  {[...Array(totalPages)].map((_, i) => (
                    <div
                      key={i}
                      className={`pageNo ${
                        i + 1 === currentPage ? "pageNo_active" : ""
                      }`}
                      onClick={() => paginate(i + 1)}
                    >
                      {i + 1}
                    </div>
                  ))}
                  <button
                    className="pagination_btn"
                    onClick={() =>
                      setCurrentPage(
                        currentPage < totalPages ? currentPage + 1 : currentPage
                      )
                    }
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CouponTable;
