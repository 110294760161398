import React, { useEffect, useState } from "react";
import ReferalComp from "../components/referal/ReferalComp";
import Layout from "../components/layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import ReferralTable from "../components/Table/ReferralTable";
import {setReferral } from "../redux/slices/userSlice";
import apis from "../services";
import { useMutation } from "@tanstack/react-query";

const Referal = () => {
  const { referrals, user } = useSelector((e) => e?.user);
  const dispatch = useDispatch();
  const [totalReferral, setTotalReferral] = useState(false);

  const { mutate: getReferrals, isLoading } = useMutation(
    () =>
      user?.role === "store"
        ? apis.getStoreReferral(user?._id)
        : user?.role === "staff"
        ? apis.getStoreReferral(user?._id)
        : "",
    {
      onError: function ({ message }) {
        console.log(message, "message");
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          setTotalReferral(data?.totalReferral);
          dispatch(setReferral(data?.referral));
        }
      },
    }
  );

  useEffect(() => {
    getReferrals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout>
      {isLoading && <div id="cover-spin"></div>}
      <ReferalComp totalReferral={totalReferral} referral={referrals || []} />
      <ReferralTable referral={referrals || []} />
    </Layout>
  );
};

export default Referal;
