import React from "react";
import Layout from "../components/layout/Layout";
import CreateTemplateComp from "../components/marketing/CreateTemplateComp";

const CreateTemplate = () => {
  return (
    <Layout>
      <CreateTemplateComp />
    </Layout>
  );
};

export default CreateTemplate;
