import React from "react";
import Layout from "../components/layout/Layout";
import AddStaff from "../components/staff/AddStaff";
import { Tab, Tabs } from "react-bootstrap";
import { useState } from "react";
import StaffTime from "../components/staff/StaffTime";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const AddSalonStaff = () => {
  const { pathname } = useLocation();
  const { state } = useLocation();
  const isEdit = pathname.includes("update");
  const { staffs } = useSelector((e) => e.user);
  const [staff_details] =
    staffs?.length > 0 && state?.id
      ? staffs?.filter((e) => e._id === state?.id)
      : [];
  const [staff_id, setStaff_id] = useState(null);
  const [key, setKey] = useState("info");
  const staffIdExists = staff_id !== null;

  useEffect(() => {
    if (staffIdExists) {
      setKey("timing"); // Open the "Staff Timing" tab
    } else {
      setKey("info"); // Open the "Staff Information" tab
    }
  }, [staffIdExists]);

  return (
    <Layout>
      <div className="p-3">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab
            eventKey="info"
            title="Staff Information"
            disabled={staffIdExists}
          >
            <AddStaff
              isEdit={isEdit}
              staff_details={staff_details}
              setStaff_id={setStaff_id}
            />
          </Tab>
          <Tab
            eventKey="timing"
            title="Staff Timing"
            disabled={!staffIdExists && !isEdit}
          >
            <StaffTime
              isEdit={isEdit}
              staff_details={staff_details}
              staff_id={staff_id}
              type="staff"
            />
          </Tab>
        </Tabs>
      </div>
    </Layout>
  );
};

export default AddSalonStaff;
