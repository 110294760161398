import { useMutation } from "@tanstack/react-query";
import moment from "moment";
import React, { useState } from "react";
import apis from "../../services";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { setStoreInfo } from "../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";

const SalonTime = ({ progress, isEdit, setShowModal }) => {
  const { storeInfo, user } = useSelector((e) => e?.user);
  let store_timings = storeInfo?.store_timings;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const initialTimeState = days?.map((day, index) => ({
    day: day,
    from: store_timings?.[index]?.from || "10:00AM",
    to: store_timings?.[index]?.to || "10:00PM",
    isAvailable:
      store_timings?.[index]?.isAvailable || true,
  }));

  const [time, setTime] = useState(isEdit ? store_timings : initialTimeState);

  const handleTimeChange = (field, value, dayIndex, timeType) => {
    setTime((prevStoreTimings) => {
      const updatedStoreTimings = [...prevStoreTimings];

      updatedStoreTimings[dayIndex] = {
        ...updatedStoreTimings[dayIndex],
        [field]: value,
      };
      if (timeType === "from") {
        const [hours, minutes] =
          updatedStoreTimings[dayIndex]?.from?.split(":") || "";
        let newPeriod = minutes?.slice(-2);
        let newhour = hours;
        let newMinute = minutes?.slice(0, -2);
        if (field === "period") {
          newPeriod = value;
        } else if (field === "hour") {
          newhour = value;
        } else if (field === "minute") {
          newMinute = value;
        }

        let fromTime = moment(
          `${newhour}:${newMinute} ${newPeriod}`,
          "hh:mm A"
        ).format("hh:mmA");

        updatedStoreTimings[dayIndex] = {
          ...updatedStoreTimings[dayIndex],
          from: fromTime,
        };
      } else if (timeType === "to") {
        const [hours, minutes] =
          updatedStoreTimings[dayIndex]?.to?.split(":") || "";
        let newPeriod = minutes?.slice(-2);
        let newhour = hours;
        let newMinute = minutes?.slice(0, -2);
        if (field === "period") {
          newPeriod = value;
        } else if (field === "hour") {
          newhour = value;
        } else if (field === "minute") {
          newMinute = value;
        }

        const ToTime = moment(
          `${newhour}:${newMinute} ${newPeriod}`,
          "hh:mm A"
        ).format("hh:mmA");

        const endTime = moment(updatedStoreTimings[dayIndex].from, "h:mma");
        const closingTime = moment(ToTime, "h:mma");
        const isBefore = closingTime.isBefore(endTime);
        if (isBefore) {
          toast.error(
            `${days[dayIndex]} end time must be greater then ${store_timings[dayIndex].from}`,
            { id: 1 }
          );
        } else {
          updatedStoreTimings[dayIndex] = {
            ...updatedStoreTimings[dayIndex],
            to: ToTime,
          };
        }
      }
      return updatedStoreTimings;
    });
  };

  const formattedStoreTimings = time?.map((day) => ({
    day: day.day,
    from: day.from,
    to: day.to,
    isAvailable: day.isAvailable,
  }));

  const { mutate, isLoading } = useMutation(
    () =>
      apis.updateStore({
        id: storeInfo?._id,
        body: {
          store_timings: formattedStoreTimings,
          completeProgess: progress || 2,
        },
      }),
    {
      onError: function ({ message }) {
        toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          if (!isEdit) {
            setShowModal(false);
            navigate(`/${user?.role}/staff/add`);
          }
          dispatch(setStoreInfo(data?.store));
          toast.success(data?.message, { id: 1 });
        }
      },
    }
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    if (time) {
      mutate();
    }
  };

  return (
    <>
      <h3 className="fw-bold text-uppercase mb-3 pt-3">Salon Timing</h3>
      <div
        className={`${
          !isEdit ? "container" : ""
        } time__container bg-white p-3 rounded-3`}
      >
        <form onSubmit={handleSubmit}>
          {time?.map((day, index) => (
            <div
              className="d-flex justify-content-md-center  align-items-center mb-2"
              key={index}
            >
              <span className="m-0 week__day__name">{day?.day}</span>
              <div className="d-flex flex-column ">
                <label className="ms-2">Opening Time</label>
                <div className="d-flex">
                  <select
                    className="time__input mx-1"
                    value={moment(day?.from, "hh:mm A").format("hh")}
                    onChange={(e) =>
                      handleTimeChange("hour", e.target.value, index, "from")
                    }
                    defaultValue="10"
                  >
                    {Array.from({ length: 12 }, (_, i) =>
                      (i + 1).toString().padStart(2, "0")
                    ).map((hour) => (
                      <option key={hour} value={hour}>
                        {hour}
                      </option>
                    ))}
                  </select>

                  <select
                    className="time__input mx-1"
                    value={moment(day?.from, "hh:mm A").format("mm")}
                    onChange={(e) =>
                      handleTimeChange("minute", e.target.value, index, "from")
                    }
                  >
                    {["00", "15", "30", "45"].map((minute) => (
                      <option key={minute} value={minute}>
                        {minute}
                      </option>
                    ))}
                  </select>
                  <select
                    className="time__input mx-1"
                    value={moment(day?.from, "hh:mm A").format("A")}
                    onChange={(e) =>
                      handleTimeChange("period", e.target.value, index, "from")
                    }
                  >
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                </div>
              </div>

              <div className="d-flex flex-column ms-3">
                <label className="ms-2">Closing Time</label>
                <div className="d-flex">
                  <select
                    className="time__input mx-1"
                    value={moment(day?.to, "hh:mm A").format("hh")}
                    onChange={(e) =>
                      handleTimeChange("hour", e.target.value, index, "to")
                    }
                  >
                    {Array.from({ length: 12 }, (_, i) =>
                      (i + 1)?.toString()?.padStart(2, "0")
                    ).map((hour) => (
                      <option key={hour} value={hour}>
                        {hour}
                      </option>
                    ))}
                  </select>

                  <select
                    className="time__input mx-1"
                    value={moment(day?.to, "hh:mm A").format("mm")}
                    onChange={(e) =>
                      handleTimeChange("minute", e.target.value, index, "to")
                    }
                  >
                    {["00", "15", "30", "45"]?.map((minute) => (
                      <option key={minute} value={minute}>
                        {minute}
                      </option>
                    ))}
                  </select>
                  <select
                    className="time__input ms-1"
                    value={moment(day?.to, "hh:mm A").format("A")}
                    onChange={(e) =>
                      handleTimeChange("period", e.target.value, index, "to")
                    }
                  >
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                </div>
              </div>
              <div className="sliderbtn">
                <label
                  className={`time_switch ${day.isAvailable ? "checked" : ""}`}
                >
                  <input
                    type="checkbox"
                    checked={day.isAvailable}
                    onChange={(e) =>
                      handleTimeChange("isAvailable", e.target.checked, index)
                    }
                  />
                  <div className="slider"></div>
                </label>
              </div>
            </div>
          ))}

          <div className="col-lg-3 col-md-5 col-sm-10 mx-auto ">
            <button className="login-btn mt-3 mb-2 fw-bold" type="submit">
              {isLoading ? 
              // <Spinner /> 
              <BeatLoader size={15} color="#fff" />
              : "SAVE"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SalonTime;
