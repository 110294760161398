import React, { useEffect } from "react";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import apis from "../../services";
import { useMutation } from "@tanstack/react-query";
import { setNoOfServices, setServices } from "../../redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";

const BookingServiceTable = ({
  setSelectedServices,
  selectedServices,
  services,
}) => {
  const handleServiceSelection = (serviceId) => {
    const isSelected = selectedServices?.includes(serviceId);
    if (isSelected) {
      setSelectedServices(selectedServices?.filter((id) => id !== serviceId));
    } else {
      setSelectedServices([...selectedServices, serviceId]);
    }
  };

  const dispatch = useDispatch();
  const { user, storeInfo } = useSelector((e) => e?.user);
  const { mutate: getServices, isLoading } = useMutation(
    () =>
      apis.getStoreServices({
        id: user?.role === "store" ? storeInfo?._id : user?._id,
        role: user?.role,
      }),
    {
      onError: function ({ message }) {
        console.log(message, "message");
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          dispatch(setServices(data?.service));
          dispatch(setNoOfServices(data?.service.length || 0));
        }
      },
    }
  );

  useEffect(() => {
    getServices();
  }, []);

  return (
    <>
      {isLoading && <div id="cover-spin"></div>}
      <div class="table-responsive serviceSelectionTable bg-white rounded-3 mt-2 pb-0 ">
        <table class="table table-hover">
          <thead className="table-secondary">
            <tr>
              <th scope="col">Service Name</th>
              <th scope="col">Service Price</th>
              <th scope="col">Select Service</th>
            </tr>
          </thead>
          <tbody>
            {services?.length > 0 ? (
              services?.map((service, i) => {
                return (
                  <tr
                    className="hc"
                    key={i}
                    onClick={() => handleServiceSelection(service?._id)}
                  >
                    <td className="text-capitalize">{service?.name}</td>
                    <td className="text-capitalize">{service?.value} PKR</td>
                    <td>
                      {selectedServices?.includes(service?._id) ? (
                        <ImCheckboxChecked
                          onClick={() => handleServiceSelection(service?._id)}
                          className="fs-4 text-pink"
                        />
                      ) : (
                        <ImCheckboxUnchecked
                          onClick={() => handleServiceSelection(service?._id)}
                          className="fs-4 text-pink"
                        />
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="w-100 text-center" colspan="3">
                  No Services Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BookingServiceTable;
