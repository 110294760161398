import apis from "../../services";
import { createAsyncThunk } from "@reduxjs/toolkit";

/***********    USER REDUX APIS     ***********/
const getCompleteInfo = createAsyncThunk(
  "Complete Store Information",
  async (id) => apis.getCompleteStore(id)
);
const getCompleteStaff = createAsyncThunk(
  "Complete Staff Information",
  async (id) => apis.getCompleteStaff(id)
);

const getCompleteAdmin = createAsyncThunk(
  "Complete Admin Information",
  async (id) => apis.getCompleteAdmin(id)
);

const reduxApis = { getCompleteInfo, getCompleteStaff,getCompleteAdmin };

export default reduxApis;
