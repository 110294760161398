import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaUsers, FaRegClock } from "react-icons/fa6";
import { GiPriceTag } from "react-icons/gi";
import { PiGenderFemaleBold } from "react-icons/pi";
import { BiCategory } from "react-icons/bi";
import { MdOutlineDescription } from "react-icons/md";
import { useSelector } from "react-redux";

const ServiceDetailsComp = ({ service }) => {
  const {user}= useSelector((e) => e.user);
  const navigate = useNavigate();
  return (
    <div className="p-3">
      {/* <img
        src={service?.image}
        className="img-fluid salonProfileImage my-3 d-block mx-auto"
        alt=""
      /> */}
      <div className="d-flex align-items-center justify-content-between">
        <h2 className="mt-2 text-center text-lg-start">{service?.name}</h2>
        <div className="col-md-3 col-lg-2">
          
            <button className="login-btn px-3"
            onClick={() =>
              navigate(`/${user?.role}/service/update`, {
                state: { service: service },
              })
            }
            >EDIT</button>
        </div>
      </div>
      <div className="row mt-2">
        {/* <div className="col-lg-3 col-md-6 mt-3">
          <div className="serivce-blocks d-flex align-items-center">
            <FaUsers className="serviceIcons" />
            <div className="d-flex w-100 flex-column align-items-center">
              <p className="m-0">NO OF STAFF</p>
              <h3 className="m-0">{service?.noOfPeople}</h3>
            </div>
          </div>
        </div> */}
        <div className="col-lg-3 col-md-6 mt-3">
          <div className="serivce-blocks h-100  d-flex align-items-center bg-white shadow-sm">
            <PiGenderFemaleBold className="serviceIcons" />
            <div className="d-flex w-100 flex-column align-items-center">
              <p className="m-0 text-uppercase">Service For</p>
              <h3 className="m-0">
                {" "}
                {service?.segment_Id === 1
                  ? "MALE"
                  : service?.segment_Id === 2
                  ? "FEMALE"
                  : service?.segment_Id === 3
                  ? "BOTH"
                  : ""}
              </h3>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 mt-3">
          <div className="serivce-blocks h-100  d-flex align-items-center bg-white shadow-sm">
            <BiCategory className="serviceIcons" />
            <div className="d-flex w-100 flex-column align-items-center">
              <p className="m-0 text-uppercase ">Category</p>
              <h3 className="m-0">{service?.service_category_Id?.name}</h3>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 mt-3">
          <div className="serivce-blocks h-100  d-flex align-items-center bg-white shadow-sm ">
            <GiPriceTag className="serviceIcons" />
            <div className="d-flex w-100 flex-column align-items-center">
              <p className="m-0 text-uppercase">Charges</p>
              <h3 className="m-0">{service?.value} PKR</h3>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 mt-3">
          <div className="serivce-blocks h-100  d-flex align-items-center bg-white shadow-sm">
            <FaRegClock className="serviceIcons" />
            <div className="d-flex w-100 flex-column align-items-center">
              <p className="m-0 text-uppercase">Duration</p>
              <h3 className="m-0">{service?.duration} Min</h3>
            </div>
          </div>
        </div>
        <div className="col-lg-12 my-3">
          <div className="serivce-blocks d-flex align-items-start align-items-lg-center bg-white shadow-sm">
            <MdOutlineDescription className="serviceIcons me-2 " />
            <div className="d-flex w-100 flex-column align-items-center">
              <p className="m-0 text-uppercase">Description</p>
              <h6 className="m-0">{service?.description}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetailsComp;
