import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import SalonInfomation from "../components/register-salon/SalonInfomation";
import CategoryTable from "../components/Table/CategoryTable";
import StaffTable from "../components/Table/StaffTable";
import ServiceTable from "../components/Table/ServiceTable";
import BookingCheckInTable from "../components/Table/BookingCheckInTable";
import BlogTable from "../components/Table/BlogTable";
import CouponTable from "../components/Table/CouponTable";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import apis from "../services";
import {
  setBlogs,
  setBookings,
  setCategory,
  setCoupons,
  setServices,
  setStaffs,
  setStoreID,
  setStoreInfo,
} from "../redux/slices/userSlice";
import AddCategoryModal from "../components/Modal/AddCategoryModal";

const StoreDetails = () => {
  const { state } = useLocation();
  const [key, setKey] = useState("info");

  useEffect(() => {
    if (state?.tab) {
      setKey(state?.tab);
    }
  }, []);
  const { slug } = useParams();
  const {
    services,
    coupons,
    bookings,
    staffs,
    categories,
    user,
    storeInfo,
    blogs,
  } = useSelector((e) => e?.user);

  const filteredServices =
    services?.filter(
      (cat) =>
        cat?.store_Id?._id === storeInfo?._id ||
        cat?.store_Id === storeInfo?._id
    ) || [];
  const filteredStaff =
    staffs?.filter(
      (cat) =>
        cat?.store_Id?._id === storeInfo?._id ||
        cat?.store_Id === storeInfo?._id
    ) || [];
  const filteredCategory =
    categories?.filter(
      (cat) =>
        cat?.store_Id?._id === storeInfo?._id ||
        cat?.store_Id === storeInfo?._id
    ) || [];

  // console.log(filteredStaff,"filteredStaff")
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { mutate: getAdminStore, isLoading } = useMutation(
    () => apis.getAdminCompleteStore(slug),
    {
      onError: function ({ message }) {
        // toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          dispatch(setStaffs(data?.staff));
          dispatch(setServices(data?.services));
          dispatch(setStoreInfo(data?.StoreDetails));
          dispatch(setStoreID(data?.StoreDetails?._id));
          dispatch(setBlogs(data?.blogs));
          dispatch(setCoupons(data?.coupons));
          dispatch(setCategory(data?.category));
          dispatch(setBookings(data?.bookings));
        }
      },
    }
  );

  useEffect(() => {
    if (user?.role === "admin") {
      if (!slug) {
        navigate("/admin/stores");
      } else {
        getAdminStore();
      }
    }
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [category, setCategory1] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const updating = async (data) => {
    await setCategory1(data);
    setShowModal(true);
    setIsEdit(true);
  };

  return (
    <Layout>
      <AddCategoryModal
        category={category}
        showModal={showModal}
        isEdit={isEdit}
        setCategory1={setCategory1}
        setShowModal={setShowModal}
      />
      {isLoading && <div id="cover-spin"></div>}
      <div className="p-2">
        <h3 className="fw-bold mb-4">Store details</h3>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="info" title="Information">
            <SalonInfomation
              staffs={filteredStaff}
              services={filteredServices}
              storeInfo={storeInfo}
            />
          </Tab>
          <Tab eventKey="service" title="Services">
            <div className="col-lg-2 ms-auto col-md-3 col-sm-4">
              <Link to={`/${user?.role}/service/add`}>
                <button className="login-btn my-3 px-3 fw-bold addbtn">
                  ADD SERVICE
                </button>
              </Link>
            </div>
            <ServiceTable services={filteredServices} />
          </Tab>
          <Tab eventKey="staff" title="Staff">
            <div className="col-lg-2 ms-auto col-md-3 col-sm-4">
              <Link to={`/${user?.role}/staff/add`}>
                <button className="login-btn my-3 px-3 fw-bold addbtn">
                  ADD STAFF
                </button>
              </Link>
            </div>
            <StaffTable staffs={filteredStaff} />
          </Tab>
          <Tab eventKey="category" title="Categories">
            <div className="col-lg-2 col-md-3 col-sm-4 ms-auto">
              <button
                className="login-btn my-3 fw-bold"
                onClick={() => {
                  setIsEdit(false);
                  setShowModal(true);
                }}
              >
                ADD CATEGORY
              </button>
            </div>
            <CategoryTable updating={updating} categories={filteredCategory} />
          </Tab>
          <Tab eventKey="blog" title="Blogs">
            <div className="col-lg-2 col-md-3 col-sm-4 ms-auto">
              <button
                className="login-btn my-3 fw-bold"
                onClick={() => {
                  navigate("/admin/blog/add");
                }}
              >
                ADD BLOG
              </button>
            </div>
            <BlogTable blog={blogs} />
          </Tab>
          <Tab eventKey="booking" title="Bookings">
            <BookingCheckInTable bookings={bookings} />
          </Tab>
          <Tab eventKey="coupon" title="Coupons">
            <CouponTable coupons={coupons} />
          </Tab>
        </Tabs>
      </div>
    </Layout>
  );
};

export default StoreDetails;
