import Joi from "joi";
export const storeValidate = (req) => {
  const schema = Joi.object({
    salon_owner_Id: Joi.string().required(),
    name: Joi.string().required().messages({
      "string.base": "Salon name is required*",
      "string.empty": "Salon name is required*",
      "any.required": "Salon name is required*",
    }),
    segment_Id: Joi.number().required().messages({
      "string.base": "Salon type is required*",
      "any.required": "Salon type is required*",
    }),
    category_Ids: Joi.array().required().messages({
      "array.base": "Salon category is required*",
      "array.empty": "Salon category is required*",
      "any.required": "Salon category is required*",
    }),
    phone: Joi.number()
      .integer()
      .min(100000000000)
      .max(999999999999)
      .required()
      .messages({
        'number.base': 'Phone number must be a valid number',
        'number.integer': 'Phone number must be an integer',
        'number.min': 'Phone number must have at least 12 digits',
        'number.max': 'Phone number cannot exceed 12 digits',
        'any.required': 'Phone number is required',
      }),

    country: Joi.string().required().messages({
      "string.base": "Country is required*",
      "string.empty": "Country is required*",
      "any.required": "Country is required*",
    }),
    city: Joi.string().required().messages({
      "string.base": "City is required*",
      "string.empty": "City is required*",
      "any.required": "City is required*",
    }),
    location: Joi.string().required().messages({
      "string.base": "Location is required*",
      "string.empty": "Location is required*",
      "any.required": "Location is required*",
    }),
    no_of_slots: Joi.number().required().messages({
      "string.base": "Slot is required*",
      "any.required": "Slot is required*",
    }),

    details: Joi.string().required().messages({
      "string.base": "Details is required*",
      "string.empty": "Details is required*",
      "any.required": "Details is required*",
    }),
    image: Joi.any(),

    latitude: Joi.number().required(),
    longitude: Joi.number().required(),
  });
  return schema.validate(req);
};
