import React, { useEffect } from "react";
import Layout from "../components/layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import StaffBookings from "../components/analytics/StaffBookings";
import ServicesPieChart from "../components/analytics/ServicesPieChart";
import SalonEarningChart from "../components/analytics/SalonEarningChart";
import StaffChart from "../components/analytics/StaffChart";
import ServiceChart from "../components/analytics/ServiceChart";
import MonthlyChart from "../components/analytics/MonthlyChart";
import * as XLSX from "xlsx";
import { IoMdCloudDownload } from "react-icons/io";
import { setGraphs } from "../redux/slices/userSlice";
import apis from "../services";
import toast from "react-hot-toast";
import { useMutation } from "@tanstack/react-query";

const Analytics = () => {
  const { graphs, storeInfo, user, staffDetails } = useSelector((e) => e.user);
  const generateExcel = () => {
    const workbook = XLSX.utils.book_new();

    Object.keys(graphs).forEach((sheetName) => {
      const sheetData = getDataArray(graphs[sheetName]);
      const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    });

    XLSX.writeFile(workbook, "Beautowns Dashboard Analytics.xlsx");
  };

  const getDataArray = (data) => {
    if (Array.isArray(data)) {
      return [data];
    } else if (typeof data === "object") {
      return Object.entries(data)?.map(([key, value]) => [key, value]);
    }
    return [[data]];
  };

  const dispatch = useDispatch();

  const { mutate: getGraphs, isLoading } = useMutation(
    () =>
      user?.role === "store"
        ? apis.getStoreGraph(storeInfo?._id)
        : user?.role === "staff"
        ? apis.getStaffGraphs(staffDetails?._id)
        : null,

    {
      onError: function ({ message }) {
        console.log(message, "message");
        toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          if (data && graphs?.data) {
            dispatch(setGraphs(graphs.data));
          }
        }
      },
    }
  );

  useEffect(() => {
    getGraphs();
  }, []);

  return (
    <Layout>
          {isLoading && <div id="cover-spin"></div>}
      <div className="p-3">
        <div className="d-flex align-items-center  justify-content-between mt-4">
          <h3 className="m-0 fw-bold ">Analytics</h3>
          <div className="col-sm-4 col-md-3 col-lg-2">
            <button
              className="login-btn px-3  d-flex align-items-center"
              onClick={generateExcel}
            >
              <IoMdCloudDownload className="fs-3 me-3" /> REPORT
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 mt-3">
            <div className="bg-white rounded-3 h-100 pt-3">
              <h5 className="mt-2 text-center">Monthly sales</h5>
              <MonthlyChart data={graphs?.monthlyBookingCounts} />
            </div>
          </div>
          <div className="col-lg-6 mt-3">
            <div className="bg-white rounded-3 h-100 pt-3">
              <h5 className="mt-2 text-center">Service sales</h5>
              <ServiceChart data={graphs?.serviceCounts} />
            </div>
          </div>
          <div className="col-lg-6 mt-3">
            <div className="bg-white rounded-3 h-100 pt-3">
              <h5 className="mt-2 text-center">Staff Bookings</h5>
              <StaffChart data={graphs?.staffBookingCounts} />
            </div>
          </div>
          <div className="col-lg-6 mt-3">
            <div className="bg-white rounded-3 h-100 pt-3">
              <h5 className="mt-2 text-center">Salon earning</h5>
              <SalonEarningChart data={graphs?.monthlyEarnings} />
            </div>
          </div>
          <div className="col-md-6 col-lg-6 mt-3">
            <div className="bg-white rounded-3 pb-2 h-100 pt-3">
              <h5 className="mt-2 text-center">Service sales</h5>
              <ServicesPieChart data={graphs?.servicePercentage} />
            </div>
          </div>
          <div className="col-md-6 col-lg-6 mt-3">
            <div className="bg-white rounded-3 h-100 pb-2  pt-3">
              <h5 className="mt-2 text-center">Staff bookings</h5>
              <StaffBookings
                isAnalytics={true}
                data={graphs?.staffBookingPercentage}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Analytics;
