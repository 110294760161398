import React from "react";
import AddBlogComp from "../components/blogs/AddBlogComp";
import Layout from "../components/layout/Layout";
const AddBlog = () => {
  return (
    <Layout>
      <AddBlogComp />
    </Layout>
  );
};

export default AddBlog;
