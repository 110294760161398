import moment from "moment";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { CiCalendar } from "react-icons/ci";
import { AiOutlineUser } from "react-icons/ai";
import { FaUsers } from "react-icons/fa";

const BookingCheckout = ({
  isBooking,
  coupon,
  isValidating,
  selectedStaffs,
  validateCoupon,
  updateStep,
  activeStep,
  data,
  isPhone,
}) => {
  const { storeInfo, services, staffs, user } = useSelector((e) => e?.user);

  let selectedServices = services?.filter((e) =>
    data?.service_Ids?.includes(e._id)
  );
  const sumOfDurations = selectedServices?.reduce(
    (acc, obj) => acc + obj?.duration,
    0
  );

  const [staff] = staffs?.filter((e) => e._id === selectedStaffs?.[0]) || [];
 
  const sumOfTotal = selectedServices?.reduce(
    (acc, obj) => acc + obj?.value,
    0
  );

  const [code, setCode] = useState("");
 
  const staffLength =
    selectedStaffs?.filter((id) => id !== undefined && id !== null)?.length ||
    0;

    console.log(staffLength,"staffLength")

  return (
    <div
      className={`p-3 ${
        isPhone ? "checkout-wrapper-phone" : "checkout-wrapper"
      }  bg-white`}
    >

    
      <div className="row align-items-center p-3">
        <div className="col-3 p-0">
          <img
            src={storeInfo?.image}
            className="img-fluid checkoutprofile"
            alt=""
          />
        </div>
        <div className="col-9 ps-2 p-0">
          <p className="h5 single_line">{storeInfo?.name}</p>
          <p className="h6 single_line fw-normal text-secondary ">
            {storeInfo?.location}
          </p>
        </div>
      </div>
      <hr />
      {selectedServices?.length > 0 &&
        selectedServices?.map((e) => (
          <div className="d-flex justify-content-between my-2">
            <div className="d-flex flex-column">
              <h6 className="m-0">{e?.name}</h6>
              <h6 className="m-0 text-secondary ">{e?.duration} min</h6>
            </div>
            <h6>{e?.value} PKR</h6>
          </div>
        ))}
      {sumOfDurations > 0 && (
        <div className="d-flex justify-content-between my-2 mb-0">
          <h6 className="mb-0">Duration</h6>
          <h6 className="mb-0">{sumOfDurations} Minutes</h6>
        </div>
      )}
      {selectedServices?.length > 0 && <hr />}

      {activeStep > 1 && coupon?.type && (
        <div className="d-flex justify-content-between my-2">
          <h6 className="fw-bold">Disount</h6>
          {coupon?.type?.hasOwnProperty("percentage") ? (
            <h6 className="fw-bold">
              {(coupon?.type?.percentage * sumOfTotal) / 100} PKR
            </h6>
          ) : coupon?.type?.hasOwnProperty("fixedAmount") ? (
            <h6 className="fw-bold">{coupon?.type?.fixedAmount} PKR </h6>
          ) : (
            ""
          )}
        </div>
      )}

      {activeStep > 1 && (
        <div className="d-flex justify-content-between my-2">
          <h6 className="fw-bold">Total</h6>
          {coupon?.type?.hasOwnProperty("percentage") ? (
            <h6 className="fw-bold">
              {sumOfTotal - (coupon?.type?.percentage * sumOfTotal) / 100} PKR
            </h6>
          ) : coupon?.type?.hasOwnProperty("fixedAmount") ? (
            <h6 className="fw-bold">
              {Math.max(sumOfTotal - coupon?.type?.fixedAmount, 0)} PKR{" "}
            </h6>
          ) : (
            <h6 className="fw-bold">{sumOfTotal} PKR</h6>
          )}
        </div>
      )}
      {activeStep > 1 && (
        <>
          <label>Add coupon</label>
          <form>
            <div className="d-flex align-items-center my-2">
              <input
                type="text"
                onChange={(e) => setCode(e.target.value)}
                className="form-control"
                required
              />
              <button
                className="w-25 h-auto couponBtn"
                type="button"
                onClick={() => {
                  if (code === "") {
                    toast.error("Please enter coupon code", { id: 1 });
                  } else {
                    validateCoupon({ id: user?._id, code: code });
                  }
                }}
              >
                {isValidating ? <Spinner size="sm" /> : "Add"}
              </button>
            </div>
          </form>
        </>
      )}

      <h5 className="fw-bold mt-3 ">Appointment</h5>
      {activeStep > 1 && (
        <div className="d-flex align-items-center justify-content-between my-2 mt-3">
          {staffLength === 1 && selectedServices?.length >= 1 ? (
            <div className="d-flex align-items-center">
              {staff?.image ? (
                <img src={staff?.image} className="checkoutstaffImage" alt="" />
              ) : (
                <AiOutlineUser className="fs-1" />
              )}
              <div className="ms-2">
                <h6 className="m-0">{staff?.name}</h6>
                <h6 className="text-secondary m-0 staff_title">
                  {staff?.title}
                </h6>
              </div>
            </div>
          ) : selectedStaffs?.length > 1 && selectedServices?.length > 1 ? (
            <div className="d-flex align-items-center">
              <FaUsers className="fs-1" />
              <div className="ms-2">
                <h6 className="m-0">{"Multiple professionals"}</h6>
              </div>
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <AiOutlineUser className="fs-1" />
              <div className="ms-2">
                <h6 className="m-0">{"Any professional"}</h6>
              </div>
            </div>
          )}

          {activeStep > 2 && (
            <h6 onClick={() => updateStep(2)} className="m-0 hc">
              Change
            </h6>
          )}
        </div>
      )}

      {activeStep === 3 && data?.date && (
        <div className="d-flex align-items-center my-2 mt-3 ">
          <CiCalendar className="fs-1 checkoutCalanderIcon" />
          <div className="">
            <h6 className="m-0">
              {moment(data?.date).format("ddd Do MMM YYYY")}
            </h6>
            <h6 className="text-secondary m-0 staff_title">{data?.time}</h6>
          </div>
        </div>
      )}

      {/* {activeStep > 2 && data?.time && (
        <div className="d-flex justify-content-between my-2">
          <h6>Time</h6>
          <h6>{data?.time}</h6>
        </div>
      )} */}

      <div className="d-none d-lg-flex px-3 my-3 w-100 ">
        {activeStep !== 1 && (
          <button
            type="button"
            className="login-btn me-2 px-2"
            onClick={() => updateStep(activeStep - 1)}
          >
            Previous
          </button>
        )}
        {activeStep < 4 ? (
          <button
            className="login-btn ms-2 px-2"
            onClick={() => {
              if (activeStep === 1) {
                if (selectedServices?.length > 0) {
                  updateStep(activeStep + 1);
                } else {
                  toast.error("Please Select Service");
                }
              }

              if (activeStep === 3) {
                if (data?.time !== "") {
                  updateStep(activeStep + 1);
                } else {
                  toast.error("Please Select Timeslot");
                }
              }
              if (activeStep === 2) {
                updateStep(activeStep + 1);
              }
            }}
            type="button"
          >
            Next
          </button>
        ) : (
          <button
            className="login-btn ms-2 px-2"
            type="submit"
            disabled={isBooking}
          >
            {isBooking ? <Spinner size="sm" /> : "Book"}
          </button>
        )}
      </div>
    </div>
  );
};

export default BookingCheckout;
