import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import { BeatLoader } from "react-spinners";

const CancelBookingModal = ({ show, setShow, Function, loading }) => {
  return (
    <Modal centered show={show} onHide={setShow}>
      <Modal.Header closeButton className="bg-white">
        <Modal.Title>Do you want to cancel this booking?</Modal.Title>
      </Modal.Header>

      <Modal.Footer className="bg-white">
        <div className="d-flex">
          <button
            className="login-btn border-0 me-2 bg-secondary text-white  px-4"
            onClick={() => setShow(false)}
          >
            Close
          </button>
          <button
            className="login-btn bg-danger text-white border-0  px-4 d-flex justify-content-center align-items-center"
            onClick={() => {
              Function();
            }}
          >
            {!loading ? "Cancel" : 
            // <Spinner animation="border" />
            <BeatLoader size={13} color="#fff" />
            }
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelBookingModal;
