import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import dummy from "../components/assets/Logo-02.png";
import apis from "../services";
import {
  setTotalUnseenNotifications,
  setUserNotifications,
} from "../redux/slices/userSlice";

const Notifications = () => {
  const { userNotifications, user } = useSelector((e) => e?.user);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const getNoti = async () => {
    try {
      setIsLoading(true);
      let data;
      if (user?.role === "store") {
        data = await apis.getStoreNotification(user?._id);
      } else if (user?.role === "staff") {
        data = await apis.getStaffNotification(user?._id);
      }else if(user?.role === "admin"){
        data = await apis.getAdminNotification();
      }
      if (data?.data?.notifications) {
        if (user?.role === "store") {
          await apis.storeNotificationSeen(user?._id);
        } else if (user?.role === "staff") {
          await apis.staffNotificationSeen(user?._id);
        }
        dispatch(setUserNotifications(data?.data?.notifications));
        dispatch(setTotalUnseenNotifications(0));
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getNoti();
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      {isLoading && <div id="cover-spin"></div>}
      <div className="p-3">
        <div className="row">
          <div className="d-flex p-2 flex-wrap align-items-center w-100 justify-content-between">
            <h3 className="m-0 fw-bold  mb-3">Notifications</h3>
          </div>

          {userNotifications?.length > 0 &&
            userNotifications?.map((not, i) => (
              <div
                key={i}
                className="col-lg-8 col-md-11 mx-auto d-flex align-items-center flex-column flex-md-row   my-3 py-2 noti_box"
              >
                <img
                  src={not?.notification?.image || dummy}
                  className="img-fluid noti_detail_img"
                  alt=""
                />
                <div className="d-flex flex-column ms-lg-2 text-center text-md-start">
                  <h4 className="m-0">{not?.notification?.title}</h4>
                  <p className="m-0 fw-normal text-secondary">
                    {moment(not?.createdAt).format("DD MMMM YYYY h:mmA")}
                  </p>
                  <p className="m-0 mt-1 d-none d-md-block">
                    {not?.notification?.body}
                  </p>
                </div>
                <p className="m-0 mt-1 d-md-none text-center text-md-start">
                  {not?.notification?.body}
                </p>
              </div>
            ))}
        </div>
      </div>
    </Layout>
  );
};

export default Notifications;
