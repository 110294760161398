import Joi from "joi";

export const registerValidate = (req) => {
  const isEdit = req.isEdit || false;
  console.log(req, "req");
  const schema = Joi.object({
    name: Joi.string().required().messages({
      "string.base": "Name is required*",
      "string.empty": "Name is required*",
      "any.required": "Name is required*",
    }),
    gender: Joi.string().valid("male", "female"),
    email: isEdit
      ? Joi.string()
          .email({ tlds: { allow: false } })
          .messages({
            "string.base": "Invalid email format",
          })
      : Joi.string()
          .required()
          .email({ tlds: { allow: false } })
          .messages({
            "string.base": "Email is required*",
            "string.empty": "Email is required*",
            "any.required": "Email is required*",
          }),
    password: isEdit
      ? Joi.string().min(8).max(255).optional().messages({
          "string.min": "Password should have at least 8 characters",
        })
      : Joi.string().min(8).max(255).required().messages({
          "string.base": "Password is required*",
          "string.empty": "Password is required*",
          "any.required": "Password is required*",
          "string.min": "Password should have at least 8 characters",
        }),
    role: isEdit
      ? Joi.string().valid("user", "admin", "store", "staff").optional()
      : Joi.string().valid("user", "admin", "store", "staff").required(),
    image: Joi.any(),
    isEdit: Joi.any(),
  });

  return schema.validate(req);
};
