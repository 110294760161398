import React, { useState, useEffect } from "react";
import apis from "../../services";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { errorValidate } from "../../validations/errorHandleJoi";
import { validateStaffPayroll } from "../../validations/payrollValidation";
import toast from "react-hot-toast";
import { useMutation } from "@tanstack/react-query";
import { Spinner } from "react-bootstrap";
import PayrollTable from "../Table/PayrollTable";
import { useParams, useNavigate } from "react-router-dom";
import { setStaffPayroll } from "../../redux/slices/userSlice";

const AddPayrollComp = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { storeInfo, staffPayroll } = useSelector((e) => e?.user);

  const payroll =
    staffPayroll?.length > 0
      ? staffPayroll?.filter((e) => e?.staff_Id?._id === id)
      : [];

  const [data, setData] = useState({
    store_Id: storeInfo?._id,
    staff_Id: id,
    bonus: 0,
    deductions: 0,
    month: "",
    year: "",
    netSalary: 0,
    startDate: "",
    endDate: "",
    paymentDate: moment().format("YYYY-MM-DD"),
  });

  const calculateNetSalary = () => {
    const salary = parseFloat(data.salary) || 0;
    const bonus = parseFloat(data.bonus) || 0;
    const deductions = parseFloat(data.deductions) || 0;
    const netSalary = salary + bonus - deductions;
    return isNaN(netSalary) ? 0 : netSalary;
  };

  useEffect(() => {
    const newNetSalary = calculateNetSalary();
    setData((prevData) => ({
      ...prevData,
      netSalary: newNetSalary,
    }));
    // eslint-disable-next-line
  }, [data.salary, data.bonus, data.deductions]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [error, setError] = useState();
  console.log(error, "error");
  const { mutate, isLoading: generating } = useMutation(apis.generatePayroll, {
    onError: function ({ message }) {
      setError("");
      toast.error(message?.message, { id: 1 });
    },
    onSuccess: ({ data: user, status }) => {
      if (status === 201) {
        navigate("/payroll");
        dispatch(setStaffPayroll(user?.payrolls));
        // (async () => {
        //   await dispatch(reduxApis?.getCompleteInfo(storeInfo?._id));
        // })();
        toast.success(user?.message, { id: 1 });
      }
    },
  });

  const generate__payroll = (e) => {
    e.preventDefault();
    const startDate = new Date(data?.startDate);
    const endDate = new Date(data?.endDate);
    if (startDate >= endDate) {
      toast?.error("Start date must be less than End date", { id: 1 });
    } else {
      let response = errorValidate(validateStaffPayroll(data));
      if (response === true) {
        mutate(data);
      } else {
        setError(response);
      }
    }
  };

  return (
    <div className="p-3">
      <h3 className="fw-bold">Generate Payroll</h3>
      <form onSubmit={generate__payroll}>
        <div className="row bg-white rounded-3 py-3 mb-5 m-0">
          <div className="col-md-3">
            <div className="mb-3">
              <label htmlFor="email" className="ms-2 mb-1">
                From
              </label>
              <input
                type="date"
                name="startDate"
                onChange={handleChange}
                className="form-control"
              />
              {error?.startDate && (
                <p className="text-danger m-0">{error?.startDate}</p>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="mb-3">
              <label htmlFor="email" className="ms-2 mb-1">
                To
              </label>
              <input
                type="date"
                name="endDate"
                onChange={handleChange}
                className="form-control"
              />
              {error?.endDate && (
                <p className="text-danger m-0">{error?.endDate}</p>
              )}
            </div>
          </div>

          <div className="col-md-3">
            <div className="mb-3">
              <label htmlFor="email" className="ms-2 mb-1">
                Month
              </label>
              <select
                id=""
                className="form-control"
                name="month"
                onChange={handleChange}
              >
                <option disabled="" selected="">
                  Choose Month
                </option>
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
              {error?.month && (
                <p className="text-danger m-0">{error?.month}</p>
              )}
            </div>
          </div>
          <div className="col-md-3 ">
            <div className="mb-3">
              <label htmlFor="email" className="ms-2 mb-1">
                Year
              </label>
              <select
                id=""
                className="form-control"
                name="year"
                onChange={handleChange}
              >
                <option selected>Select Year</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
                <option value="2027">2027</option>
              </select>
              {error?.year && <p className="text-danger m-0">{error?.year}</p>}
            </div>
          </div>

          <div className="col-lg-3 col-md-4">
            <div className="mb-3">
              <label htmlFor="email" className="ms-2 mb-1">
                Basic Pay
              </label>
              <input
                type="number"
                name="salary"
                onChange={handleChange}
                className="form-control"
                placeholder="35000"
              />
              {error?.salary && (
                <p className="text-danger m-0">{error?.salary}</p>
              )}
            </div>
          </div>
          <div className="col-lg-3 col-md-4">
            <div className="mb-3">
              <label htmlFor="email" className="ms-2 mb-1">
                Bonus
              </label>
              <input
                type="number"
                name="bonus"
                onChange={handleChange}
                className="form-control"
                placeholder="2500"
              />
            </div>
          </div>
          <div className="col-lg-3 ">
            <div className="mb-3">
              <label htmlFor="email" className="ms-2 mb-1">
                Deductions
              </label>
              <input
                type="number"
                name="deductions"
                onChange={handleChange}
                className="form-control"
                placeholder="2500"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-4">
            <div className="mb-3">
              <label htmlFor="email" className="ms-2 mb-1">
                Net Salary
              </label>
              <input
                type="number"
                readOnly
                value={data.netSalary}
                className="form-control"
                placeholder="500"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-10 mx-auto ">
            <button className="login-btn mt-2" type="submit">
              {generating ? <Spinner /> : "GENERATE PAYROLL"}
            </button>
          </div>
        </div>
      </form>

      {payroll?.length > 0 ? (
        <PayrollTable payroll={payroll} />
      ) : (
        <h3 className="text-center">No payroll record of this staff</h3>
      )}
    </div>
  );
};

export default AddPayrollComp;
