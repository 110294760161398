import React, { useEffect, useState } from "react";
import StoresTable from "../components/Table/StoresTable";
import Layout from "../components/layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { setLoader, setStores } from "../redux/slices/userSlice";
import apis from "../services";

const Stores = () => {
  const { stores } = useSelector((e) => e?.user);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const getAllStores = async () => {
    try {
      // dispatch(setLoader(true));
      setIsLoading(true)
      const store = await apis.getAllStore();
      console.log(store,"store")
      if (store?.data?.store?.length >0) {
        dispatch(setStores(store?.data?.store));
      }
      setIsLoading(false)
      // dispatch(setLoader(false));
    } catch (error) {
      setIsLoading(false)
      // dispatch(setLoader(false));
    }
  };

  useEffect(() => {
    getAllStores();
  }, []);

  return (
    <Layout>
       {isLoading && <div id="cover-spin"></div>}

      <div className="p-3">
        <h3 className="fw-bold mb-4">Salons</h3>
        <StoresTable stores={stores} />
      </div>
    </Layout>
  );
};

export default Stores;
