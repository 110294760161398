import { createSlice } from "@reduxjs/toolkit";
// import reduxApis from "../api";

const userSlice = createSlice({
  name: "user",
  initialState: {
    storeID:null,
    userNotifications: 0,
    totalUnseenNotifications: 0,
    user: null,
    referrals: [],
    email_for_verification: null,
    totalAmountReward: 0,
    error: null,
    storeInfo: null,
    loading: false,
    blogs: [],
    coupons: [],
    bookings: [],
    transactions: [],
    staffs: [],
    staffPayroll: [],
    staffDetails: [],
    categories: [],
    marketing: [],
    services: [],
    analytics: {},
    graphs: {},
    stores: [],
    storeCategories: [],
    noOfCategory: 0,
    noOfServices: 0,
    noOfStaff: 0,
  },
  reducers: {
    setStoreID: (state, action) => {
      state.storeID = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setStores: (state, action) => {
      state.stores = action.payload;
    },
    setBookings: (state, action) => {
      state.bookings = action.payload;
    },

    setNoOfServices: (state, action) => {
      state.noOfServices = action.payload;
    },
    setNoOfCategory: (state, action) => {
      state.noOfCategory = action.payload;
    },
    setNoOfStaff: (state, action) => {
      state.noOfStaff = action.payload;
    },

    setLoader: (state, action) => {
      state.loading = action.payload;
    },
    setAnalytics: (state, action) => {
      state.analytics = action.payload;
    },
    setGraphs: (state, action) => {
      state.graphs = action.payload;
    },
    set_Email_For_Verification: (state, action) => {
      state.email_for_verification = action.payload;
    },
    setUserNotifications: (state, action) => {
      state.userNotifications = action.payload;
    },
    setTotalUnseenNotifications: (state, action) => {
      state.totalUnseenNotifications = action.payload;
    },
    setStoreInfo: (state, action) => {
      state.storeInfo = action.payload;
    },
    setStaffDetails: (state, action) => {
      state.staffDetails = action.payload;
    },
    setBlogs: (state, action) => {
      state.blogs = action.payload;
    },
    setCoupons: (state, action) => {
      state.coupons = action.payload;
    },
    setCategory: (state, action) => {
      state.categories = action.payload;
    },
    setStoreCategory: (state, action) => {
      state.storeCategories = action.payload;
    },
    setReferral: (state, action) => {
      state.referrals = action.payload;
    },
    setServices: (state, action) => {
      state.services = action.payload;
    },
    setMarketing: (state, action) => {
      state.marketing = action.payload;
    },
    setStaffs: (state, action) => {
      state.staffs = action.payload;
    },
    setStaffBooking: (state, action) => {
      state.staffBookings = action.payload;
    },
    setStaffPayroll: (state, action) => {
      state.staffPayroll = action.payload;
    },
    logout: (state) => {
      state.userNotifications = null;
      state.storeID = null;
      state.totalUnseenNotifications = null;
      state.user = null;
      state.referrals = null;
      state.email_for_verification = null;
      state.totalAmountReward = 0;
      state.error = null;
      state.storeInfo = null;
      state.staffDetails = null;
      state.loading = false;
      state.blogs = [];
      state.coupons = [];
      state.storeBookings = [];
      state.staffBookings = [];
      state.transactions = [];
      state.staffs = [];
      state.staffDetails = {};
      state.categories = [];
      state.services = [];
      state.staffPayroll = [];
      state.marketing = [];
      state.noOfCategory = 0;
      state.noOfServices = 0;
      state.noOfStaff = 0;
      state.bookings = [];
      state.staffPayroll = [];
      state.graphs = {};
      state.analytics={}
      state.stores=[]
      state.storeCategories=[]
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(reduxApis.getCompleteInfo.pending, (state) => {
  //       state.loading = true;
  //     })
  //     .addCase(reduxApis.getCompleteInfo.fulfilled, (state, action) => {
  //       let { data } = action?.payload;
  //       state.loading = false;
  //       state.blogs = data?.blogs || [];
  //       state.storeBookings = data?.bookings || [];
  //       state.transactions = data?.transactions || [];
  //       state.coupons = data?.coupons || [];
  //       state.services = data?.services || [];
  //       state.categories = data?.categories || [];
  //       state.referrals = data?.referrals || [];
  //       state.totalAmountReward = data?.totalAmountReward;
  //       state.userNotifications = data?.notifications || [];
  //       state.staffs = data?.staffs || [];
  //       state.staffPayroll = data?.staffPayroll || [];
  //       state.analytics = data?.analytics || {};
  //       state.graphs = data?.graphs || {};
  //       state.marketing = data?.storeMarketing  || [];
  //     })
  //     .addCase(reduxApis.getCompleteInfo.rejected, (state, action) => {
  //       state.loading = false;
  //       state.error = action.payload;
  //     });

  //   builder
  //     .addCase(reduxApis.getCompleteStaff.pending, (state) => {
  //       state.loading = true;
  //     })
  //     .addCase(reduxApis.getCompleteStaff.fulfilled, (state, action) => {
  //       state.loading = false;
  //       state.staffBookings = action?.payload?.data?.bookings || [];
  //       state.referrals = action?.payload?.data?.referral || [];
  //       state.userNotifications = action?.payload?.data?.notifications || [];
  //       state.totalAmountReward = action?.payload?.data?.totalAmountReward || 0;
  //       state.staffDetails = action?.payload?.data?.staffInfo || {};
  //       state.analytics = action?.payload?.data?.analytics || {};
  //       state.graphs = action?.payload?.data?.graphs || {};
  //     })
  //     .addCase(reduxApis.getCompleteStaff.rejected, (state, action) => {
  //       state.loading = false;
  //       state.error = action.payload;
  //     });

  //   builder
  //     .addCase(reduxApis.getCompleteAdmin.pending, (state) => {
  //       state.loading = true;
  //     })
  //     .addCase(reduxApis.getCompleteAdmin.fulfilled, (state, action) => {
  //       console.log(action?.payload?.data,"action")
  //       let { data } = action?.payload;
  //       state.loading = false;
  //       state.stores = data?.allStores || [];
  //       state.blogs = data?.blogs || [];
  //       state.storeBookings = data?.bookings || [];
  //       state.transactions = data?.transactions || [];
  //       state.coupons = data?.coupons || [];
  //       state.services = data?.services || [];
  //       state.categories = data?.serviceCategoies || [];
  //       state.storeCategories = data?.storeCategories || [];
  //       state.referrals = data?.referrals || [];
  //       state.totalAmountReward = data?.totalAmountReward;
  //       state.userNotifications = data?.notifications || [];
  //       state.staffs = data?.staffs || [];
  //       state.staffPayroll = data?.staffPayroll || [];
  //       state.analytics = data?.analytics || {};
  //       state.graphs = data?.graphs || {};
  //       state.marketing = data?.storeMarketing  || [];
  //     })
  //     .addCase(reduxApis.getCompleteAdmin.rejected, (state, action) => {
  //       state.loading = false;
  //       state.error = action.payload;
  //     });
  // },
});

export const {
  logout,
  setUser,
  setStoreCategory,
  setBlogs,
  setStaffDetails,
  setTotalAmountReward,
  setUserNotifications,
  set_Email_For_Verification,
  setReferral,
  setCoupons,
  setServices,
  setTotalUnseenNotifications,
  setStoreInfo,
  setStores,
  setStaffs,
  setCategory,
  setStaffPayroll,
  setLoader,
  setMarketing,
  setAnalytics,
  setGraphs,
  setBookings,
  setNoOfServices,
  setNoOfCategory,
  setNoOfStaff,
  setStoreID
} = userSlice.actions;

export default userSlice.reducer;
