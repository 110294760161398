import React, { useEffect, useState, useRef } from "react";
import { useMutation } from "@tanstack/react-query";
import apis from "../../services";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import {
  staffValidate,
  updateStaffValidate,
} from "../../validations/StaffValidation";
import { errorValidate } from "../../validations/errorHandleJoi";
import { Spinner } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import imageIcon from "../assets/imageIcon.png";
import "react-phone-input-2/lib/style.css";
import { TbPhotoEdit } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { setStaffs } from "../../redux/slices/userSlice";
import { BeatLoader } from "react-spinners";

const AddStaff = ({ setStaff_id, isEdit, staff_details }) => {
  const { storeInfo, user, storeID } = useSelector((e) => e.user);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = useState(null);
  const [data, setData] = useState({
    store_Id: user?.role === "admin" ? storeID : storeInfo?._id,
    name: "",
    title: "",
    email: "",
    description: "",
    phone: "",
    gender: "",
    password: "",
    image: null,
  });

  useEffect(() => {
    const {
      _id: store_Id,
      salon_staff_Id,
      isDeleted,
      isSuspend,
      workingSchedule,
      createdAt,
      updatedAt,
      __v,
      ...rest
    } = staff_details || {};

    if (isEdit) {
      setData((prevData) => ({
        ...prevData,
        store_Id: store_Id,
        ...rest,
      }));
    }

    setImagePreview(staff_details?.image);
    // eslint-disable-next-line
  }, [staff_details]);

  const onChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setImagePreview(URL.createObjectURL(files?.[0]));
      setData((prevData) => ({ ...prevData, [name]: files?.[0] }));
    } else {
      setData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  let [error, setError] = useState();
  console.log(error, "error");

  const { mutate, isLoading: isSubmitting } = useMutation(apis.add_staff, {
    onError: function ({ message }) {
      setError("");
      toast.error(message?.message, { id: 1 });
    },
    onSuccess: ({ data: user, status }) => {
      if (status === 201) {
        setStaff_id(user?.staff?._id);
        toast.success(user?.message, { id: 1 });
        dispatch(setStaffs(user?.staffs));
      }
    },
  });
  const { mutate: updateStaff, isLoading: isUpdating } = useMutation(
    apis.update_Staff,
    {
      onError: function ({ message }) {
        setError("");
        toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          toast.success(data?.message, { id: 1 });
          dispatch(setStaffs(data?.staffs));
          if(user?.role==="admin"){
            navigate(`/admin/stores/details/${storeInfo?.slug}`,{state:{tab:"staff"}})
          }else{
            navigate(`/${user?.role}/staff`);
          }
        }
      },
    }
  );

  const add_staff = (e) => {
    e.preventDefault();
    const form_data = new FormData();
    for (const [key, value] of Object.entries(data)) {
      if (isEdit && ["store_Id", "email", "password"].includes(key)) {
        continue;
      }
      if (key === "image" && !value) {
        continue;
      }
      form_data.append(key, value);
    }
    let { email, store_Id, password, ...rest } = data;
    let response = errorValidate(
      isEdit ? updateStaffValidate(rest) : staffValidate(data)
    );
    if (response === true) {
      // if (data?.image) {

      if (isEdit) {
        updateStaff({ id: staff_details?._id, body: form_data });
      } else {
        mutate(form_data);
      }
      // } else {
      //   toast.error("Please select image");
      // }
    } else {
      setError(response);
    }
  };

  const fileInputRef = useRef();

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleFileSelection = () => {
    const selectedFile = fileInputRef.current.files[0];
    setImagePreview(URL.createObjectURL(selectedFile));
    setData((prevData) => ({
      ...prevData,
      image: selectedFile,
    }));
  };

  return (
    <>
      <h3 className="text-uppercase fw-bold">
        {isEdit ? "Update Staff" : "Add Staff"}
      </h3>
      <form onSubmit={add_staff} className="bg-white p-3 mx-auto rounded-3 ">
        {isEdit ? (
          <div className="profile_image_wrapper">
            {imagePreview === null || !imagePreview?.length > 0 ? (
              <div className="staffnameWrapper d-flex justify-content-center align-items-center ">
                {data?.name?.substring(0, 1)}
              </div>
            ) : (
              <img
                src={imagePreview}
                onClick={triggerFileInput}
                className="img-fluid d-block mx-auto salonProfileImage shadow-sm"
                alt=""
              />
            )}

            <TbPhotoEdit
              onClick={triggerFileInput}
              className="text-center hc d-block mx-auto fs-1 mt-1 inputImage"
            />
          </div>
        ) : (
          <div className="profile_image_wrapper">
            <img
              src={imagePreview || imageIcon}
              onClick={triggerFileInput}
              className="img-fluid d-block mx-auto salonProfileImage shadow-sm"
              alt=""
            />
            <TbPhotoEdit
              onClick={triggerFileInput}
              className="text-center hc d-block mx-auto fs-1 mt-1 inputImage"
            />
          </div>
        )}

        <div className="row">
          <input
            type="file"
            name="image"
            accept="image/png, image/gif, image/jpeg"
            onChange={handleFileSelection}
            ref={fileInputRef}
            className="d-none"
          />
          <div className="col-md-6 mt-3">
            <div className="form-group">
              <label className="ms-1">Name</label>
              <input
                type="text"
                name="name"
                value={data?.name}
                onChange={onChange}
                className="form-control"
              />
            </div>
            {error?.name && <p className="m-0 text-danger">{error?.name}</p>}
          </div>
          <div className="col-md-6 mt-3">
            <div className="form-group">
              <label className="ms-1">Designation</label>
              <input
                type="text"
                value={data?.title}
                name="title"
                onChange={onChange}
                className="form-control"
              />
            </div>
            {error?.title && <p className="m-0 text-danger">{error?.title}</p>}
          </div>
          {!isEdit && (
            <>
              <div className="col-md-6 mt-3">
                <div className="form-group">
                  <label className="ms-1">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={data?.email}
                    onChange={onChange}
                    className="form-control"
                  />
                </div>
                {error?.email && (
                  <p className="m-0 text-danger">{error?.email}</p>
                )}
              </div>
              {/* <div className="col-md-6 mt-3">
                <div className="form-group">
                  <label className="ms-1">
                    {isEdit ? "New Password" : "Password"}
                  </label>
                  <input
                    type="password"
                    name="password"
                    onChange={onChange}
                    className="form-control"
                  />
                </div>
                {error?.password && (
                  <p className="m-0 text-danger">{error?.password}</p>
                )}
              </div> */}
              <div className="col-md-6 mt-3">
                <div className=" position-relative">
                  <label htmlFor="password" className="ms-2 mb-1">
                    Password
                  </label>
                  <input
                    name="password"
                    type={showPassword ? "text" : "password"}
                    className="form-control "
                    onChange={onChange}
                  />

                  <div
                    className="eyeDiv"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {!showPassword ? (
                      <FaRegEyeSlash className="eyeIcon" />
                    ) : (
                      <FaRegEye className="eyeIcon" />
                    )}
                  </div>
                </div>
                {error?.password && (
                  <p className="m-0 text-danger">{error?.password}</p>
                )}
              </div>
            </>
          )}
          <div className="col-md-6 mt-3">
            <div className="form-group">
              <label className="ms-1">Gender</label>
              <select
                name="gender"
                value={data?.gender}
                className="form-control"
                onChange={onChange}
              >
                <option value="" selected>
                  Choose...
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
            {error?.gender && (
              <p className="m-0 text-danger">{error?.gender}</p>
            )}
          </div>
          <div className="col-md-6 mt-3">
            <div className="form-group">
              <label className="ms-1">Phone</label>
              <PhoneInput
                country={"pk"}
                value={data?.phone}
                onlyCountries={["pk"]}
                name="phone"
                onChange={(value) => setData({ ...data, phone: value })}
              />
            </div>
            {error?.phone && <p className="m-0 text-danger">{error?.phone}</p>}
          </div>

          <div className="col-12 mt-3">
            <div className="form-group">
              <label className="ms-1">Description</label>
              <textarea
                id=""
                rows="4"
                name="description"
                value={data?.description}
                onChange={onChange}
                className="form-control rounded-4"
              ></textarea>
            </div>
            {error?.description && (
              <p className="m-0 text-danger">{error?.description}</p>
            )}
          </div>

          <div className="col-lg-3 col-md-5 col-sm-10 mx-auto">
            <button
              className="login-btn mt-3 fw-bold d-flex justify-content-center align-items-center"
              disabled={isSubmitting || isUpdating}
              type="submit"
            >
              {isSubmitting || isUpdating ? 
               <BeatLoader size={15} color="#fff" />
              // <Spinner />
               : "SAVE"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddStaff;
