import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import AddStoreCategoryModal from "../components/Modal/AddStoreCategoryModal";
import {  setLoader, setStoreCategory } from "../redux/slices/userSlice";
import apis from "../services";
import StoreCategoryTable from "../components/Table/StoreCategoryTable";

const StoreCategory = () => {
  const { storeCategories } = useSelector((e) => e?.user);
  const [showModal, setShowModal] = useState(false);
  const [category, setCategory1] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();

  const updating = (data) => {
    setShowModal(true);
    setIsEdit(true);
    setCategory1(data);
  };

  const getStoreCategories = async () => {
    try {
      dispatch(setLoader(true));
      const category = await apis.getStoreCategory();
      if (category?.data?.categories) {
        dispatch(setStoreCategory(category?.data?.categories));
      }
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLoader(false));
    }
  };

  useEffect(() => {
    getStoreCategories();
  }, []);

  return (
    <Layout>
      
      <AddStoreCategoryModal
        category={category}
        showModal={showModal}
        isEdit={isEdit}
        setShowModal={setShowModal}
      />

      <div className="p-3">
        <div className="d-flex flex-wrap flex-wrap align-items-center w-100 justify-content-between">
          <h3 className="m-0 my-3 fw-bold ">Store Category</h3>
          <div className="col-lg-2 col-md-3 col-sm-4">
            <button
              className="login-btn my-3 fw-bold"
              onClick={() => {
                setIsEdit(false);
                setShowModal(true);
              }}
            >
              ADD CATEGORY
            </button>
          </div>
        </div>
        <StoreCategoryTable updating={updating} storeCategories={storeCategories} />
      </div>
    </Layout>
  );
};

export default StoreCategory;
