import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Sidebar.css";
import Logo from "../assets/Logo-02.png";
import { Menu, staffMenu, AdminMenu } from "./Menu";
import { useSidebar } from "./SidebarContext";
import { FaArrowLeft } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { FaAngleDown } from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, storeInfo } = useSelector((e) => e?.user);
 
  const { isSidebarOpen, toggleSidebar, activeMenuIndex, toggleSubmenu } =
    useSidebar();

  const getMenu = () => {
    if (user?.role === "store") {
      return Menu;
    } else if (user?.role === "staff") {
      return staffMenu;
    } else if (user?.role === "admin") {
      return AdminMenu;
    }
  };

  const selectedMenu = getMenu();

  // const isMenuItemActive = (menuItem) => {
  //   const currentPath = location.pathname;
  //   const isExactMatch = menuItem.path === currentPath;
  //   return (
  //     isExactMatch ||
  //     (menuItem.subMenu &&
  //       menuItem.subMenu.some((sub) => sub.path === currentPath))
  //   );
  // };

  const isMenuItemActive = (menuItem) => {
    const currentPath = location.pathname;
    const isExactMatch = menuItem.path === currentPath;

    if (isExactMatch) {
      return true;
    }
    // Check if the menu item has a sub-menu
    if (menuItem?.subMenu) {
      // Check if any sub-menu path matches the current location
      const isSubMenuActive = menuItem?.subMenu.some(
        (sub) => sub?.path === currentPath
      );

      if (isSubMenuActive) {
        return true;
      }

      // Check if the current location is a child route of the parent path
      if (menuItem.subMenu.some((sub) => currentPath.startsWith(sub.path))) {
        return true;
      }
    }

    return false;
  };

  const [currentSize, setCurrentSize] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      setCurrentSize(windowWidth < 992);
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div
        className={`sidebar ${
          isSidebarOpen ? "active_sidebar" : "inactive_sidebar"
        }`}
      >
        <FaArrowLeft
          className="position-absolute backIcons top-0 end-0 fs-3 m-3 text-white"
          onClick={() => {
            toggleSidebar();
          }}
        />

        <div className="logo">
          <img src={Logo} className="img-fluid mainLogo" alt="logo" />
          {isSidebarOpen && (
            <p className="logo-name d-none d-lg-block">Beautowns</p>
          )}
        </div>
        {/* <h6 className="fw-bold text-white text-center text-eclips m-0 px-1">{storeInfo?.name}</h6> */}

        <div className="menu">
          {selectedMenu?.map((menuItem, index) => (
            <div key={index}>
              <div
                className={
                  isMenuItemActive(menuItem)
                    ? "menuItem active_menu"
                    : "menuItem non-active-icon"
                }
                onClick={() => {
                  if (isSidebarOpen && menuItem.subMenu) {
                    toggleSubmenu(index); // Use the context function to set active menu
                  } else {
                    toggleSidebar();
                    navigate(menuItem?.path);
                  }
                }}
                title={menuItem?.heading}
              >
                <div className="menuIconss">{menuItem.icon()}</div>
                <span className="menuHeading text-nowrap ">{menuItem?.heading}</span>
                {isSidebarOpen && menuItem?.subMenu && (
                  <FaAngleDown
                    className={`${
                      activeMenuIndex === index && "rotate-up" // Use context state for rotation
                    } fs-4 ms-auto me-2 text-white bg-transparent`}
                  />
                )}
              </div>
              {isSidebarOpen && menuItem?.subMenu && (
                <div
                  className="submenu text-nowrap"
                  style={{
                    maxHeight: activeMenuIndex === index ? "200px" : "0", // Use context state for maxHeight
                    overflow: "hidden",
                    transition: "max-height 0.3s ease-in-out",
                  }}
                >
                  {menuItem?.subMenu?.map((subMenuItem, subIndex) => (
                    <div
                      key={subIndex}
                      className={
                        location.pathname === subMenuItem?.path
                          ? "menuItem active_menu"
                          : "menuItem"
                      }
                      onClick={() => {
                        if (currentSize) {
                          toggleSidebar();
                        }
                        navigate(subMenuItem?.path);
                      }}
                    >
                      <span className="text-nowrap">
                        <GoDotFill className="text-white bg-transparent m-0 fs-5" />
                        {subMenuItem?.heading}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
