import moment from "moment";
import React from "react";
import { useState } from "react";
import { CiReceipt } from "react-icons/ci";
import ReciptModal from "../Modal/ReciptModal";

const TransactionTable = ({ transactions }) => {
  const [show, setShow] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = transactions?.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const totalPages = Math.ceil(transactions?.length / itemsPerPage);

  return (
    <>
      <ReciptModal data={selectedTransaction} show={show} setShow={setShow} />
      <div class="table-responsive bg-white rounded-3 mt-5">
        <table class="table table-hover ">
          <thead className="table-secondary">
            <tr>
              <th scope="col">Customer Name</th>
              <th scope="col">Date</th>
              <th scope="col">Amount</th>
              <th scope="col">Status</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentItems?.length > 0 &&
              currentItems?.map((transaction, i) => {
                return (
                  <tr key={i}>
                    <td>{transaction?.user_Id?.name}</td>
                    <td>
                      {moment(transaction?.createdAt).format("DD-MM-YYYY")}
                    </td>
                    <th>{transaction?.amount} PKR</th>
                    <td>
                      <button className="received-payment-btn">Received</button>
                    </td>
                    <td>
                      <button
                        className="recipt-btn"
                        onClick={() => {
                          setShow(true);
                          setSelectedTransaction(transaction);
                        }}
                      >
                        <CiReceipt className="fs-3 text-white hc" />
                        Receipt
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className="w-90 position-absolute bottom-0 start-50 translate-middle-x mb-3">
          <div className="row align-items-center text-center text-md-start">
            <div className="col-sm-6 ms-auto col-xs-6 mb-2">
              <div className="d-flex align-items-center justify-content-center">
                <button
                  className="pagination_btn"
                  onClick={() =>
                    setCurrentPage(
                      currentPage > 1 ? currentPage - 1 : currentPage
                    )
                  }
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                {[...Array(totalPages)].map((_, i) => (
                  <div
                    key={i}
                    className={`pageNo ${
                      i + 1 === currentPage ? "pageNo_active" : ""
                    }`}
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </div>
                ))}
                <button
                  className="pagination_btn"
                  onClick={() =>
                    setCurrentPage(
                      currentPage < totalPages ? currentPage + 1 : currentPage
                    )
                  }
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionTable;
