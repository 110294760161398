import Joi from "joi";
export const blogValidation = (req) => {
  const schema = Joi.object({
    store_Id: Joi.string().required(),
    author: Joi.string().required().messages({
      "string.base": "Author is required*",
      "string.empty": "Author is required*",
      "any.required": "Author is required*",
    }),
    title: Joi.string().required().messages({
      "string.base": "Title is required*",
      "string.empty": "Title is required*",
      "any.required": "Title is required*",
    }),
    description: Joi.string().required().messages({
      "string.base": "Description is required*",
      "string.empty": "Description is required*",
      "any.required": "Description is required*",
    }),
    image: Joi.any(),
    author_image: Joi.any(),
  });
  return schema.validate(req);
};
