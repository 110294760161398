import React, { useState } from "react";
// import { MdDeleteForever } from "react-icons/md";

const CustomerTable = ({ customers }) => {
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =customers?.length > 0 ? customers?.slice(indexOfFirstItem, indexOfLastItem) : [];
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(customers?.length / itemsPerPage);

  return (
    <>
      <div className="">
        <div class="table-responsive bg-white rounded-3 mt-2">
          <table class="table table-hover ">
            <thead className="table-secondary">
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Gender</th>
                {/* <th scope="col">Action</th> */}
              </tr>
            </thead>
            <tbody>
              {currentItems?.length > 0 ? (
                currentItems?.map((customer) => {
                  return (
                    <tr>
                      <td>{customer?.name}</td>
                      <td>{customer?.email}</td>
                      <td>{customer?.gender}</td>
                      {/* <td>
                        <MdDeleteForever className="fs-3 text-danger hc" />
                      </td> */}
                    </tr>
                  );
                })
              ) : (
                <td colSpan={4} className="text-center py-5">
                  No customer found
                </td>
              )}
            </tbody>
          </table>
          {customers?.length > 0 && (
            <div className="w-90 position-absolute bottom-0 start-50 translate-middle-x mb-3">
              <div className="row align-items-center text-center text-md-start">
                <div className="col-sm-6 ms-auto col-xs-6">
                  <div className="d-flex align-items-center justify-content-center">
                    <button
                      className="pagination_btn"
                      onClick={() =>
                        setCurrentPage(
                          currentPage > 1 ? currentPage - 1 : currentPage
                        )
                      }
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    {[...Array(totalPages)].map((_, i) => (
                      <div
                        key={i}
                        className={`pageNo ${
                          i + 1 === currentPage ? "pageNo_active" : ""
                        }`}
                        onClick={() => paginate(i + 1)}
                      >
                        {i + 1}
                      </div>
                    ))}
                    <button
                      className="pagination_btn"
                      onClick={() =>
                        setCurrentPage(
                          currentPage < totalPages
                            ? currentPage + 1
                            : currentPage
                        )
                      }
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CustomerTable;
