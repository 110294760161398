import Joi from "joi";

export const validateStaffPayroll = (req) => {
  const schema = Joi.object({
    store_Id: Joi.string().required().messages({
      "any.required": "Store ID is required.",
    }),
    staff_Id: Joi.string().required().messages({
      "any.required": "Staff ID is required.",
    }),
    month: Joi.number().integer().min(1).max(12).required().messages({
      "number.base": "Month must be required.",
      "number.integer": "Month must be an required.",
      "number.min": "Month must be required.",
      "number.max": "Month must be required.",
      "any.required": "Month is required.",
    }),
    year: Joi.number().integer().required().messages({
      "number.base": "Year must be a required.",
      "number.integer": "Year must be required.",
      "any.required": "Year is required.",
    }),
    salary: Joi.number().positive().required().messages({
      "number.base": "Salary must be a required.",
      "number.positive": "Salary must be a positive Number.",
      "any.required": "Salary is required.",
    }),
    bonus: Joi.number().default(0).optional().messages({
      "number.base": "Bonus must be a number.",
      "any.optional": "Bonus is optional.",
    }),
    deductions: Joi.number().default(0).optional().messages({
      "number.base": "Deductions must be a number.",
      "any.optional": "Deductions are optional.",
    }),
    paymentDate: Joi.date().required().messages({
      "date.base": "Payment date must be a valid date.",
      "any.required": "Payment date is required.",
    }),
    startDate: Joi.date().required().messages({
      "date.base": "Start date must be a valid date.",
      "any.required": "Start date is required.",
    }),
    endDate: Joi.date().required().messages({
      "date.base": "End date must be a valid date.",
      "any.required": "End date is required.",
    }),
    netSalary: Joi.number().required().messages({
      "number.base": "Net salary must be a number.",
      "any.required": "Net salary is required.",
    }),
  });

  return schema.validate(req);
};
