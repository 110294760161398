import React, { useState } from "react";
import Logo from "../assets/Logo-02.png";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import dealBg from "../assets/brush.jpg";
import haircut from "../assets/haircut.jpg";
import MarketingCustomers from "./MarketingCustomers";

const DiscountTemplate = ({
  captureAndSendImage,
  isLoading,
  handleCheckboxChange,
  selectedIds,
  setSelectedIds,
  mainData,
  setMainData,
  divRef,
}) => {
  const { storeInfo } = useSelector((e) => e?.user);

  const [data, setData] = useState({
    offername: "Deal 1",
    background: "",
    discount: "50% OFF",
    logo: "",
    target: "all",
    description:
      "Vist our salon for more information. we provide many servies for making party makup and special eid discount",
  });

  const onChange = (e, index) => {
    const { name, value, files } = e.target;
    if (name === "fields" && index !== undefined) {
      // Handle changes for dynamic fields
      setData((prevData) => {
        const newFields = [...prevData.fields];
        newFields[index] = value;
        return {
          ...prevData,
          fields: newFields,
        };
      });
    } else {
      // Handle changes for other fields
      if (files && files.length > 0) {
        const file = files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
          const fileUrl = event.target.result;
          setData((prev) => ({ ...prev, [name]: fileUrl }));
        };
        reader.readAsDataURL(file);
      } else {
        setData((prev) => ({ ...prev, [name]: value }));
      }
    }
  };

  const style = {
    background: `url(${dealBg})`,
  };

  const style1 = {
    background: `url(${data?.background ? data?.background : haircut})`,
  };

  return (
    <div className="row mt-5">
      <div className="col-lg-6">
        <h3 className="fw-bold">Create Template</h3>
        <div className="bg-white rounded-3 p-3 mb-3">
          <div className="row">
            <div className="mb-3">
              <label htmlFor="offername">Description</label>
              <textarea
                type="text"
                className="form-control w-100"
                name="description"
                rows="4"
                maxlength={"110"}
                value={data?.description}
                onChange={onChange}
              ></textarea>
            </div>

            <div className="row align-items-center pe-0 ">
              <div className="col-md-6 pe-lg-0 ">
                <div className="mb-3">
                  <label htmlFor="logo">Background image</label>
                  <input
                    type="file"
                    className="form-control"
                    name="background"
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="col-md-6 pe-lg-0 ">
                <div className="mb-3">
                  <label htmlFor="offername">Discount</label>
                  <input
                    type="text"
                    className="form-control"
                    name="discount"
                    value={data?.discount}
                    maxLength={8}
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>

            <div className="row pe-0 ">
              <div className="col-lg-6 pe-0 ">
                <div className="mb-3">
                  <label htmlFor="logo">Salon Logo</label>
                  <br />
                  <input
                    type="file"
                    className="form-control"
                    name="logo"
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="col-lg-6 pe-lg-0 ">
                <div className="mb-3">
                  <label htmlFor="target">Target</label>
                  <select
                    name="target"
                    id=""
                    onChange={(e) =>
                      setMainData({ ...mainData, target: e.target.value })
                    }
                    value={mainData?.target}
                    className="form-control"
                  >
                    <option value="all">All Customers</option>
                    <option value="specific">Specific Customer</option>
                  </select>
                </div>
              </div>
            </div>

            {mainData?.target === "specific" && (
              <MarketingCustomers
                handleCheckboxChange={handleCheckboxChange}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
              />
            )}

            <button
              className="backBtn mt-4 d-flex align-items-center px-4 d-block mx-auto"
              onClick={captureAndSendImage}
            >
              {isLoading ? <Spinner size="sm" /> : "Create"}
            </button>
          </div>
        </div>
      </div>

      <div className="col-lg-6 mt-lg-5 overflow-x-auto ">
        <div
          className="position-relative discountTemplate"
          ref={divRef}
          style={style}
        >
          <div className="col-7">
            <img
              src={data?.logo || Logo}
              className="templateLogo d-block mx-auto my-3"
              alt=""
            />

            <h1 className="text-warning mt-1 font-lobster text-center">
              {storeInfo?.name}
            </h1>

            <h1 className="luckiest-font  text-warning my-4 text-center discountOff">
              {data?.discount}
            </h1>

            <div className="w-90 p-3 text-warning  lemon-font">
              {data?.description}
            </div>
          </div>

          <div
            style={style1}
            className="innerDiscountWrapper position-absolute top-0 end-0 "
          ></div>
          <h6 className="position-absolute lemon-font bottom-0 start-0 text-white ms-3">
            Powered by beautowns
          </h6>
        </div>
      </div>
    </div>
  );
};

export default DiscountTemplate;
