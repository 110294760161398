import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import ImageModal from "../components/Modal/ImageModal";
import apis from "../services";
import { useMutation } from "@tanstack/react-query";
// import toast from "react-hot-toast";
import { setMarketing } from "../redux/slices/userSlice";
// import template from "../components/assets/temp.jpg";
// import Cards from "../components/marketing/Cards";

const ViewMarketingTemplate = () => {
  const { marketing ,storeInfo} = useSelector((e) => e?.user);
  const [show, setShow] = useState(false);
  const [imageAddress, setImageAddress] = useState(null);
  const dispatch = useDispatch();
  // getStoreMarketing
  const { mutate: getBookings, isLoading } = useMutation(
    () => apis.getStoreMarketing(storeInfo?._id),
    {
      onError: function ({ message }) {
        console.log(message, "message");
        // toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          console.log(data,"data")
          dispatch(setMarketing(data?.market));
        }
      },
    }
  );

  useEffect(() => {
    getBookings();
  }, []);


  return (
    <Layout>
      <ImageModal imageAddress={imageAddress} show={show} setShow={setShow} />
      {isLoading && <div id="cover-spin"></div>}
      <div className="p-3">
        <h3>Market Templates</h3>
        {marketing?.length > 0 &&
          marketing?.map((e) => (
            <img
              src={e?.image}
              onClick={() => {
                setShow(!show);
                setImageAddress(e?.image);
              }}
              className="img-fluid templateImage hc"
              alt=""
            />
          ))}
      </div>
    </Layout>
  );
};

export default ViewMarketingTemplate;
