import React from "react";
import {  Modal } from "react-bootstrap";
import { BeatLoader } from "react-spinners";

const DeleteModal = ({ show, setShow, deleteFunction, loading }) => {
  return (
    <Modal centered show={show} onHide={setShow}>
      <Modal.Header closeButton className="bg-white">
        <Modal.Title>Do You want to Delete?</Modal.Title>
      </Modal.Header>

      <Modal.Footer className="bg-white">
        <div className="d-flex">
          <button className="login-btn border-0 me-2 bg-secondary text-white  px-4"  onClick={() => setShow(false)}>
            Cancel
          </button>
          <button disabled={loading} className="login-btn bg-danger text-white px-4" onClick={deleteFunction}>
            {!loading ? "Delete" : 
            
            // <Spinner animation="border" />
            <BeatLoader 
            size={10} color="#fff" />
            }
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
