import Joi from "joi";

export const BookingValidate = (req) => {
  const schema = Joi.object({
    name: Joi.string().required().messages({
      "string.empty": "Name is required",
    }),
    phone: Joi.number()
      .integer()
      .min(100000000000)
      .max(999999999999)
      .required()
      .messages({
        "number.base": "Phone number must be a valid number",
        "number.integer": "Phone number must be an integer",
        "number.min": "Phone number must have at least 12 digits",
        "number.max": "Phone number cannot exceed 12 digits",
        "any.required": "Phone number is required",
      }),
    advance_Amount: Joi.number().integer().min(0).default(0).messages({
      "number.min": "Minimum amount should be 0",
      "number.base": "Minimum amount should be 0",
      "number.integer": "Minimum amount should be 0",
    }),

    // email: Joi.string()
    //   .email({ tlds: { allow: false } }) // Disallow top-level domains (TLDs) to customize email validation
    //   .optional()
    //   .messages({
    //     'string.email': 'Email must be a valid email address',
    //   }),
  });

  return schema.validate(req);
};
