import React, { useEffect, useRef, useState } from "react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { errorValidate } from "../../validations/errorHandleJoi";
import { registerValidate } from "../../validations/RegisterValidation";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/slices/userSlice";
import toast from "react-hot-toast";
import { useMutation } from "@tanstack/react-query";
import apis from "../../services";
import { Spinner } from "react-bootstrap";
import { TbPhotoEdit } from "react-icons/tb";
import imageIcon from "../assets/imageIcon.png";
import { MdOutlineEmail } from "react-icons/md";
import { FaIdCard } from "react-icons/fa";
import { PiGenderFemaleBold } from "react-icons/pi";
import { IoIosUnlock } from "react-icons/io";
import { BeatLoader } from "react-spinners";

const SIgnUpForm = ({ isEdit }) => {
  const [showPassword, setShowPassword] = useState();
  const { user } = useSelector((e) => e?.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [imagePreview, setImagePreview] = useState(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const referralCode = params.get("referralCode");

  let [data, setData] = useState({
    name: "",
    gender: "female",
    email: "",
    password: "",
    role: "store",
    image: "",
  });

  useEffect(() => {
    if (isEdit) {
      setData({
        name: user?.name,
        ...(user?.gender && { gender: user?.gender }),
        email: user?.email,
        image: user?.image,
      });
      setImagePreview(user?.image);
    }

    // eslint-disable-next-line
  }, [user]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  let [error, setError] = useState();
  console.log(error, "error");

  const { mutate: updateUser, isLoading: updating } = useMutation(
    apis.update_User,
    {
      onError: function ({ message }) {
        setError("");
        toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data: user, status }) => {
        if (status === 200) {
          dispatch(setUser(user?.user));
          toast.success(user?.message, { id: 1 });
        }
      },
    }
  );

  const { mutate, isLoading } = useMutation(
    referralCode ? apis.authRegisterReferral : apis.authRegister,
    {
      onError: function ({ message }) {
        setError("");
        toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data: user, status }) => {
        if (status === 200) {
          toast.success(user?.message, { id: 1 });
          navigate("/otp-verification", {
            state: { email: data.email, role: "store" },
          });
        }
      },
    }
  );

  const signUP = (e) => {
    e.preventDefault();
    const { role, password, email, favourite, ...rest } = data || {};
    let response = errorValidate(
      registerValidate({
        ...data,
        isEdit: isEdit ? true : false,
      })
    );
    if (response === true) {
      if (isEdit) {
        const form_data = new FormData();
        for (const [key, value] of Object.entries(rest)) {
          if (key === "isEdit") {
            continue;
          }

          form_data.append(key, value);
        }

        updateUser({ id: user._id, body: form_data });
      } else {
        let { image, ...rest } = data;
        if (referralCode) {
          mutate({ body: rest, code: referralCode });
        } else {
          mutate(rest);
        }
      }
    } else {
      setError(response);
    }
  };

  const fileInputRef = useRef();
  const triggerFileInput = () => {
    fileInputRef.current.click();
  };
  const handleFileSelection = () => {
    const selectedFile = fileInputRef.current.files[0];
    setImagePreview(URL.createObjectURL(selectedFile));
    setData((prevData) => ({
      ...prevData,
      image: selectedFile,
    }));
  };

  return (
    <div
      className={
        isEdit
          ? "bg-white p-3 rounded-3 col-md-8 col-lg-6 mx-auto py-4 mb-4"
          : "Login-card"
      }
    >
      <form onSubmit={signUP}>
        <h1 className="text-center mb-4">
          {isEdit ? "Update Profile" : "Sign Up"}
        </h1>
        <input
          type="file"
          name="image"
          accept="image/*"
          className="d-none"
          onChange={handleFileSelection}
          ref={fileInputRef}
        />
        {isEdit && (
          <div className="profile_image_wrapper">
            <img
              src={imagePreview || imageIcon}
              onClick={triggerFileInput}
              className="img-fluid hc d-block mx-auto salonProfileImage"
              alt=""
            />
            <TbPhotoEdit
              onClick={triggerFileInput}
              className="text-center hc d-block mx-auto fs-1 mt-1 inputImage"
            />
          </div>
        )}
        <div className="row">
          <div className="col-12">
            <div className="mb-3 position-relative ">
              <label htmlFor="name" className="ms-2 mb-1">
                Owner Name
              </label>
              <input
                type="text"
                name="name"
                value={data?.name}
                onChange={onChange}
                className="form-control iconsPadding"
              />
              <FaIdCard className="inputIconss" />
              {error?.name && <p className="text-danger">{error?.name}</p>}
            </div>
          </div>
          {isEdit && (
            <div className="col-12">
              <div className="mb-3 position-relative">
                <label htmlFor="gender" className="ms-2 mb-1">
                  Gender
                </label>
                <select
                  id=""
                  className="form-control iconsPadding"
                  name="gender"
                  value={data?.gender}
                  onChange={onChange}
                >
                  <option value="" disabled="" selected="">
                    Choose...
                  </option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
                <PiGenderFemaleBold className="inputIconss" />
                {error?.gender && (
                  <p className="text-danger">{error?.gender}</p>
                )}
              </div>
            </div>
          )}
          <div className="col-12">
            <div className="mb-3 position-relative ">
              <label htmlFor="email" className="ms-2 mb-1">
                Owner Email
              </label>
              <input
                type="text"
                onChange={onChange}
                name="email"
                value={data?.email}
                className="form-control iconsPadding"
                disabled={isEdit}
                readOnly={isEdit}
              />
              <MdOutlineEmail className="inputIconss" />
              {error?.email && <p className="text-danger">{error?.email}</p>}
            </div>
          </div>
          {!isEdit && (
            <div className="col-12">
              <div className="mb-3 position-relative">
                <label htmlFor="password" className="ms-2 mb-1">
                  Password
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control iconsPadding"
                  onChange={onChange}
                  name="password"
                  placeholder="**********"
                />
                <IoIosUnlock className="inputIconss" />

                <div
                  className="eyeDiv"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {!showPassword ? (
                    <FaRegEyeSlash className="eyeIcon" />
                  ) : (
                    <FaRegEye className="eyeIcon" />
                  )}
                </div>
              </div>
              {error?.password && (
                <p className="text-danger password-error">{error?.password}</p>
              )}
            </div>
          )}
        </div>

        <button className="login-btn fw-bold" disabled={isLoading} type="submit">
          {isLoading || updating ? (
            // <Spinner animation="border" />
            <BeatLoader size={15} color="#fff" />
          ) : !isEdit ? (
            "SIGNUP"
          ) : (
            "Update"
          )}
        </button>
      </form>
      {!isEdit && (
        <p className="text-center mb-0 my-4">
          Already have an Account?{" "}
          <b>
            <Link to="/login">Login Now!</Link>
          </b>
        </p>
      )}
    </div>
  );
};

export default SIgnUpForm;
