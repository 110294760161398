import React, { useEffect, useState } from "react";
import AllStaffCalander from "../components/bookings/AllStaffCalander";
import SpecificStaffCalander from "../components/bookings/SpecificStaffCalander";
import Layout from "../components/layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { setBookings, setStaffs } from "../redux/slices/userSlice";
import apis from "../services";

const BookingSchedule = () => {
  const { bookings, staffs, noOfStaff, storeInfo, user, staffDetails } =
    useSelector((e) => e?.user);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [specific, setSpecific] = useState("all");
  const [calanderView, setCalanderView] = useState("week");
  const [bookingCategory, setBookingCategory] = useState("upcoming");

  const filteredBookings =
    specific !== "all"
      ? bookings?.filter((e) => {
          return e?.salon_staff_Id?._id === specific;
        })
      : bookings;

  const filteredCategory =
    bookingCategory === "upcoming"
      ? filteredBookings?.filter((e) => e?.isCheckIn === false)
      : bookingCategory === "done"
      ? filteredBookings?.filter((e) => e?.isCheckIn === true)
      : bookingCategory === "cancelled"
      ? filteredBookings?.filter((e) => e?.isCancel === true)
      : [];

  const [staff] = staffs?.filter((e) => e._id === specific) || [];

  const getBookings = async () => {
    try {
      let book;
      let staff;
      setIsLoading(true);
      if (user?.role === "store") {
        book = await apis.getStoreBookings(storeInfo?._id);
        staff = await apis.getStoreStaff({
          id: storeInfo?._id,
          role: user?.role,
        });
      } else if (user?.role === "staff") {
        book = await apis.getStaffBookings(staffDetails?._id);
      }
      setIsLoading(false);
      if (book?.data?.booking) {
        dispatch(setBookings(book?.data?.booking));
      }
      if (staff.data?.staff) {
        dispatch(setStaffs(staff?.data?.staff));
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBookings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dummy = [
    {
      name: "You are not part of beautowns yet. Please add staff,service category and service. Thanks!",
      _id: "dummy",
      color: "#000000",
    },
  ];

  return (
    <Layout>
      <div className="p-3">
        {isLoading && <div id="cover-spin"></div>}
        <div className="row">
          {user?.role === "store" && (
            <div className="col-md-4 col-lg-3 my-3">
              <label>Select specific staff</label>
              <select
                value={specific}
                onChange={(e) => setSpecific(e.target.value)}
                className="form-control bg_white"
              >
                <option disabled selected>
                  Select specific staff
                </option>
                <option value={"all"}>All staff</option>
                {staffs?.length > 0 ? (
                  staffs?.map((e) => (
                    <option key={e._id} value={e?._id}>
                      {e.name}
                    </option>
                  ))
                ) : (
                  <option disabled>No Staff Found</option>
                )}
              </select>
            </div>
          )}

          {/* {(specific === "all" || user?.role === "staff") && ( */}
          <div className="col-md-4 col-lg-3 my-3">
            <label>Select calendar view</label>
            <select
              value={calanderView}
              onChange={(e) => setCalanderView(e?.target?.value)}
              className="form-control bg_white"
            >
              <option>Select calender view</option>
              <option value={"day"}>Daily</option>
              <option value={"week"}>Weekly</option>
              <option value={"month"}>Monthly</option>
            </select>
          </div>
          {/* // )} */}

          <div className="col-md-4 col-lg-3 my-3">
            <label>Select Bookings</label>
            <select
              value={bookingCategory}
              onChange={(e) => setBookingCategory(e?.target?.value)}
              className="form-control bg_white"
            >
              <option disabled selected>
                Select Booking Category
              </option>
              <option value={"upcoming"}>Upcoming</option>
              <option value={"done"}>Complete</option>
              <option value={"cancelled"}>Cancelled</option>
            </select>
          </div>
        </div>
        <h3 className="mb-4 fw-bold ">
          {specific !== "all" ? <>{staff?.name} Bookings</> : "Bookings"}
        </h3>
        {user?.role === "store" && specific === "all" ? (
          isLoading ? (
            ""
          ) : isLoading ? (
            ""
          ) : (
            <AllStaffCalander
              calanderView={calanderView}
              staffs={noOfStaff > 0 ? staffs : dummy}
              bookings={filteredCategory}
            />
          )
        ) : isLoading ? (
          ""
        ) : (
          <SpecificStaffCalander
            calanderView={calanderView}
            bookings={filteredCategory}
          />
        )}
      </div>
    </Layout>
  );
};

export default BookingSchedule;
