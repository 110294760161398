import React from "react";
import toast from "react-hot-toast";
import apis from "../../services";
import { useState } from "react";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setCoupons } from "../../redux/slices/userSlice";

const AddAdminCouponComp = () => {
  const { user } = useSelector((e) => e?.user);
  const navigate = useNavigate();
  const { bookings } = useSelector((e) => e?.user);
  const [amountType, setAmountType] = useState("fixedAmount");

  let [data, setData] = useState({
    target: "all",
    type: { fixedAmount: "" },
    userIds: [],
    amount: "",
    quantity: "",
    value: "",
    validityDate: "",
  });

  const onChangeHandlerAmount = (e) => {
    const { value } = e.target;
    setData((prevData) => ({
      ...prevData,
      type: { [value]: data?.amount },
    }));
    setAmountType(value);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "amount") {
      setData((prevData) => ({
        ...prevData,
        type: { [amountType]: parseInt(value) },
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const CreateCoupon = async (e) => {
    e.preventDefault();
    if (!data?.userIds?.length > 0 && data?.target === "specific") {
      toast.error("Plz select the user");
    } else {
      try {
        setIsLoading(true);
        const { amount, ...rest } = data;
        const response = await apis.createAdminCoupon(rest);
        if (response?.data?.status) {
          toast.success(response?.data?.message, { id: 1 });
          dispatch(setCoupons(response?.data?.coupons));
        }
        navigate(`/admin/admin-coupon`);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        toast.error(e?.message?.message, { id: 1 });
      }
    }
  };

  const handleUserSelection = (userId) => {
    setData((prevState) => {
      if (prevState.userIds.includes(userId)) {
        const updatedUserIds = prevState.userIds.filter((id) => id !== userId);
        return { ...prevState, userIds: updatedUserIds };
      } else {
        const updatedUserIds = [...prevState.userIds, userId];
        return { ...prevState, userIds: updatedUserIds };
      }
    });
  };

  return (
    <div className="p-3">
      <h3 className="fw-bold">ADD ADMIN COUPON</h3>
      <form onSubmit={CreateCoupon} className="bg-white p-3 rounded-3 ">
        <div>
          <div className="row mt-3 justify-content-center ">
            <div className="col-md-6 col-lg-4 mb-3">
              <div className="form-group">
                <label className="text-black">Select Target</label>
                <select
                  name="target"
                  id="target"
                  onChange={onChange}
                  className="form-control form-control-lg pe-2"
                >
                  <option value="all">All Customers</option>
                  <option value="specific">Specific Customer</option>
                </select>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-3">
              <div className="form-group">
                <label className="text-black">Type</label>
                <select
                  name="type"
                  onChange={onChangeHandlerAmount}
                  className="form-control form-control-lg pe-2"
                >
                  <option value="fixedAmount">Fixed</option>
                  <option value="percentage">Percent</option>
                </select>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-3">
              <div className="form-group">
                <label className="text-black font-w500">Amount</label>
                <input
                  type="number"
                  name="amount"
                  id="amount"
                  onChange={onChange}
                  className="form-control"
                  required
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-3">
              <div className="form-group">
                <label className="text-black font-w500">Quantity</label>
                <input
                  type="number"
                  name="quantity"
                  id="quantity"
                  required
                  onChange={onChange}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-3">
              <div className="form-group">
                <label className="text-black font-w500">Validity Date</label>
                <input
                  type="date"
                  name="validityDate"
                  id="validityDate"
                  min={new Date().toJSON().slice(0, 10)}
                  required
                  onChange={onChange}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-3">
              <div className="form-group">
                <label className="text-black font-w500">Code</label>
                <input
                  type="text"
                  name="value"
                  id="value"
                  onChange={onChange}
                  required
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </div>
        {data?.target === "specific" && (
          <div style={{ overflow: "auto", maxHeight: "150px" }}>
            <Table bordered hover size="sm">
              <thead>
                <tr style={{ textAlign: "center" }}>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {bookings?.length > 0 ? (
                  Array.from(
                    new Set(bookings?.map((user, p) => user?.user_Id?._id))
                  ).map((userId) => {
                    const user = bookings?.find(
                      (u) => u?.user_Id?._id === userId
                    );

                    if (user && user?.user_Id?.email) {
                      return (
                        <tr
                          className="hc"
                          key={user?.user_Id?._id}
                          // onClick={() =>
                          //   handleUserSelection(user?.user_Id?._id)
                          // }
                        >
                          <td>{user?.user_Id?.name}</td>
                          <td>{user?.user_Id?.email}</td>

                          <td className="text-center">
                            {/* <input
                              style={{ marginTop: "12px" }}
                              type="checkbox"
                              className="checkbox ms-4"
                              onChange={() =>
                                handleUserSelection(user?.user_Id?._id)
                              }
                              checked={data.userIds.includes(
                                user?.user_Id?._id
                              )}
                            /> */}
                            {data.userIds.includes(user?.user_Id?._id) ? (
                              <ImCheckboxChecked
                                onClick={() =>
                                  handleUserSelection(user?.user_Id?._id)
                                }
                                className="fs-4 text-pink"
                              />
                            ) : (
                              <ImCheckboxUnchecked
                                onClick={() =>
                                  handleUserSelection(user?.user_Id?._id)
                                }
                                className="fs-4 text-pink"
                              />
                            )}
                          </td>
                        </tr>
                      );
                    }
                    return null;
                  })
                ) : (
                  <tr>
                    <td colSpan={4} className="text-center">
                      No User Found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        )}
        <div className="col-md-5 col-lg-3 mx-auto  d-block">
          <button className="login-btn fw-bold" type="submit">
            {isLoading ? <Spinner /> : "Add coupon"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddAdminCouponComp;
