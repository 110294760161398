import React from "react";
import LoginBanner from "../components/assets/loginBanner.png";
import LoginForm from "../components/auth/LoginForm";
import { useLocation } from "react-router-dom";
import AdminLogin from "../components/auth/AdminLogin";
import AdminBanner from "../components/assets/adminLoginBanner.svg"

const Login = () => {
  const { pathname } = useLocation();
  return (
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-6 col-md-6 d-none d-md-block">
          <img src={pathname?.includes("admin") ?  AdminBanner :LoginBanner} className="img-fluid" alt="" />
        </div>
        <div className="ms-auto col-md-6 col-lg-5 d-flex justify-content-center align-items-center">
          {pathname?.includes("admin") ? <AdminLogin /> : <LoginForm />}
        </div>
      </div>
    </div>
  );
};

export default Login;
