import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { BiLogOutCircle } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  logout,
  setTotalUnseenNotifications,
  setUserNotifications,
} from "../../redux/slices/userSlice";
import { useSidebar } from "./SidebarContext";
import { FaBars, FaArrowLeft } from "react-icons/fa6";
import { TbRefresh } from "react-icons/tb";
import { PiBellBold } from "react-icons/pi";
import { AiOutlineFullscreenExit } from "react-icons/ai";
import { MdPassword } from "react-icons/md";
import { SlSizeFullscreen } from "react-icons/sl";
import { CgProfile } from "react-icons/cg";
import dummy from "../assets/Logo-02.png";
import defaultPic from "../assets/imageIcon.png";
import apis from "../../services";
import { FaAlignLeft } from "react-icons/fa";
import { CiWallet } from "react-icons/ci";
import ResetPasswordModal from "../Modal/ResetPasswordModal";
import { Spinner } from "react-bootstrap";
import reduxApis from "../../redux/api";
import { BeatLoader } from "react-spinners";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, totalUnseenNotifications, storeInfo } = useSelector(
    (e) => e?.user
  );
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let isStore = user?.role === "store" ? true : false;
  const { toggleSidebar, isSidebarOpen } = useSidebar();
  const [showDropdown, setShowDropdown] = useState(false);
  const [noti, setNoti] = useState([]);
  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleDropdownClose = () => {
    setShowDropdown(false);
  };

  const getNoti = async () => {
    try {
      setIsLoading(true);
      let data;
      if (user?.role === "store") {
        data = await apis.getStoreNotification(user?._id);
      } else if (user?.role === "staff") {
        data = await apis.getStaffNotification(user?._id);
      }else if(user?.role === "admin"){
        data = await apis.getAdminNotification();
      }
      if (data?.data?.notifications) {
        setNoti(data?.data?.notifications);
        if (isStore) {
          await apis.storeNotificationSeen(user?._id);
        } else {
          await apis.staffNotificationSeen(user?._id);
        }
        dispatch(setUserNotifications(data?.data?.notifications));
        dispatch(setTotalUnseenNotifications(0));
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    setNoti([]);
    if (showDropdown) {
      getNoti();
    }
    // eslint-disable-next-line
  }, [showDropdown]);

  function calculateTimeDifference(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      console.error("Invalid date format");
      return "Invalid date";
    }
    const seconds = Math.floor((new Date() - date) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " years ago";
    }

    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months ago";
    }

    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days ago";
    }

    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours ago";
    }

    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes ago";
    }

    return Math.floor(seconds) + " seconds ago";
  }

  const [isFullScreen, setFullScreen] = useState(false);

  const toggleFullScreen = () => {
    if (!isFullScreen) {
      // Enter fullscreen mode
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      }
    } else {
      // Exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }

    setFullScreen(!isFullScreen);
  };

  return (
    <>
      <ResetPasswordModal show={show} setShow={setShow} />
      <div className="header-div d-flex justify-content-end  justify-content-sm-between align-items-center">
        <div className="d-none d-lg-flex align-items-center">
          {!isSidebarOpen ? (
            <FaAlignLeft className="fs-4" onClick={toggleSidebar} />
          ) : (
            <FaArrowLeft className="fs-4" onClick={toggleSidebar} />
          )}
          <h4 className="m-0 ms-3 fw-bold ">{user?.role==="admin" ? "Beautowns Admin" : user?.role==="store" ?  storeInfo?.name : ""}</h4>
          {/* <div className="searchInput ms-4 d-block ">
            <div className="hellow">
              <div className="one">
                <p>Hello, {user?.name}</p>{" "}
               
              </div>
              <div className="two">
                <p>{moment().format("MMMM Do YYYY, h:mm a")}</p>
              </div>
            </div>
          </div> */}
        </div>

        <div>
          {/* <div className="search">
            <input type="text" placeholder="Search..." />
            <IoSearch className="search__icon" />
          </div> */}
        </div>

        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center ">
            <div
              className="bars bg-light shadow-sm mx-1"
              onClick={toggleSidebar}
            >
              {!isSidebarOpen ? <FaBars /> : <FaArrowLeft />}

            </div>
          </div>
          {/* <TbRefresh
            className="fs-3 me-3 me-md-4 text-dark hc"
            onClick={() => {
              (async () => {
                if (user?.role === "store") {
                  await dispatch(reduxApis?.getCompleteInfo(storeInfo?._id));
                } else if (user?.role === "staff") {
                  await dispatch(reduxApis?.getCompleteInfo(storeInfo?._id));
                } else if (user?.role === "admin") {
                  await dispatch(reduxApis?.getCompleteAdmin(user?._id));
                }
              })();
            }}
          /> */}

          {isFullScreen ? (
            <AiOutlineFullscreenExit
              onClick={toggleFullScreen}
              className="fs-3 mx-1 me-3 me-md-3 text-dark hc"
            />
          ) : (
            <SlSizeFullscreen
              onClick={toggleFullScreen}
              className="fs-5 mx-1 me-3 me-md-3 text-dark hc"
            />
          )}
          {/* <Link to="/chat">
            <HiOutlineChatAlt2 className="fs-3 mx-1 me-md-2 text-dark hc" />
          </Link> */}

          <Dropdown
            show={showDropdown}
            onToggle={handleDropdownToggle}
            onClose={handleDropdownClose}
          >
            <Dropdown.Toggle
              variant="link"
              id="notification-dropdown"
              className="notification-toggle position-relative "
            >
              <PiBellBold className="fs-3 me-1 me-md-3 text-dark hc" />
              {totalUnseenNotifications > 0 ? (
                <span class="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-danger mt-2">
                  {totalUnseenNotifications}
                </span>
              ) : (
                ""
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu className="notification-dropdown-menu">
              <Dropdown.ItemText>Recent Notifications</Dropdown.ItemText>
              {isLoading ? (
                // <Spinner className="d-block my-5 mx-auto " />
                <div className="d-flex my-5 justify-content-center align-items-center">
                  <BeatLoader
                    // size={10}
                    color="#ea4c89"
                  />
                </div>
              ) : (
                <>
                  {noti?.length > 0 &&
                    noti?.slice(0, 4)?.map((n, i) => (
                      <Dropdown.Item className="notification-item mt-2" key={i}>
                        <Link to={`/${user?.role}/notifications`}>
                          <div className="d-flex">
                            <img
                              src={dummy}
                              className="notificationImage"
                              alt=""
                            />
                            <div className="ms-2">
                              <div className="notification-content">
                                {n?.notification?.title}
                              </div>
                              <div className="notification-time">
                                {calculateTimeDifference(n?.createdAt)}
                              </div>
                            </div>
                          </div>
                        </Link>
                      </Dropdown.Item>
                    ))}

                  <Dropdown.Item className="notification-item text-center d-flex justify-content-center  mt-2 ">
                    <Link to={`/${user?.role}/notifications`}>See more</Link>
                  </Dropdown.Item>
                </>
              )}
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown>
            <Dropdown.Toggle
              className="header-dropdown p-0 "
              id="dropdown-basic"
            >
              <div className="user-info">
                <div>
                  <img src={user?.image || defaultPic} className="" alt="" />
                </div>
                <div className="d-none d-md-block">
                  <p className="">{user?.name}</p>
                  <p>{user?.role === "store" ? "Salon Owner" : user?.role}</p>
                </div>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="header_drop_menu shadow">
              <img
                src={user?.image || defaultPic}
                className="d-block mx-auto img-fluid shadow-sm  header_menu_profile mt-3"
                alt=""
              />
              <p className="text-center m-0 mt-2 h6 text-capitalize ">
                {user?.name}
              </p>
              <p className="text-center m-0 mt-1 useremail w-90 d-block mx-auto">
                {user?.email}
              </p>

              <hr />
              <Dropdown.Item
                className="fs-6 d-flex align-items-center"
                onClick={() => {
                  navigate(`/${user?.role}/profile`);
                }}
              >
                <CgProfile className="text-dark fs-5 me-2" /> Profile
              </Dropdown.Item>
              <Dropdown.Item
                className="fs-6 d-flex align-items-center"
                onClick={() => navigate(`/${user?.role}/wallet`)}
              >
                <CiWallet className="text-dark fs-5 me-2" /> Wallet
              </Dropdown.Item>
              <Dropdown.Item
                className="fs-6 d-flex align-items-center"
                onClick={() => {
                  setShow(true);
                }}
              >
                <MdPassword className="text-dark fs-5 me-2" /> Change Password
              </Dropdown.Item>

              <Dropdown.Item
                className="fs-6 d-flex align-items-center"
                onClick={() => {
                  navigate("/login");
                  dispatch(logout());
                }}
              >
                <BiLogOutCircle className="text-dark fs-4 me-2" /> Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

export default Header;
