import React, { useEffect } from "react";
import Layout from "../components/layout/Layout";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

const BlogDetails = () => {
  const { state } = useLocation();
  const navitage = useNavigate();
  const { blogs, user } = useSelector((e) => e.user);
  const [specificBlog] =
    state?.id && blogs?.filter((e) => e?.slug === state?.id);
  useEffect(() => {
    if (state?.id === undefined) {
      navitage(`/${user?.role}/blog`);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <div className="container">
        <h1 className="text-center">{specificBlog?.title}</h1>
        <div className="d-flex align-items-center my-5">
          <img
            src={specificBlog?.author_image}
            className="img-fluid authorImage"
            alt=""
          />
          <div className="d-flex flex-column ms-2">
            <h5 className="m-0">{specificBlog?.author}</h5>
            <h5 className="m-0 text-secondary">
              {moment(specificBlog?.createdAt).format("DD-MM-YYYY")}
            </h5>
          </div>
        </div>
        <div className="col-sm-11 mx-auto mb-4">
          <img
            src={specificBlog?.image}
            className="blog_img d-block  mx-auto mt-4"
            alt=""
          />
        </div>
        <p>{specificBlog?.description}</p>
      </div>
    </Layout>
  );
};

export default BlogDetails;
