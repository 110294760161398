import moment from "moment";
import React, { useState } from "react";
import { IoMdEye } from "react-icons/io";
import { MdDeleteForever } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import apis from "../../services";
import DeleteModal from "../Modal/DeleteModal";
import { useNavigate } from "react-router-dom";
import { setBlogs } from "../../redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import ImageModal from "../Modal/ImageModal";

const BlogTable = ({ blog}) => {
  const {user}=useSelector((state)=>state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const itemsPerPage = 10; // Define number of items per page
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = blog?.slice(indexOfFirstItem, indexOfLastItem);
  const [show, setShow] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [imageAddress, setImageAddress] = useState(false);
  const [id, setId] = useState(null);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const totalPages = Math.ceil(blog?.length / itemsPerPage);

  const { mutate, isLoading } = useMutation(() => apis?.delete_blog({id:id,role:user?.role}), {
    onError: function ({ message }) {
      toast.error(message?.message, { id: 1 });
    },
    onSuccess: ({ data, status }) => {
      if (status === 200) {
        setShow(false);
        dispatch(setBlogs(data?.blogs));
        toast.success(data?.message, { id: 1 });
      }
    },
  });

  const showModal = (data) => {
    setImageAddress(data);
    setShowImage(true);
  };

  return (
    <div class="table-responsive bg-white rounded-3 mt-2">
      <DeleteModal
        deleteFunction={mutate}
        show={show}
        setShow={setShow}
        loading={isLoading}
      />

      <ImageModal
        show={showImage}
        setShow={setShowImage}
        imageAddress={imageAddress}
      />

      <table class="table table-hover ">
        <thead className="table-secondary">
          <tr>
            <th scope="col">Title</th>
            <th scope="col">Author</th>
            <th scope="col">Author image</th>
            <th scope="col">Blog image</th>
            <th scope="col">Desciption</th>
            <th scope="col">Posted at</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {currentItems?.length > 0 ? (
            currentItems?.map((blog) => {
              return (
                <tr>
                  <td>{blog?.title}</td>
                  <td>{blog?.author}</td>
                  <td>
                    <img
                      src={blog?.author_image}
                      className="img-fluid blogTableImg"
                      onClick={() => showModal(blog?.author_image)}
                      alt=""
                    />
                  </td>
                  <td>
                    <img
                      src={blog?.image}
                      className="img-fluid blogTableImg"
                      onClick={() => showModal(blog?.image)}
                      alt=""
                    />
                  </td>

                  <td>{blog?.description?.substring(0, 20)}</td>
                  <td>{moment(blog?.createdAt).format("DD-MM-YYYY")}</td>
                  <td className="d-flex">
                    <IoMdEye
                      className="fs-3 text-secondary me-2 hc"
                      onClick={() =>
                        navigate(`/${user?.role}/blog/details`, {
                          state: { id: blog?.slug, type: "Details" },
                        })
                      }
                    />

                    <FaEdit
                      className="fs-3 text-secondary mx-2 hc"
                      onClick={() =>
                        navigate(`/${user?.role}/blog/update`, {
                          state: { id: blog?.slug },
                        })
                      }
                    />

                    <MdDeleteForever
                      className="fs-3 text-danger hc"
                      onClick={() => {
                        setId(blog?._id);
                        setShow(true);
                      }}
                    />
                  </td>
                </tr>
              );
            })
          ) : (
            <td colSpan="5" className="text-center py-5 ">
              No blogs found
            </td>
          )}
        </tbody>
      </table>
      {blog?.length > 0 && (
        <div className="w-90 position-absolute bottom-0 start-50 translate-middle-x mb-3">
          <div className="row align-items-center text-center text-md-start">
            <div className="col-sm-6 ms-auto  col-xs-6">
              <div className="d-flex align-items-center justify-content-center">
                <button
                  className="pagination_btn"
                  onClick={() =>
                    setCurrentPage(
                      currentPage > 1 ? currentPage - 1 : currentPage
                    )
                  }
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                {[...Array(totalPages)].map((_, i) => (
                  <div
                    key={i}
                    className={`pageNo ${
                      i + 1 === currentPage ? "pageNo_active" : ""
                    }`}
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </div>
                ))}
                <button
                  className="pagination_btn"
                  onClick={() =>
                    setCurrentPage(
                      currentPage < totalPages ? currentPage + 1 : currentPage
                    )
                  }
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogTable;
