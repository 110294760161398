import React, { useEffect, useState } from "react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { errorValidate } from "../../validations/errorHandleJoi";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import apis from "../../services";
import { useMutation } from "@tanstack/react-query";
import { set_Email_For_Verification } from "../../redux/slices/userSlice";
import toast from "react-hot-toast";
import { ResetPasswordValidate } from "../../validations/ForgotPasswordValidation";
import { Spinner } from "react-bootstrap";
import { BeatLoader } from "react-spinners";

const ResetPasswordForm = ({ isEdit, setShow }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const { user } = useSelector((e) => e.user);
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();

  let [data, setData] = useState({
    email: isEdit ? user?.email : state?.data?.email,
    role: isEdit ? user?.role : state?.data?.role,
    password: "",
    confirmPassword: "",
  });

  console.log(user,"user")
  useEffect(() => {
    if (!state && !user) {
      navigate(-1);
    }

    // eslint-disable-next-line
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  let [error, setError] = useState();
  console.log(error, "error");
  const { mutate, isLoading } = useMutation(apis.authUpdatePassword, {
    onError: function ({ message }) {
      setError("");
      toast.error(message?.message, { id: 1 });
    },
    onSuccess: ({ data: user, status }) => {
      if (status === 200) {
        dispatch(set_Email_For_Verification(null));
        toast.success(user?.message, { id: 1 });
        if (isEdit) {
          setShow(false);
        } else {
          navigate("/login");
        }
      }
    },
  });

  const changePassword = (e) => {
    e.preventDefault();
    let response = errorValidate(ResetPasswordValidate(data));
    if (response === true) {
      mutate({
        email: isEdit ? user?.email : state?.data?.email,
        role: isEdit ? user?.role : state?.data?.role,
        password: data?.password,
      });
    } else {
      setError(response);
    }
  };

  return (
    <div
      className={`${
        !isEdit ? "Login-card" : " Login-card shadow-none  border-0 "
      }`}
    >
      <form onSubmit={changePassword}>
        {!isEdit && <h1 className="text-center mb-4">Reset Password</h1>}
        <div className="mb-3 position-relative">
          <label htmlFor="password" className="ms-2 mb-1">
            New Password
          </label>
          <input
            type={showPassword ? "text" : "password"}
            className="form-control"
            name="password"
            onChange={onChange}
            placeholder="**********"
          />

          <div
            className="eyeDiv"
            onClick={() => setShowPassword(!showPassword)}
          >
            {!showPassword ? (
              <FaRegEyeSlash className="eyeIcon" />
            ) : (
              <FaRegEye className="eyeIcon" />
            )}
          </div>
        </div>
        {error?.password && (
          <p className="text-danger error__password">{error?.password}</p>
        )}
        <div className="mb-3 position-relative">
          <label htmlFor="password" className="ms-2 mb-1">
            Confirm Password
          </label>
          <input
            type={showCPassword ? "text" : "password"}
            className="form-control"
            placeholder="**********"
            onChange={onChange}
            name="confirmPassword"
          />
          <div
            className="eyeDiv"
            onClick={() => setShowCPassword(!showCPassword)}
          >
            {!showCPassword ? (
              <FaRegEyeSlash className="eyeIcon" />
            ) : (
              <FaRegEye className="eyeIcon" />
            )}
          </div>
        </div>
        {error?.confirmPassword && (
          <p className="text-danger error__password">
            {error?.confirmPassword}
          </p>
        )}

        <button className="login-btn mt-2 fw-bold" disabled={isLoading} type="submit">
          {isLoading ? (
            // <Spinner size="sm" />
            <BeatLoader size={15} color="#fff" />
          ) : isEdit ? (
            "UPDATE PASSWORD"
          ) : (
            "RESET PASSWORD"
          )}
        </button>
      </form>
    </div>
  );
};

export default ResetPasswordForm;
